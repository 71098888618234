import React from "react";
import { Calendar, CalendarComponent } from '@syncfusion/ej2-react-calendars';

function DashbordCalender(){
    
    return(
        <>
        <CalendarComponent id="calendar"  />
        </>
    );
}
export default DashbordCalender;







