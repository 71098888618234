import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "./components/BookingCtWeek";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import aboutthumb from '../src/images/about-thumb.png';
import careerthumb from '../src/images/career-thumb.png';
import career1 from '../src/images/career1.jpg';
import career2 from '../src/images/career2.jpg';
import career3 from '../src/images/career3.jpg';
import cpu from "../src/images/cpu.svg";
import handshake from "../src/images/Handshake.svg";
import crditCard from "../src/images/CreditCard.svg";
import chartBar from "../src/images/ChartBarHorizontal.svg";
import bugdroid from "../src/images/bugdroid.svg";
import receipt from "../src/images/Receipt.svg";
import megaphone from "../src/images/MegaphoneSimple.svg";
import camerac from "../src/images/Camerac.svg";
import ArrowRight from "../src/images/ArrowRight.svg";
import MapPinLine from "../src/images/MapPinLine.svg";
import Briefcase from "../src/images/Briefcase.svg";
import ClipboardText from "../src/images/ClipboardText.svg";
import packagec from "../src/images/Package.svg";
import PenNib from "../src/images/PenNib.svg";
import firstaid from "../src/images/FirstAidKit.svg";
import headphones from "../src/images/Headphones.svg";
import arrowright from "../src/images/button-arrowright.svg";

import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';
import user3 from '../src/images/user3.png';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';
import passport from '../src/images/passport-image.jpg';
import CoursesTable from "./components/CoursesTable"; 
import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";
import netflix from "../src/images/netflix.svg";
import youtube from "../src/images/youtube.svg";
import google from "../src/images/google.svg";
import lenovo from "../src/images/lenovo.svg";
import slack from "../src/images/slack.svg";
import lexmark from "../src/images/lexmark.svg";
import microsoft from "../src/images/microsoft.svg";
import verizon from "../src/images/verizon.svg";
import misionthumb from "../src/images/mision-thumb.png";
import Gallerymain from "../src/images/Gallerymain.png";

import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery"; 

class Carrer extends React.Component {
componentWillMount(){
$(document).ready(function(){
$(".togle-icon").click(function(e) {
e.preventDefault();
$("#navAccordion").toggleClass("active");
$("main").toggleClass("full-width");
$("footer").toggleClass("full-width");
});
});
}
render() {
return(
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
         <div className="bredadcrumb-section">
               <div className="container">
                  <div className="breadcrumb-menu">
                  <h3>Career</h3>
                     <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">Home</li>                            
                              <li className="breadcrumb-item active" aria-current="page">Career</li>
                           </ol>
                     </nav>
                  </div>
               </div>
            </div>   
           

         <div className="contact-us-section career-page">
               <div className="container">
                  <div className="row">
                  <div className="col-lg-6 order-lg-2">
                     <div className="about-thumb responsive_bottom">
                     <img src={careerthumb} alt="careerthumb"/>
                     </div>
                  </div>
                  <div className="col-lg-6 order-lg-0 d-flex align-items-center">
                     <div className="about-content contact-content">
                        <h2>Join the most incredible  &amp;  creative team.</h2>
                        <p>Proin gravida enim augue, dapibus ultrices eros feugiat et. Pellentesque bibendum orci felis, sit amet efficitur felis lacinia ac. Mauris gravida justo ac nunc consectetur.</p>
                        <a className="brows-btn" href="">   View Open Positions
                        </a>
                     </div>
                  </div>

                  </div>
               </div>
            </div>
            <div className="join-team-section section-padding">
            <div className="container">
               <div className="row">
               <div className="col-xl-6 col-lg-6">
                  <div className="join-team-thumb-wrap">
                     <div className="join-team-thumb one">
                     <img src={career1} alt="career1"/>
                     </div>
                     <div className="join-team-thumb two">
                     <img src={career2} alt="career2"/>
                     </div>
                     <div className="join-team-thumb three">
                     <img src={career3} alt="career3"/>
                     </div>
                  </div>
               </div>
               <div className="col-xl-5 col-lg-6 offset-xl-1">
                  <div className="join-team-content">
                     <h2>Why you will join our team</h2>
                     <p>Quisque leo leo, suscipit sed arcu sit amet, iaculis feugiat felis. Vestibulum non consectetur tortor. Morbi at orci vehicula, vehicula mi ut, vestibulum odio. </p>
                     <div className="checkbox-field">
                     <ul>
                        <li>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                           <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                           </svg>
                           <span>Ut justo ligula, vehicula sed egestas vel.</span>
                           Quisque leo leo, suscipit sed arcu sit amet, iaculis feugiat felis. Vestibulum non consectetur tortor. Morbi at orci vehicula, vehicula mi ut, vestibulum odio.
                        </li>
                        <li>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                           <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                           </svg>
                           <span>Aenean vitae leo leo praesent ullamcorper ac.</span>
                           Aenean vitae leo leo. Praesent ullamcorper ac libero et mattis. Aenean vel erat at neque viverra feugiat.
                        </li>
                     </ul>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
         <div className="category-section benefit-package section-padding">
            <div className="container">
               <div className="section-title">
               <h2>Our Perks &amp; Benefits</h2>
               </div>
               <div className="row g-lg-4 topcategory  ">
              

               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat1">
                   <div className="cat-image"><img src={cpu} alt="Cpu"/></div>
                     <div className="cat-content ">
                     <h5>Label</h5>
                     <p>63,476 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat2">
                   <div className="cat-image"><img src={handshake} alt="handshake"/></div>
                     <div className="cat-content ">
                     <h5>Business</h5>
                     <p>63,476 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat3">
                   <div className="cat-image"><img src={crditCard} alt="crditCard"/></div>
                     <div className="cat-content ">
                     <h5>Finance & Accounting</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat4">
                   <div className="cat-image"><img src={chartBar} alt="chartBar"/></div>
                     <div className="cat-content ">
                     <h5>IT & Software</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat5">
                   <div className="cat-image"><img src={bugdroid} alt="bugdroid"/></div>
                     <div className="cat-content ">
                     <h5>Personal Development</h5>
                     <p>33,841 Courses</p> 
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat6">
                   <div className="cat-image"><img src={receipt} alt="receipt"/></div>
                     <div className="cat-content ">
                     <h5>Office Productivity</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat7">
                   <div className="cat-image"><img src={megaphone} alt="megaphone"/></div>
                     <div className="cat-content ">
                     <h5>Marketing</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat8">
                   <div className="cat-image"><img src={camerac} alt="camerac"/></div>
                     <div className="cat-content ">
                     <h5>Photography & Video</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               
            
             
              
               </div>
            </div>
          </div>
          <div className="gallery-section section-padding">
            <div className="container">
               <div className="row">
               <div className="col-lg-8 order-lg-2">
                  <div className="gallery-thumb responsive_bottom">
                  <img src={Gallerymain} alt="Gallerymain"/>
                  </div>
               </div>
               <div className="col-lg-4 d-flex align-items-center">
                  <div className="our-mission-content">
                     <h5>OUR GALLERY</h5>
                     <h2>We’ve been here almost 17 years</h2>
                     <p>Fusce lobortis leo augue, sit amet tristique nisi commodo in. Aliquam ac libero quis tellus venenatis imperdiet. Sed sed nunc libero. Curabitur in urna ligula. torquent per conubia nostra.</p>
                  </div>
               </div>
               </div>
            </div>
         </div>
         <div className="gray-bg">
           <div className="company-section section-padding">
            <div className="container">
            
               <div className="company-main-wrap">
                     <div className="row ourclient align-items-center">
                        <div className="company-content col-lg-4">
                           <h3>We Just keep growing with 6.3k Companies</h3>
                           <p>Nullam egestas tellus at enim ornare tristique. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</p>
                        </div>
                        <div className="col-lg-8">
                        <ul className="client-list">
                           <li><div className="logo-client"><img src={netflix} alt="netflix"/></div></li>
                           <li><div className="logo-client"><img src={youtube} alt="youtube"/></div></li>
                           <li><div className="logo-client"><img src={google} alt="google"/></div></li>
                           <li><div className="logo-client"><img src={lenovo} alt="lenovo"/></div></li>
                           <li><div className="logo-client"><img src={slack} alt="slack"/></div></li>
                           <li><div className="logo-client"><img src={verizon} alt="verizon"/></div></li>
                           <li><div className="logo-client"><img src={lexmark} alt="lexmark"/></div></li>  
                           <li><div className="logo-client"><img src={microsoft} alt="microsoft"/></div></li>
                        </ul>

                        </div>
                     </div>
               </div>

                     </div>
            </div>

            <div className="open-position-section section-padding">
      <div className="container">
        <div className="section-title">
          <h2>Our all open positions (04)</h2>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6">
            <div className="open-position-wrap">
              <div className="open-position-header">
                <h3>Product Designer (UI/UX Designer)</h3>
                <div className="sub-heading-meta">
                  <ul>
                    <li>
                    <img src={MapPinLine} alt="MapPinLine"/>
                      Tokyo, Japan
                    </li>
                    <li>
                    <img src={Briefcase} alt="Briefcase"/>
                      Full-Time
                    </li>
                    <li>
                    <img src={ClipboardText} alt="ClipboardText"/>
                      01 Vacancy
                    </li>
                  </ul>
                </div>
              </div>
              <div className="open-position-bottom">
                <p><span>Deadline:</span> 30 June, 2021</p>
                <a href="" className="arrow-icon">
                  <img src={ArrowRight} alt="ArrowRight"/>
                  
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="open-position-wrap">
              <div className="open-position-header">
                <h3>Social Media Manager</h3>
                <div className="sub-heading-meta">
                  <ul>
                    <li>
                    <img src={MapPinLine} alt="MapPinLine"/>
                      Tokyo, Japan
                    </li>
                    <li>
                    <img src={Briefcase} alt="Briefcase"/>
                      Full-Time
                    </li>
                    <li>
                    <img src={ClipboardText} alt="ClipboardText"/>
                      01 Vacancy
                    </li>
                  </ul>
                </div>
              </div>
              <div className="open-position-bottom">
                <p><span>Deadline:</span> 30 June, 2021</p>
                <a href="" className="arrow-icon">
                <img src={ArrowRight} alt="ArrowRight"/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="open-position-wrap">
              <div className="open-position-header">
                <h3>Director of Accounting</h3>
                <div className="sub-heading-meta">
                <ul>
                    <li>
                    <img src={MapPinLine} alt="MapPinLine"/>
                      Tokyo, Japan
                    </li>
                    <li>
                    <img src={Briefcase} alt="Briefcase"/>
                      Full-Time
                    </li>
                    <li>
                    <img src={ClipboardText} alt="ClipboardText"/>
                      01 Vacancy
                    </li>
                  </ul>
                </div>
              </div>
              <div className="open-position-bottom">
                <p><span>Deadline:</span> 30 June, 2021</p>
                <a href="" className="arrow-icon">
                <img src={ArrowRight} alt="ArrowRight"/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <div className="open-position-wrap mb-0">
              <div className="open-position-header">
                <h3>Principal Software Engineer</h3>
                <div className="sub-heading-meta">
                <ul>
                    <li>
                    <img src={MapPinLine} alt="MapPinLine"/>
                      Tokyo, Japan
                    </li>
                    <li>
                    <img src={Briefcase} alt="Briefcase"/>
                      Full-Time
                    </li>
                    <li>
                    <img src={ClipboardText} alt="ClipboardText"/>
                      01 Vacancy
                    </li>
                  </ul>
                </div>
              </div>
              <div className="open-position-bottom">
                <p><span>Deadline:</span> 30 June, 2021</p>
                <a href="" className="arrow-icon">
                <img src={ArrowRight} alt="ArrowRight"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
             </div>

             </div>




         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
      </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}
export default Carrer;