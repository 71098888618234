import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BsFilterLeft, BsSearch, BsBell } from "react-icons/bs";
import userimg from "../images/usericon.png";
import DashbordNotification from "./DashbordNotification";
import { FaBars, FaSearch } from "react-icons/fa";
import $ from "jquery";

const NavigateContext = React.createContext(null);

class TopMenu extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            showSearch: false
        };
    }
	
    componentWillMount(){
        $(document).ready(function(){
            $("#ser-text").click(function(){
              $("#serch-div-top").show();
              $("#ser-text").hide();
            });

            
          });
    }
	
toggleSearch = () => {
        this.setState(prevState => ({
            showSearch: !prevState.showSearch
        }));
    }

    handleClick = () => {
		console.log('navigate to home page');
		//this.context("/");
        //this.props.history.push("/");
        //this.props.navigation.navigate('/')
        //window.locate.replace('/')
        this.props.navigation.goBack();
    }
    render() {
		const { showSearch } = this.state;
		TopMenu.contextType = NavigateContext;
      return(
          <>
            
          <div className="d-none d-lg-block col-lg-7">
                
                <div className="right-menu-sec">

                <div id="serch-div-top" className="col-lg-6">
                <div className="d-flex serch-sec-div position-relative form-control align-items-center">
                    <FaSearch/>
                    <input type="text" className="form-control" placeholder="Search for Anything"/>
                  
                </div>
            </div>
                   
                        <ul className="list-unstyled m-0 d-flex">
                            <li>
                            <a id="ser-text">
                                <BsSearch/>
                            </a>
                            </li>
                            <li className="nav-item dropdown">
                            <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <BsBell/><span className="notification-count">12</span>
                            </a>
                            <ul className="dropdown-menu cart-dropdown-ne notificaion-dp shadow" aria-labelledby="navbarDropdown">
                                <li>
                                    <div className="d-flex pt-2 px-3 justify-content-between align-content-center">
                                        <h4 className="mb-0"> Notificaions </h4>
                                        <a id="#" className="clear-bnt"> Clear All </a>
                                    </div> 
                                    
                                    <hr/>
                                    <DashbordNotification/>
                                    <NavLink to="/notification" className="ms-auto btn allnoti-btn text-center d-table">
                                        See All
                                    </NavLink>
                                </li>
                            </ul>
                            </li>
                            
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="user-div-top d-flex align-items-center">
                                <figure className="m-0 ms-2">
                                        <img src={userimg} alt="user"/>
                                    </figure>
                                    <h6>  Navdeep Singh </h6>
                                </div>

                                </a>
                                <ul className="dropdown-menu account-div shadow" aria-labelledby="navbarDropdown">
                                    <li>
                                        <a className="dropdown-item">
                                            <span className="d-block"> navdepsingh@gmail.com </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item">
                                        <span className="d-block"> User Profile </span>  
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item">
                                            <span className="d-block"> Nanvdeep Singh </span>
                                        </a>
                                    </li>
                                    <li>
										<button className="dropdown-item" onClick={this.handleClick}>
                                            <span className="d-block"> Logout </span>
                                        </button>
                                    </li>
                                    
                                </ul>
                            </li>
                            
                        </ul>
                </div>
  
           </div>
          </>
      );
    }
  }
export default TopMenu;