import React from "react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

function DatePickerSec1(){
    return(
        <>
        <DatePickerComponent placeholder='Select date'></DatePickerComponent>
        </>
    );
}
export default DatePickerSec1;