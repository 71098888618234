import React from "react";
import fileicon from "../images/file.svg";
import roketicon from "../images/rocket-icon.svg";
import flagicon from "../images/flag-icon.svg";
import chckicon from "../images/check-icon.svg";
import subicon from "../images/file-ck.svg";
import $ from "jquery";


class BookingTable extends React.Component {
  componentWillMount(){

    $(document).ready(function(){
        
      $(function(){
         
        $('.showSingle').click(function(){
              $('.targetDiv').slideUp();
              $('#div'+$(this).attr('target')).slideDown();
        });
      });
    });
    
  }
  render() {
    return (

      <>
      <table className="table mt-4 table-hover inside-sp table-responsive">
        <thead>
        <tr className="bg-row">
            <th scope="col">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                
              </div>
            </th>
            <th scope="col">Course ID</th>
            <th scope="col">Topic</th>
            <th scope="col">Assigned Educator</th>
            <th scope="col">Create Date</th>
            <th scope="col">Last Edit</th>
            <th scope="col">Priority</th>
			<th scope="col">Status</th>
			<th scope="col">Action</th>
        </tr>
        </thead>
      <tbody>
      <tr>
          <td>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
              
          </div>
          </td>
          <td>
          <a className="showSingle" target="1">  #145634 </a> </td>
          <td >
          Laptop Problem
          </td>
          <td className="cm-width">
            Jane Cooper
          </td>
          <td >
            7/02/2024
          </td>
          <td>
		  7/02/2024
          </td>
		  <td>
		  High
          </td>
		  <td>
		  New
          </td>
          <td>
            <div className="d-flex justify-content-center">
               <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2"> ... </button>
            </div>
          </td>
      </tr>
	  
	  <tr>
          <td>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
          </div>
          </td>
          <td>
          <a className="showSingle" target="1">  #145634 </a> </td>
          <td >
          Laptop Problem
          </td>
          <td className="cm-width">
            Jane Cooper
          </td>
          <td >
            7/02/2024
          </td>
          <td>
		  7/02/2024
          </td>
		  <td>
		  High
          </td>
		  <td>
		  New
          </td>
          <td>
            <div className="d-flex justify-content-center">
               <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2"> ... </button>
            </div>
          </td>
      </tr>
	  
	  <tr>
          <td>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
          </div>
          </td>
          <td>
          <a className="showSingle" target="1">  #145634 </a> </td>
          <td >
          Laptop Problem
          </td>
          <td className="cm-width">
            Jane Cooper
          </td>
          <td >
            7/02/2024
          </td>
          <td>
		  7/02/2024
          </td>
		  <td>
		  High
          </td>
		  <td>
		  New
          </td>
          <td>
            <div className="d-flex justify-content-center">
               <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2"> ... </button>
            </div>
          </td>
      </tr>
	  
	  <tr>
          <td>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
          </div>
          </td>
          <td>
          <a className="showSingle" target="1">  #145634 </a> </td>
          <td >
          Laptop Problem
          </td>
          <td className="cm-width">
            Jane Cooper
          </td>
          <td >
            7/02/2024
          </td>
          <td>
		  7/02/2024
          </td>
		  <td>
		  High
          </td>
		  <td>
		  New
          </td>
          <td>
            <div className="d-flex justify-content-center">
               <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2"> ... </button>
            </div>
          </td>
      </tr>
	  
	  <tr>
          <td>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
          </div>
          </td>
          <td>
          <a className="showSingle" target="1">  #145634 </a> </td>
          <td >
          Laptop Problem
          </td>
          <td className="cm-width">
            Jane Cooper
          </td>
          <td >
            7/02/2024
          </td>
          <td>
		  7/02/2024
          </td>
		  <td>
		  High
          </td>
		  <td>
		  New
          </td>
          <td>
            <div className="d-flex justify-content-center">
               <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2"> ... </button>
            </div>
          </td>
      </tr>
	  
	  <tr>
          <td>
          <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
          </div>
          </td>
          <td>
          <a className="showSingle" target="1">  #145634 </a> </td>
          <td >
          Laptop Problem
          </td>
          <td className="cm-width">
            Jane Cooper
          </td>
          <td >
            7/02/2024
          </td>
          <td>
		  7/02/2024
          </td>
		  <td>
		  High
          </td>
		  <td>
		  New
          </td>
          <td>
            <div className="d-flex justify-content-center">
               <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2"> ... </button>
            </div>
          </td>
      </tr>

     
      
      </tbody>
      </table>
      </>
    );
  }
}
export default BookingTable;