import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "./components/BookingCtWeek";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import aboutthumb from '../src/images/about-thumb.png';
import contactthumb from '../src/images/contact-thumb.png';

import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';
import user3 from '../src/images/user3.png';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';
import passport from '../src/images/passport-image.jpg';
import CoursesTable from "./components/CoursesTable"; 
import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";
import netflix from "../src/images/netflix.svg";
import youtube from "../src/images/youtube.svg";
import google from "../src/images/google.svg";
import lenovo from "../src/images/lenovo.svg";
import slack from "../src/images/slack.svg";
import lexmark from "../src/images/lexmark.svg";
import microsoft from "../src/images/microsoft.svg";
import verizon from "../src/images/verizon.svg";
import misionthumb from "../src/images/mision-thumb.png";
import Gallerymain from "../src/images/Gallerymain.png";
import thumb1 from "../src/images/thumb1.jpg";
import thumb2 from "../src/images/thumb2.jpg";
import thumb3 from "../src/images/thumb3.jpg";
import thumb4 from "../src/images/thumb4.jpg";

import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery"; 

class contact extends React.Component {
componentWillMount(){
$(document).ready(function(){
$(".togle-icon").click(function(e) {
e.preventDefault();
$("#navAccordion").toggleClass("active");
$("main").toggleClass("full-width");
$("footer").toggleClass("full-width");
});
});
}
render() {
return(
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
         <div className="bredadcrumb-section">
               <div className="container">
                  <div className="breadcrumb-menu">
                  <h3>Contact</h3>
                     <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">Home</li>                            
                              <li className="breadcrumb-item active" aria-current="page">Contact</li>
                           </ol>
                     </nav>
                  </div>
               </div>
            </div>   
            <div className="contact-us-section">
                  <div className="container">
                     <div className="row">
                     <div className="col-lg-7 order-lg-2"> 
                        <div className="about-thumb responsive_bottom">          
                           <img src={contactthumb} alt="contact-thumb"/>
                        </div>
                     </div>
                     <div className="col-lg-5 d-flex align-items-center">
                        <div className="about-content contact-content">
                           <h2>Connect with us</h2>
                           <p>Want to chat? We’d love to hear from you! Get in touch with our Customer Success Team to inquire about speaking events, advertising rates, or just say hello.</p>
                           <a className="brows-btn mt-4" href="#">
                           <span>
                                                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M21 5.25L12 13.5L3 5.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                      <path d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                      <path d="M10.3636 12L3.2312 18.538" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                      <path d="M20.7687 18.5381L13.6362 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                   </svg>
                                             </span>
                           Copy Email
                           </a>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>
               <div className="branches-section section-padding">
                  <div className="container">
                     <div className="section-title">
                     <h2>Our branches all over the world.</h2>
                     <p>
                        Phasellus sed quam eu eros faucibus cursus. Quisque mauris
                                       urna, imperdiet id leo quis, luctus auctor nisi.
                     </p>
                     </div>
                     <div className="row">
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="branches-wrap">
                           <div className="branches-thumb">
                           <img src={thumb1} alt="thumb1"/>
                           <div className="branches-data">
                              <h5>mAIN BRANCHE</h5>
                              <a href="#">
                                 <h4>Los Angeles, California</h4>
                              </a>
                              <p>1702 Olympic Boulevard Santa Monica, CA 90404</p>
                           </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="branches-wrap">
                           <div className="branches-thumb">
                           <img src={thumb2} alt="thumb2"/>
                           <div className="branches-data">
                              <h5>mAIN BRANCHE</h5>
                              <a href="#">
                                 <h4>Tokyo, Japan</h4>
                              </a>
                              <p>901 N Pitt Str., Suite 170 Tokyo, VA 22314, Japan</p>
                           </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="branches-wrap">
                           <div className="branches-thumb">
                           <img src={thumb3} alt="thumb3"/>
                           <div className="branches-data">
                              <h5>mAIN BRANCHE</h5>
                              <a href="#">
                                 <h4>Moscow, Russia</h4>
                              </a>
                              <p>Anjeliersstraat 470H, 1015 NL Moscow, Russia</p>
                           </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="branches-wrap">
                           <div className="branches-thumb">
                           <img src={thumb4} alt="thumb4"/>
                           <div className="branches-data">
                              <h5>mAIN BRANCHE</h5>
                              <a href="#">
                                 <h4>Mumbai, India</h4>
                              </a>
                              <p>36 East 20th St, 6th Floor Mumbai, India</p>
                           </div>
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>
               <div className="contact-section section-padding">
                  <div className="container">
                     <div className="section-title">
                     <h2>Contact Us</h2>
                     </div>
                     <div className="row">
                     <div className="col-lg-6">
                        <div className="contact-info-wrap">
                           <div className="contact-title mb-2">
                           <h5>
                              Will you be in Los Angeles or any other
                                                   branches any time soon? Stop by the office! We'd love to meet.
                           </h5>
                           </div>
                           <div className="contact-info-item">
                           <h5>Address</h5>
                           <p>
                              1702 Olympic Boulevard
                                                   Santa Monica, CA 90404
                           </p>
                           </div>
                           <div className="contact-info-item">
                           <h5>Phone Number</h5>
                           <p>
                              <a href="#">(480) 555-0103</a>
                              <a href="#">(219) 555-0114</a>
                           </p>
                           </div>
                           <div className="contact-info-item">
                           <h5>Email address</h5>
                           <p>
                              <a href="mailto:someone@yoursite.com">help.Etutor@gmail.com</a> 
                              <a href="mailto:someone@yoursite.com">career.Etutor@gamil.com</a>
                           </p>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="contact-wrap">
                           <div className="contact-title">
                           <h5>Get In touch</h5>
                           <p>Feel free contact with us, we love to make new partners &amp; friends</p>
                           </div>
                           <form action="#">
                           <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                 <div className="form-field mb-3">
                                 <label>Full name</label>
                                 <div className="field-wrapper">
                                    <input type="text" placeholder="Full name" fdprocessedid="u2e0x7"/>
                                 </div>
                                 </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                 <div className="form-field mb-3">
                                 <label>Last name</label>
                                 <div className="field-wrapper">
                                    <input type="text" placeholder="Last name" fdprocessedid="5kdltr"/>
                                 </div>
                                 </div>
                              </div>
                           </div>
                           <div className="form-field mb-3">
                              <label>Email</label>
                              <div className="field-wrapper">
                                 <input type="email" placeholder="Email Address" fdprocessedid="4e6jk"/>
                              </div>
                           </div>
                           <div className="form-field mb-3">
                              <label>Subject</label>
                              <div className="field-wrapper">
                                 <input type="email" placeholder="Message Subject" fdprocessedid="ubcvq"/>
                              </div>
                           </div>
                           <div className="default-input-field">
                              <label>Message</label>
                              <textarea name="textarea" placeholder="Message Subject"></textarea>
                           </div>
                           <button className="brows-btn" fdprocessedid="es89k">
                              Send Message
                              <span>
                                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                         <path d="M20.5816 11.3465L4.74585 2.47843C4.61262 2.40383 4.4598 2.37166 4.30781 2.38624C4.15582 2.40081 4.0119 2.46143 3.89528 2.55999C3.77866 2.65855 3.6949 2.79035 3.65519 2.93779C3.61549 3.08523 3.62173 3.24127 3.67309 3.38506L6.66006 11.7486C6.71832 11.9117 6.71832 12.09 6.66006 12.2531L3.67309 20.6166C3.62173 20.7604 3.61549 20.9165 3.65519 21.0639C3.6949 21.2113 3.77866 21.3431 3.89528 21.4417C4.0119 21.5403 4.15582 21.6009 4.30781 21.6155C4.45981 21.63 4.61263 21.5979 4.74585 21.5233L20.5816 12.6552C20.6979 12.5901 20.7948 12.4951 20.8622 12.3801C20.9296 12.2651 20.9652 12.1342 20.9652 12.0008C20.9652 11.8675 20.9296 11.7366 20.8622 11.6216C20.7948 11.5066 20.6979 11.4116 20.5816 11.3465V11.3465Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                         <path d="M6.75 12H12.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                      </svg>
                                                   </span>

                           </button>
                           </form>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>
               <div className="map-section">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.8529176484453!2d-118.47978242448481!3d34.02198617316845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bb3056a299ef%3A0x94f425adda595d69!2s1702%20Olympic%20Blvd%2C%20Santa%20Monica%2C%20CA%2090404%2C%20USA!5e0!3m2!1sen!2sin!4v1718303538632!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               </div>
         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
      </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}
export default contact;