import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../components/BookingCtWeek";
import BookingCtMonth from "../components/BookingCtMonth";
import RevieCtWeek from "../components/RevieCtWeek";
import RevieCtMonth from "../components/RevieCtMonth";
import CustomerCtweek from "../components/CustomerCtweek";
import CustomerCtMonth from "../components/CustomerCtMonth";
import dsicn from "../../src/images/ds-icon.png";
import clicn from "../../src/images/uil_calender.svg";
import coloricon from "../../src/images/cl-icon-color.svg";
import timeicon from "../../src/images/time.svg";
import useicon from "../../src/images/user-cion.svg";
import downloadicon from "../../src/images/download-icon.svg";
import startrating from "../../src/images/start-r.svg";
import editicon from "../../src/images/edit-icon.svg";
import filtericon from "../../src/images/filter-icon.svg";
import fileicon from "../../src/images/file.svg";
import roketicon from "../../src/images/rocket-icon.svg";
import flagicon from "../../src/images/flag-icon.svg";
import chckicon from "../../src/images/check-icon.svg";
import subicon from "../../src/images/file-ck.svg";
import BookingTable from "../components/BookingTable";
import BookingModal from "../components/BookingModal";
import roundck from "../../src/images/round-check.svg";
import specialicon from "../../src/images/sp-icons.svg";
import studentuser from "../../src/images/student_img.jpg";
import arrowright from "../../src/images/button-arrowright.svg";
import heart from "../../src/images/Heart.svg";
import trophy from "../../src/images/Trophy.svg";

import checksquare from "../../src/images/CheckSquare.svg";
import studentCourse1 from "../../src/images/studentCourse1.jpg";
import studentCourse2 from "../../src/images/studentCourse2.jpg";
import studentCourse3 from "../../src/images/studentCourse3.jpg";
import studentCourse4 from "../../src/images/studentCourse4.jpg";
import usersgroup from "../../src/images/users-group.svg";
import notebook from "../../src/images/Notebook.svg";
import globehemisphere from "../../src/images/GlobeHemisphereWest.svg";
import wavycheck from "../../src/images/CircleWavyCheck.svg";
import stack from "../../src/images/Stack.svg";
import checkcircle from "../../src/images/CheckCircle.svg";
import becomethumb from "../../src/images/become-thumb.png";
import monitor from "../../src/images/monitor.png";
import newspaperclipping from "../../src/images/NewspaperClipping.svg";
import identificationCard from "../../src/images/IdentificationCard.svg";
import playcirclec from "../../src/images/PlayCircle-c.svg";
import handshake from "../../src/images/Handshake.svg";
import ruleunion from "../../src/images/rule-union.png";
import ruleunion2 from "../../src/images/rule-union2.png";
import gallery from "../../src/images/Gallery.png";
import teachingthumb from "../../src/images/teaching-thumb.png";
import pRight from "../../src/images/p-ArrowRight.svg";
import pLeft from "../../src/images/p-ArrowLeft.svg";
import NewRevinew from "../components/NewRevinew";
import ins1 from "../../src/images/ins1.jpg";
import ins2 from "../../src/images/ins2.jpg";
import ins3 from "../../src/images/ins3.jpg";
import ins4 from "../../src/images/ins4.jpg";
import ins5 from "../../src/images/ins5.jpg";
import ins6 from "../../src/images/ins6.jpg";
import ins7 from "../../src/images/ins7.jpg";
import ins8 from "../../src/images/ins8.jpg";
import ins9 from "../../src/images/ins9.jpg";
import ins10 from "../../src/images/ins10.jpg";
import ins11 from "../../src/images/ins11.jpg";
import ins12 from "../../src/images/ins12.jpg";
import courseimages from "../../src/images/course-images.jpg";
import crditCard from "../../src/images/CreditCard.svg";
import visa from "../../src/images/Visa.svg";
import mastercasd from "../../src/images/master-casd.svg";
import paypal from "../../src/images/paypal.svg";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "../components/MobileMenu";
//import Sildebar from "../Sildebar";
import TopMenu from"../components/TopMenu";
import FilerDashboard from "../components/FilerDashboard";
import CalenderMultiple from "../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../components/LabDashboradFilter";
import Newgrphadd from "../../src/images/customer-graph.svg";
import StudentSidebar from "./components/StudentSidebar";
import $ from "jquery";

class Becomeinstructor extends React.Component {
   componentWillMount(){

      $(document).ready(function(){
        $(".togle-icon").click(function(e) {
            e.preventDefault();
            $("#navAccordion").toggleClass("active");
            $("main").toggleClass("full-width");
            $("footer").toggleClass("full-width");
      
      });
      $('.counter').each(function () {
         $(this).prop('Counter',0).animate({
         Counter: $(this).text()
         }, {
         duration: 4000,
         easing: 'swing',
         step: function (now) {
         $(this).text(Math.ceil(now));
         }
         });
         });
      });
      
    }
   render() {
     return(
        <>
        <div className='page-wrapper chiller-theme toggled'>
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
             <StudentSidebar/>
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
          

            <div className="d-none d-lg-block">
                  <div className="top-bar-menu shadow float-start">
                     <a className="tog-btn me-2 togle-icon">
                     <FaBars/>
                     </a>
                     <div className="d-flex me-auto  align-items-center">
                        <h5 className="mb-0 crm-newadd"> Student ID: <span>  NS3608320 </span>  </h5>
                        <h5 className="mb-0 ms-3 crm-newadd"> Student Name:<span> Navdeep Singh </span>  </h5>
                     </div>
                     <TopMenu/>
                  </div>
            </div>
             
            <div className="dasb-div bk-div float-start w-100">
               <div className="bredadcrumb-section">
                  <div className="container">
                     <div className="breadcrumb-menu">
                     <h3>Become An Instructor</h3>
                        <nav aria-label="breadcrumb">
                              <ol className="breadcrumb">
                                 <li className="breadcrumb-item">Home</li>                                                           
                                 <li className="breadcrumb-item active" aria-current="page">Become An Instructor</li>
                              </ol>
                        </nav>
                     </div>
                  </div>
               </div> 
               <div className="contact-us-section become-instructor">
                  <div className="container">
                     <div className="row">
                     <div className="col-lg-6 order-lg-2">
                        <div className="about-thumb responsive_bottom">
                        <img src={becomethumb} alt="becomethumb"/>
                        </div>
                     </div>
                     <div className="col-lg-6 d-flex align-items-center">
                        <div className="about-content become-instructor">
                           <h2>Become an Instuctor</h2>
                           <p>Become an instructor &amp; start teaching with 26k certified instructors. Create a success story with 67.1k Students — Grow yourself with 71 countries.</p>
                           <a className="brows-btn btn" href="#">   Get Started      </a>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>
               <div className="counter-section become-instructor">

               <div className="container">
               <div className="row best-course-list">
                  <div className="col-s-5">
                     <div className="counter-item">
                        <div className="counter-icon">
                              <img src={usersgroup} alt="usersgroup"/>
                        </div>
                        <div className="counter-data">
                        <span className="counter-value"><span className="counter">67.1 </span>K</span> 
                                 <p>Happy Customers</p>
                        </div>
                     </div>
                  </div>
                 
                  <div className="col-s-5">
                     <div className="counter-item">
                        <div className="counter-icon">
                        <img src={notebook} alt="notebook"/>
                        </div>
                        <div className="counter-data">
                          <span className="counter-value"><span className="counter">26 </span>K</span> 
                                 <p>Certified Instructor</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-s-5">
                     <div className="counter-item">
                        <div className="counter-icon">
                        <img src={globehemisphere} alt="globeHemisphere"/>
                        </div>
                        <div className="counter-data">
                        <span className="counter-value"><span className="counter">72 </span></span> 
                                 <p>Country Language</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-s-5">
                     <div className="counter-item">
                        <div className="counter-icon">
                        <img src={wavycheck} alt="wavycheck"/>
                        </div>
                        <div className="counter-data">
                        <span className="counter-value"><span className="counter">99.9</span>%</span> 
                                 <p>Success Rate</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-s-5">
                     <div className="counter-item">
                        <div className="counter-icon">
                        <img src={stack} alt="stack"/>
                        </div>
                        <div className="counter-data">
                        <span className="counter-value"><span className="counter">57 </span>%</span> 
                                 <p>Trusted Companies</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>


               </div>
               <div className="teaching-Etutor-section section-padding">
                  <div className="container">
                     <div className="row align-items-center">
                     <div className="col-xl-6 col-lg-6">
                        <div className="join-team-thumb-wrap">
                        <img src={monitor} alt="monitor"/>
                           
                        </div>
                     </div>
                     <div className="col-xl-5 col-lg-6">
                        <div className="join-team-content become-instructor">
                           <h2>Why you’ll start teaching on Etutor</h2>
                           <p>Praesent congue ornare nibh sed ullamcorper. Proin venenatis tellus non turpis scelerisque, vitae auctor arcu ornare. Cras vitae nulla a purus mollis venenatis.</p>
                           <div className="team-field">
                           <ul>
                              <li>
                              <span className="icon_fill-check">
                              <img src={checkcircle} alt="checkcircle"/> 
                               </span>                                
                                 <div className="field-text">
                                 <span>Tech your students as you want.</span>
                                 Morbi quis lorem non orci fermentum euismod. Nam sapien tellus, aliquam nec porttitor vel, pellentesque at metus.
                                 </div>
                              </li>
                              <li>
                              <span className="icon_fill-check">
                              <img src={checkcircle} alt="checkcircle"/> 
                               </span> 
                               <div className="field-text">   <span>Manage your course, payment in one place</span>
                                 Sed et mattis urna. Sed tempus fermentum est, eu lobortis nibh consequat eu. Nullam vel libero pharetra, euismod turpis et, elementum enim.
                                 </div></li>
                              <li>
                              <span className="icon_fill-check">
                              <img src={checkcircle} alt="checkcircle"/> 
                               </span> 
                               <div className="field-text">    <span>Chat with your students</span>
                                 Nullam mattis lectus ac diam egestas posuere. Praesent auctor massa orci, ut fermentum eros dictum id.
                                 </div>
                              </li>
                           </ul>
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>
               <div className="become-successful-instructor section-padding">
                  <div className="container">
                     <div className="section-title">
                     <h2>
                        How you'll become
                                       successful instructor
                     </h2>
                     </div>
                           <div className="row">
                           <div className="col-xl-3 col-lg-6 col-md-6">
                              <div className="progress-content progress-content-color-5">
                                 <div className="progress-content-left">
                                 <img src={newspaperclipping} alt="newspaperclipping"/>           
                                </div>
                                 <div className="progress-content-right">
                                 <a href="#">1. Apply to become instructor.</a>
                                 <p>Sed et mattis urna. Sed tempus fermentum est, eu lobortis nibh consequat eu. Nullam vel libero pharetra.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-3 col-lg-6 col-md-6">
                              <div className="progress-content progress-content-color-3">
                                 <div className="progress-content-left">
                                 <img src={identificationCard} alt="identificationCard"/>
                                 </div>
                                 <div className="progress-content-right">
                                 <a href="#">2. Setup &amp; edit your profile.</a>
                                 <p>Sed et mattis urna. Sed tempus fermentum est, eu lobortis nibh consequat eu. Nullam vel libero pharetra.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-3 col-lg-6 col-md-6">
                              <div className="progress-content progress-content-color-2">
                                 <div className="progress-content-left">
                                 <img src={playcirclec} alt="playcirclec"/>
                                 </div>
                                 <div className="progress-content-right">
                                 <a href="#">3. Create your new course</a>
                                 <p>Sed et mattis urna. Sed tempus fermentum est, eu lobortis nibh consequat eu. Nullam vel libero pharetra.</p>
                                 </div>
                              </div>
                           </div>
                           <div className="col-xl-3 col-lg-6 col-md-6">
                              <div className="progress-content progress-content-color-1">
                                 <div className="progress-content-left">
                                 <img src={handshake} alt="handshake"/>
                                 </div>
                                 <div className="progress-content-right">
                                 <a href="#">4. Start teaching &amp; earning</a>
                                 <p>Sed et mattis urna. Sed tempus fermentum est, eu lobortis nibh consequat eu. Nullam vel libero pharetra.</p>
                                 </div>
                              </div>
                           </div>
                           </div>
                        </div>
               </div>
                  <div className="instructor-rules section-padding">
                     <div className="container">
                        <div className="row">
                        <div className="col-lg-6 order-lg-2">
                           <div className="about-thumb responsive_bottom">
                           <img src={ruleunion} alt="instructor-banner"  className="img-fluid"/>
                           
                           </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                           <div className="instructor-rules__info">
                              <h2>
                              Instructor rules &  regulations
                              </h2>
                              <p>Sed auctor, nisl non elementum ornare, turpis orci consequat arcu, at iaculis quam leo nec libero. Aenean mollis turpis velit, id laoreet sem luctus in. Etiam et egestas lorem.</p>
                              <ul>
                              <li>Sed ullamcorper libero quis condimentum pellentesque.</li>
                              <li>Nam leo tortor, tempus et felis non.</li>
                              <li>Porttitor faucibus erat. Integer eget purus non massa ultricies pretium ac sed eros.</li>
                              <li>Vestibulum ultrices commodo tellus. Etiam eu lectus sit amet turpi.</li>
                              </ul>
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>

                  <div className="help-center-section section-padding">
                     <div className="container">
                        <div className="row">
                        <div className="col-xl-6 col-lg-6">
                           <div className="join-team-thumb-wrap">
                              <img src="assets/images/Union2.png" alt=""/>
                              <img src={ruleunion2} alt="instructor-banner2"  className="img-fluid"/>
                           </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1 d-flex align-items-center">
                           <div className="join-team-content help-center-content">
                              <h2>Don’t worry we’re always here to help you</h2>
                              <p>Mauris aliquet ornare tortor, ut mollis arcu luctus quis. Phasellus nec augue malesuada, sagittis ligula vel, faucibus metus. Nam viverra metus eget nunc dignissim.</p>
                              <div className="course-list-section">
                              <ul>
                                 <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    This course is for those who want to launch a Freelance Web Design career.
                                 </li>
                                 <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    Those who are looking to reboot their work life and try a new profession that.
                                 </li>
                                 <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    Nunc auctor consequat lorem, in posuere enim hendrerit sed.
                                 </li>
                                 <li>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                    Duis ornare enim ullamcorper congue.
                                 </li>
                              </ul>
                              </div>
                              <div className="help-center-wrap">
                              <a className="help-center-icon" href="">
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 5.25L12 13.5L3 5.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M10.3636 12L3.2312 18.538" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M20.7687 18.5381L13.6362 12" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                              </a>
                              <div className="help-center-data">
                                 <p>Email us, anytime anywhere</p>
                                 <a href="">help.Etutor@gamil.com</a>
                              </div>
                              </div>
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>
                  <div className="gallery-section section-padding">
                     <div className="container">
                        <div className="row">
                        <div className="col-lg-7 order-lg-2">
                           <div className="gallery-thumb responsive_bottom">
                           <img src={gallery} alt="instructor-Gallery"  className="img-fluid"/>
                           </div>
                        </div>
                        <div className="col-lg-5">
                           <div className="our-mission-content become-instructor">
                              <h2>20k+ Instructor created their success story with Etutor</h2>
                              <p>Nunc euismod sapien non felis eleifend porttitor. Maecenas dictum eros justo, 
                              id commodo ante laoreet nec. Phasellus aliquet, orci id pellentesque mollis.</p>
                            
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>


                  <div className="start-teaching">
                     <div className="container">
                     <div className="row">
                        <div className="col-lg-6 order-lg-2">
                           <div className="footer-thumb responsive_bottom">
                           <img src={teachingthumb} alt="teachingthumb"  className="teachingthumb"/>
                           </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                           <div className="footer-header-content">
                           <h3>Start teaching with us and inspire others</h3>
                           <p>Become an instructor &amp; start teaching with 26k certified instructors. Create a success story with 67.1k Students — Grow yourself with 71 countries.</p>
                           <div className="footer-btn-wrap">
                              <a className="brows-btn btn" href="/create-account">Register now</a>
                           </div>
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
            </div>
          </div>
        </main>
       </div>

       
        <div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg">
            <div className="modal-content">
               <div className="modal-header pb-0">
                  
               <ul className="nav nav-pills" id="pills-tab" role="tablist">
                     <li className="nav-item" role="presentation">
                     <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Paitent Details</button>
                     </li>
                     <li className="nav-item" role="presentation">
                     <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
                     </li>
                     
               </ul>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">

                  <div className="tabs-settings">
                     
                     <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              
                              <div className="patinent-details">
                                 <div className="inside-history-div col-lg-12 mx-auto mt-3">
                                    <h6 className="mn-head"> 10 Results Found </h6> 
                                    <div className="result-div mt-3">
                                       <div className="cm-history mx-auto row">
                                          <div className="col-lg-10">
                                             <div className="left-history">
                                                <h5 className="d-lg-flex"> Veena Rao  </h5>
                                                <div className="same-details">
                                                   <span> 30 Yrs, Female </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                   <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                             <div className="left-history mt-5">
                                                <h6 className="nw-add"> Beneficiary </h6>
                                                <h5> Jaswant Thakur </h5>
                                                <div className="same-details">
                                                   <span> 45 Yrs, Male </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                          </div>
                                          <div className="col-lg-2">
                                             <div className="right-sec d-grid justify-content-lg-end">
                                                <h5> Rs. 1500/- </h5>
                                                <h6 className="ps-st">
                                                   <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                                </h6>
                                                <figure className="mb-0">
                                                   <img src={startrating} alt="ck"/>
                                                </figure>
                                                <p className="text-center"> Satisfied </p>
                                             </div>
                                             
                                          </div>
                                       </div>



                                    </div>
                           
                                 </div>

                                 <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                                       <div className="row row-cols-7 justify-content-center">
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={fileicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Accepted 
                                                   <span className="d-block">
                                                   (09:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={roketicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Started
                                                   <span className="d-block">
                                                   (10:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm ">
                                                   <img src={flagicon} alt="file"/>
                                             </figure>
                                             <h5>
                                              Reached
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={specialicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Collected
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={subicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Submitted
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       </div>
                                 </div>
                              </div>

                              
                        
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        
                              <div className="inside-history-div col-lg-11 ms-auto mt-3">
                                 <h6 className="mn-head"> 10 Results Found </h6> 
                                 <div className="result-div mt-3">
                                    <div className="cm-history row">
                                       <div className="col-lg-11">
                                          <div className="left-history">
                                             <h5> Veena Rao
                                             </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span > <FaCircle/> 25-02-2022 </span>
                                                <span > <FaCircle/>  #ORD6848r4 </span>
                                                <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          <div className="left-history mt-5">
                                             <h6 className="nw-add"> Beneficiary </h6>
                                             <h5> Jaswant Thakur </h5>
                                             <div className="same-details">
                                                <span> 45 Yrs, Male </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                       </div>
                                       <div className="col-lg-1">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1500/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 500/- </h5>
                                             <h6 className="unps-st">
                                                Unpaid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 2000/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1200/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>


                                 </div>
                                 
                              </div>

                        </div>
                        
                     </div>
                  </div>






                  



               </div>
               
            </div>
         </div>
        </div>
       
        <MobileMenu/>


       <LabDashboradFilter/>

        <div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <CalenderMultiple/>
                  </div>
                  
               </div>
            </div>
        </div>
        </>
     );
   }
 }

export default Becomeinstructor;