import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../components/BookingCtWeek";
import BookingCtMonth from "../components/BookingCtMonth";
import RevieCtWeek from "../components/RevieCtWeek";
import RevieCtMonth from "../components/RevieCtMonth";
import CustomerCtweek from "../components/CustomerCtweek";
import CustomerCtMonth from "../components/CustomerCtMonth";
import dsicn from "../../src/images/ds-icon.png";
import clicn from "../../src/images/uil_calender.svg";
import coloricon from "../../src/images/cl-icon-color.svg";
import timeicon from "../../src/images/time.svg";
import useicon from "../../src/images/user-cion.svg";
import downloadicon from "../../src/images/download-icon.svg";
import startrating from "../../src/images/start-r.svg";
import editicon from "../../src/images/edit-icon.svg";
import filtericon from "../../src/images/filter-icon.svg";
import fileicon from "../../src/images/file.svg";
import roketicon from "../../src/images/rocket-icon.svg";
import flagicon from "../../src/images/flag-icon.svg";
import chckicon from "../../src/images/check-icon.svg";
import subicon from "../../src/images/file-ck.svg";
import BookingTable from "../components/BookingTable";
import BookingModal from "../components/BookingModal";
import roundck from "../../src/images/round-check.svg";
import specialicon from "../../src/images/sp-icons.svg";
import studentuser from "../../src/images/student_img.jpg";
import arrowright from "../../src/images/button-arrowright.svg";
import profileimage from "../../src/images/profile-image.png";
import uploadicon from "../../src/images/UploadSimple.svg";
import trophy from "../../src/images/Trophy.svg";

import checksquare from "../../src/images/CheckSquare.svg";
import studentCourse1 from "../../src/images/studentCourse1.jpg";
import studentCourse2 from "../../src/images/studentCourse2.jpg";
import studentCourse3 from "../../src/images/studentCourse3.jpg";
import studentCourse4 from "../../src/images/studentCourse4.jpg";
import usersgroup from "../../src/images/users-group.svg";
import pRight from "../../src/images/p-ArrowRight.svg";
import pLeft from "../../src/images/p-ArrowLeft.svg";
import NewRevinew from "../components/NewRevinew";
import ins1 from "../../src/images/ins1.jpg";
import ins2 from "../../src/images/ins2.jpg";
import ins3 from "../../src/images/ins3.jpg";
import ins4 from "../../src/images/ins4.jpg";
import ins5 from "../../src/images/ins5.jpg";
import ins6 from "../../src/images/ins6.jpg";
import ins7 from "../../src/images/ins7.jpg";
import ins8 from "../../src/images/ins8.jpg";
import ins9 from "../../src/images/ins9.jpg";
import ins10 from "../../src/images/ins10.jpg";
import ins11 from "../../src/images/ins11.jpg";
import ins12 from "../../src/images/ins12.jpg";
import Stack from "../../src/images/Stack.svg";
import Doublechecks from "../../src/images/double-Checks.svg";
import ClipboardText from "../../src/images/ClipboardText.svg";
import MonitorPlay from "../../src/images/MonitorPlay.svg";
import playcirclec from "../../src/images/PlayCircle-c.svg";
import imageupload from "../../src/images/image-upload.svg";
import videoupload from "../../src/images/video-upload.svg";
import uploadsample from "../../src/images/Uploadicon.svg";
import pencilgray from "../../src/images/PencilLinegray.svg";
import plusicon from "../../src/images/Plus.svg";
import trashicon from "../../src/images/Trash.svg";
import menuicon from "../../src/images/Menu.svg";
import pencilline from "../../src/images/PencilLine.svg";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope,FaGlobe,FaFacebookF,FaInstagram,FaLinkedinIn,FaTwitter ,FaWhatsapp,FaYoutube ,FaPlus   } from "react-icons/fa";
import MobileMenu from "../components/MobileMenu";
//import Sildebar from "../Sildebar";
import TopMenu from"../components/TopMenu";
import FilerDashboard from "../components/FilerDashboard";
import CalenderMultiple from "../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../components/LabDashboradFilter";
import Newgrphadd from "../../src/images/customer-graph.svg";
import InstructorSidebar from "./components/InstructorSidebar";
import $ from "jquery";

class InstructorCreateCourse extends React.Component {
   componentWillMount(){

      $(document).ready(function(){
        $(".togle-icon").click(function(e) {
            e.preventDefault();
            $("#navAccordion").toggleClass("active");
            $("main").toggleClass("full-width");
            $("footer").toggleClass("full-width");
      
      });
      });
      
    }
   render() {
     return(
        <>
        <div className='page-wrapper chiller-theme toggled'>
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
             <InstructorSidebar/>
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
          

            <div className="d-none d-lg-block">
                  <div className="top-bar-menu shadow float-start">
                     <a className="tog-btn me-2 togle-icon">
                     <FaBars/>
                     </a>
                     <div className="d-flex me-auto  align-items-center">
                        <h5 className="mb-0 crm-newadd"> Student ID: <span>  NS3608320 </span>  </h5>
                        <h5 className="mb-0 ms-3 crm-newadd"> Student Name:<span> Navdeep Singh </span>  </h5>
                     </div>
                     <TopMenu/>
                  </div>
            </div>
             
            <div className="dasb-div bk-div float-start w-100">
          
               <div className="student_wrapper ">
                                    
                     <div className="createtabs-list">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                           <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                              <span className="tabs_text"> <img src={Stack} alt="Stack"/> Basic Information</span>
                              <span className="cnt-info">7/12</span>
                              <span className="check-icon"><img src={Doublechecks} alt="Doublechecks"/></span>
                              </button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                              <span className="tabs_text"> <img src={ClipboardText} alt="ClipboardText"/>Advance Information</span>
                              <span className="cnt-info">7/12</span>
                              <span className="check-icon"><img src={Doublechecks} alt="Doublechecks"/></span>
                              </button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">
                              <span className="tabs_text">
                              <img src={MonitorPlay} alt="MonitorPlay"/> Curriculum</span><span className="cnt-info">7/12</span>
                           <span className="check-icon" ><img src={Doublechecks} alt="Doublechecks"/></span></button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button className="nav-link" id="publish-tab" data-bs-toggle="tab" data-bs-target="#publish-tab-pane" type="button" role="tab" aria-controls="publish-tab-pane" aria-selected="false" >
                              <span className="tabs_text">
                              <img src={playcirclec} alt="playcirclec"/> Publish Course</span><span className="cnt-info">7/12</span>
                              <span className="check-icon"><img src={Doublechecks} alt="Doublechecks"/></span></button>
                              </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                           <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                              <div className="details_tabs">
                              <div className="row">
                                    <div className="col-lg-12 tab_head">
                                    <div className="row">
                                       <div className="col-lg-6 d-flex align-items-center">
                                       <h2>Advance Informations</h2>
                                       </div>
                                       <div className="col-lg-6 head-btn d-flex justify-content-end">
                                       <a className="brows-btn " href="">Save</a> 
                                       <a className="btn-light save-preview-btn" href="">Save & Preview</a> 
                                       </div>
                                        </div>
                                        </div>
                                    </div>

                                 
                                 <div className="profile_form ">  
                                    <form>
                                    <div className="row g-3">                   
                                       <div className="col-md-12 title_input"> 
                                       <label for="title" className="form-label">Title</label>
                                       <input type="text" className="form-control"  placeholder="You course tittle" id="title"/>
                                       <span className="word_counter">0/50</span>
                                       </div>                        
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-md-12 title_input"> 
                                       <label for="subtittle" className="form-label">Subtittle</label>
                                       <input type="text" className="form-control"  placeholder="You course subtittle" id="subtittle"/>
                                       <span className="word_counter">0/120</span>
                                       </div>                        
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-md-6"> 
                                          <label for="course-Category" className="form-label">Course Category</label>
                                          <select id="course-Category" className="form-select">
                                             <option>Select...</option>
                                             <option>Category1</option>
                                             <option>Category2</option>
                                          </select>
                                       </div>     
                                       <div className="col-md-6"> 
                                          <label for="course-sub-Category" className="form-label">Course Sub-category</label>
                                          <select id="course-sub-Category" className="form-select">
                                             <option>Select...</option>
                                             <option>Category1</option>
                                             <option>Category2</option>
                                          </select>
                                       </div>                       
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-md-12 "> 
                                       <label for="CourseTopic" className="form-label">Course Topic</label>
                                       <input type="text" className="form-control"  placeholder="What is primarily taught in your course?" id="CourseTopic"/>
                                     
                                       </div>                        
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="Course-language" className="form-label">Course Language</label>
                                          <select id="Course-language" className="form-select">
                                             <option>Select...</option>
                                             <option>Course-language</option>
                                             <option>Course-language</option>
                                          </select>
                                       </div>     
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="subtitle-Language" className="form-label">Subtitle Language (Optional)</label>
                                          <select id="subtitle-Language" className="form-select">
                                             <option>Select...</option>
                                             <option>subtitle-Language</option>
                                             <option>subtitle-Language</option>
                                          </select>
                                       </div>  
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="course-level" className="form-label">Course Level</label>
                                          <select id="course-level" className="form-select">
                                             <option>Select...</option>
                                             <option>course-level</option>
                                             <option>course-level</option>
                                          </select>
                                       </div>  
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="durations" className="form-label">Durations</label>
                                          <select id="durations" className="form-select">
                                             <option>Day</option>
                                             <option>30day</option>
                                             <option>90days</option>
                                          </select>
                                       </div>                     
                                    </div>
                                    <div className="row g-3">                   
                                    <div className="col-md-6 submit_input"> 
                                       <a href="" className="btn btn-light">Cancel</a>
                                 
                                     </div>      
                                     <div className="col-md-6 submit_input d-flex justify-content-end"> 
                                       <input type="submit" value="Save & next" className="submit_btns"/>
                                 
                                     </div>                        
                                    </div>

                                    </form>
                                 </div>
                              </div>
                              
                             </div>
                              <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                              <div className="details_tabs">
                                
                                    <div className="row">
                                    <div className="col-lg-12 tab_head">
                                    <div className="row">
                                       <div className="col-lg-6 d-flex align-items-center">
                                       <h2>Advance Informations</h2>
                                       </div>
                                       <div className="col-lg-6 head-btn d-flex justify-content-end">
                                       <a className="brows-btn " href="">Save</a> 
                                       <a className="btn-light save-preview-btn" href="">Save & Preview</a> 
                                       </div>
                                        </div>
                                        </div>
                                    </div>
                                 <div className="profile_form ">  
                                    <form>
                                    <div className="row ">
                                    <div className="col-md-12 step-area">
                                       <div className="row">
                                       <div className="col-md-6">
                                          <div className="row gy-0 inforow">
                                          
                                          <div className="col-md-12"><h4>Course Thumbnail</h4></div>
                                             <div className="col-md-4">
                                             <img src={imageupload} alt="imageupload"/>
                                             </div>
                                             <div className="col-md-8">
                                                <div className="upload_text">
                                                   <p>Upload your course Thumbnail here. <strong>Important guidelines:</strong> 1200x800 pixels or 12:8 Ratio. Supported format: <strong>.jpg, .jpeg, or .png</strong></p>
                                                   <div className="upload-btn-wrapper">
                                                      <button className="btn">Upload image  <img src={uploadsample} alt="uploadsample"/></button>
                                                      <input type="file" name="myfile" />
                                                      </div>
                                                
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                       <div className="col-md-6">
                                       <div className="row gy-0 inforow">
                                          <div className="col-md-12"><h4>Course Trailer</h4></div>
                                             <div className="col-md-4">
                                             <img src={videoupload} alt="videoupload"/>
                                             </div>
                                             <div className="col-md-8">
                                                <div className="upload_text">
                                                   <p>Students who watch a well-made promo video are 5X more likely to enroll in your course. We've seen that statistic go up to 10X for exceptionally awesome videos.</p>
                                                   <div className="upload-btn-wrapper">
                                                      <button className="btn">Upload Video <img src={uploadsample} alt="uploadsample"/></button>
                                                      <input type="file" name="myfile" />
                                                      </div>
                                                
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                       </div>
                                    </div>
                                    </div>


                                  
                                    <div className="row ">  
                                    <div className="col-md-12 step-area">
                                       <div className="row">
                                       <div className="col-md-12 step-heading">   <h4>Course Descriptions</h4>   </div>              
                                          <div className="col-md-12 "> 
                                    
                                          <div className="form-floating">
                                          <textarea className="form-control" placeholder="Enter you course descriptions" id="floatingTextarea2" ></textarea>
                                          <label for="floatingTextarea2">Enter you course descriptions</label>
                                          </div>
                                          </div>
                                          </div>  
                                          </div>                         
                                    </div>
                                    <div className="row ">
                                     <div className="col-md-12 step-area">
                                       <div className="row">

                                       <div className="col-md-12 step-heading">  <h4>What you will teach in this course<span>(4/8)</span></h4>   <a className="add_linkrow" >+ Add new</a></div>   
                                          
                                          <div className="col-md-12 title_input"> 
                                          <label for="aa" className="form-label">01</label>
                                          <input type="text" className="form-control"  placeholder="What you will teach in this course..." id="aa"/>
                                          <span className="word_counter">0/120</span>
                                          </div>   
                                          <div className="col-md-12 title_input"> 
                                          <label for="ab" className="form-label">02</label>
                                          <input type="text" className="form-control"  placeholder="What you will teach in this course..." id="ab"/>
                                          <span className="word_counter">0/120</span>
                                          </div>   
                                          <div className="col-md-12 title_input"> 
                                          <label for="ac" className="form-label">03</label>
                                          <input type="text" className="form-control"  placeholder="What you will teach in this course..." id="ac"/>
                                          <span className="word_counter">0/120</span>
                                          </div>  
                                          <div className="col-md-12 title_input"> 
                                          <label for="ad" className="form-label">04</label>
                                          <input type="text" className="form-control"  placeholder="What you will teach in this course..." id="ad"/>
                                          <span className="word_counter">0/120</span>
                                          </div> 
                                          </div>
                                          </div>
                                                         
                                    </div>
                                    <div className="row "> 
                                    <div className="col-md-12 step-area">
                                       <div className="row">
                                       <div className="col-md-12 step-heading">  <h4>Target Audience <span>(4/8)</span></h4>   <a className="add_linkrow" >+ Add new</a></div>   
                                          
                                          <div className="col-md-12 title_input"> 
                                          <label for="ba" className="form-label">01</label>
                                          <input type="text" className="form-control"  placeholder="Who this course is for..." id="ba"/>
                                          <span className="word_counter">0/120</span>
                                          </div>   
                                          <div className="col-md-12 title_input"> 
                                          <label for="bb" className="form-label">02</label>
                                          <input type="text" className="form-control"  placeholder="Who this course is for..." id="bb"/>
                                          <span className="word_counter">0/120</span>
                                          </div>   
                                          <div className="col-md-12 title_input"> 
                                          <label for="bc" className="form-label">03</label>
                                          <input type="text" className="form-control"  placeholder="Who this course is for..." id="bc"/>
                                          <span className="word_counter">0/120</span>
                                          </div>  
                                          <div className="col-md-12 title_input"> 
                                          <label for="bd" className="form-label">04</label>
                                          <input type="text" className="form-control"  placeholder="Who this course is for..." id="bd"/>
                                          <span className="word_counter">0/120</span>
                                          </div> 
                                          </div> 
                                          </div>                  
                                    </div>
                                    <div className="row "> 
                                    <div className="col-md-12 step-area">
                                       <div className="row">
                                       <div className="col-md-12 step-heading">  <h4>Course requirements  <span>(4/8)</span></h4>   <a className="add_linkrow" >+ Add new</a></div>   
                                          
                                          <div className="col-md-12 title_input"> 
                                          <label for="ca" className="form-label">01</label>
                                          <input type="text" className="form-control"  placeholder="What is you course requirements..." id="ca"/>
                                          <span className="word_counter">0/120</span>
                                          </div>   
                                          <div className="col-md-12 title_input"> 
                                          <label for="cb" className="form-label">02</label>
                                          <input type="text" className="form-control"  placeholder="What is you course requirements..." id="cb"/>
                                          <span className="word_counter">0/120</span>
                                          </div>   
                                          <div className="col-md-12 title_input"> 
                                          <label for="cc" className="form-label">03</label>
                                          <input type="text" className="form-control"  placeholder="What is you course requirements..." id="cc"/>
                                          <span className="word_counter">0/120</span>
                                          </div>  
                                          <div className="col-md-12 title_input"> 
                                          <label for="cd" className="form-label">04</label>
                                          <input type="text" className="form-control"  placeholder="What is you course requirements..." id="cd"/>
                                          <span className="word_counter">0/120</span>
                                          </div> 
                                          </div> 
                                            </div>              
                                    </div>
                                    

                                 
                                    
                                    <div className="row ">   
                                    <div className="col-md-12 step-area">
                                       <div className="row">                
                                    <div className="col-md-6 submit_input"> 
                                       <a href="" className="btn btn-light previous-btn">Previous</a>
                                 
                                     </div>      
                                     <div className="col-md-6 submit_input d-flex justify-content-end"> 
                                       <input type="submit" value="Save & next" className="submit_btns"/>
                                       </div>                        
                                    </div>
                                     </div>                        
                                    </div>

                                    </form>
                                 </div>
                              </div>


                              </div>
                              <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                              <div className="details_tabs">
                                    <div className="row">
                                       <div className="col-lg-12 tab_head">
                                       <div className="row">
                                          <div className="col-lg-6 d-flex align-items-center">
                                          <h2>Course Curriculum</h2>
                                          </div>
                                          <div className="col-lg-6 head-btn d-flex justify-content-end">
                                          <a className="brows-btn " href="">Save</a> 
                                          <a className="btn-light save-preview-btn" href="">Save & Preview</a> 
                                          </div>
                                          </div>
                                          </div>
                                    </div>
                                 
                                 <div className="profile_form ">  
                                    <form>
               
                                    <div className="row">   
                                    <div className="col-md-12 section-block">               
                                       <div className="accordion" id="courseadd-ac">
                                          <div className="accordion-item">
                                             <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                             <div className="course-itme-section">
                                             <div className="left-item">
                                                <span className="drap-icon">
                                                <img src={menuicon} alt="menuicon"/> </span>Sections 01:
                                                <div className="section_name"> Section name</div>
                                             </div> 
                                                <div className="right-item  d-flex justify-content-end ">
                                                   <a className="add_newsection"> <img src={plusicon} alt="plusicon"/> </a>
                                                   <a className="edit_section"><img src={pencilgray} alt="pencilgray"/></a>
                                                   <a className="delete_section"><img src={trashicon} alt="trashicon"/></a></div>
                                             </div>
                                                </button>
                                             </h2>
                                             <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#courseadd-ac">
                                                <div className="accordion-body">
                                       <div className="lesson-block">

                                       <div className="accordion" id="lesson-ac">
                                          <div className="accordion-item">
                                             <h2 className="accordion-header" id="lectureone">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#lecture-one" aria-expanded="true" aria-controls="collapseOne">
                                             <div className="course-itme-section">
                                             <div className="left-item">
                                                <span className="drap-icon">
                                                <img src={menuicon} alt="menuicon"/> </span>
                                                <div className="lecture_name"> Lecture name</div>
                                             </div> 
                                                <div className="right-item  d-flex justify-content-end ">
                                                <div className="dropdown ">
                                                      <button className="cs-dropdown  btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                      contents
                                                      </button>
                                                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#edit-video" href="#">Video</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#upload_file" href="#">Attach File</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#lecture-captions" href="#">Captions</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#lecture-description" href="#">Description</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#add-lecture-nots" href="#">Description</a></li>
                                                      </ul>
                                                   </div>
                                                   <a className="edit_section"><img src={pencilgray} alt="pencilgray"/></a>
                                                   <a className="delete_section"><img src={trashicon} alt="trashicon"/></a></div>
                                             </div>
                                                </button>
                                             </h2>
                                             <div id="lecture-one" className="accordion-collapse collapse " aria-labelledby="lectureone" data-bs-parent="#lesson-ac">
                                                <div className="accordion-body">
                                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                </div>
                                             </div>
                                          </div>
                                          <div className="accordion-item">
                                             <h2 className="accordion-header" id="lecturetwo">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#lecture-two" aria-expanded="true" aria-controls="collapseOne">
                                             <div className="course-itme-section">
                                             <div className="left-item">
                                                <span className="drap-icon">
                                                <img src={menuicon} alt="menuicon"/> </span>
                                                <div className="lecture_name"> Lecture name</div>
                                             </div> 
                                                <div className="right-item  d-flex justify-content-end ">
                                                <div className="dropdown ">
                                                      <button className="cs-dropdown  btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                      contents
                                                      </button>
                                                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#edit-video" href="#">Video</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#upload_file" href="#">Attach File</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#lecture-captions" href="#">Captions</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#lecture-description" href="#">Description</a></li>
                                                      <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#add-lecture-nots" href="#">Description</a></li>
                                                     
                                                      </ul>
                                                   </div>
                                                   <a className="edit_section"><img src={pencilgray} alt="pencilgray"/></a>
                                                   <a className="delete_section"><img src={trashicon} alt="trashicon"/></a></div>
                                             </div>
                                                </button>
                                             </h2>
                                             <div id="lecture-two" className="accordion-collapse collapse " aria-labelledby="lecturetwo" data-bs-parent="#lesson-ac">
                                                <div className="accordion-body">
                                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                                </div>
                                             </div>
                                          </div>
                                       
                                    
                                          </div> 
                                      
                                     </div>
                                            </div>
                                                </div>
                                             </div>
                                          </div>
                                          </div> 
                                             <div className="addnew-section col-md-12">
                                         

                                          <button type="button" class="button border-btn" data-bs-toggle="modal" data-bs-target="#edit-section">
                                          Add Sections
                                          </button>
                                                   </div>             
                                          </div>               
                                    

                                    </form>
                                 </div>
                              </div>
                              </div>
                              <div className="tab-pane fade" id="publish-tab-pane" role="tabpanel" aria-labelledby="publish-tab" tabindex="0">
                              <div className="details_tabs">
                              <div className="row">
                                    <div className="col-lg-12 tab_head">
                                    <div className="row">
                                       <div className="col-lg-6 d-flex align-items-center">
                                       <h2>Basic Information</h2>
                                       </div>
                                       <div className="col-lg-6 head-btn d-flex justify-content-end">
                                       <a className="brows-btn " href="">Save</a> 
                                       <a className="btn-light save-preview-btn" href="">Save & Preview</a> 
                                       </div>
                                        </div>
                                        </div>
                                    </div>
                                 
                                 <div className="profile_form ">  
                                    <form>
                                    <div className="row g-3">                   
                                       <div className="col-md-12 title_input"> 
                                       <label for="title" className="form-label">Title</label>
                                       <input type="text" className="form-control"  placeholder="You course tittle" id="title"/>
                                       <span className="word_counter">0/50</span>
                                       </div>                        
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-md-12 title_input"> 
                                       <label for="subtittle" className="form-label">Subtittle</label>
                                       <input type="text" className="form-control"  placeholder="You course subtittle" id="subtittle"/>
                                       <span className="word_counter">0/120</span>
                                       </div>                        
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-md-6"> 
                                          <label for="course-Category" className="form-label">Course Category</label>
                                          <select id="course-Category" className="form-select">
                                             <option>Select...</option>
                                             <option>Category1</option>
                                             <option>Category2</option>
                                          </select>
                                       </div>     
                                       <div className="col-md-6"> 
                                          <label for="course-sub-Category" className="form-label">Course Sub-category</label>
                                          <select id="course-sub-Category" className="form-select">
                                             <option>Select...</option>
                                             <option>Category1</option>
                                             <option>Category2</option>
                                          </select>
                                       </div>                       
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-md-12 "> 
                                       <label for="CourseTopic" className="form-label">Course Topic</label>
                                       <input type="text" className="form-control"  placeholder="What is primarily taught in your course?" id="CourseTopic"/>
                                     
                                       </div>                        
                                    </div>
                                    <div className="row g-3">                   
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="Course-language" className="form-label">Course Language</label>
                                          <select id="Course-language" className="form-select">
                                             <option>Select...</option>
                                             <option>Course-language</option>
                                             <option>Course-language</option>
                                          </select>
                                       </div>     
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="subtitle-Language" className="form-label">Subtitle Language (Optional)</label>
                                          <select id="subtitle-Language" className="form-select">
                                             <option>Select...</option>
                                             <option>subtitle-Language</option>
                                             <option>subtitle-Language</option>
                                          </select>
                                       </div>  
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="course-level" className="form-label">Course Level</label>
                                          <select id="course-level" className="form-select">
                                             <option>Select...</option>
                                             <option>course-level</option>
                                             <option>course-level</option>
                                          </select>
                                       </div>  
                                       <div className="col-lg-3 col-sm-6"> 
                                          <label for="durations" className="form-label">Durations</label>
                                          <select id="durations" className="form-select">
                                             <option>Day</option>
                                             <option>30day</option>
                                             <option>90days</option>
                                          </select>
                                       </div>                     
                                    </div>
                                    <div className="row g-3">                   
                                    <div className="col-md-6 submit_input"> 
                                       <a href="" className="btn btn-light">Cancel</a>
                                 
                                     </div>      
                                     <div className="col-md-6 submit_input d-flex justify-content-end"> 
                                       <input type="submit" value="Save & next" className="submit_btns"/>
                                 
                                     </div>                        
                                    </div>

                                    </form>
                                 </div>
                              </div>
                              </div>
                              </div>
                     </div>


                    
                  </div>
                 
                  
               </div>
            </div>
          
        </main>
       </div>
     



       <div class="modal fade" id="edit-section" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Section Name</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <form>
            <div class="input_wrap">
               <label for="editsection" class="form-label">Section</label>
               <input type="text" class="form-control" placeholder="Write your section name here.." id="editsection" aria-describedby="editsection"/>
            
            </div>


            <div class="action-btn">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn brows-btn">Save Changes</button>
      </div>
         </form> 
      </div>
     
    </div>
  </div>
</div>
<div class="modal fade" id="edit-video" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg " >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Lecture Video</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <form>
         <div class="input-group mb-3">
         <input type="file" class="form-control" id="inputGroupFile02"/>
         <label class="input-group-text" for="inputGroupFile02">Upload File</label>
         </div>

            <div class="action-btn">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn brows-btn">Upload Video</button>
      </div>
         </form> 
      </div>
     
    </div>
  </div>
</div>
<div class="modal fade" id="upload_file" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Lecture Video</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <form>
         <div class="input-group mb-3">
         
        <div class="formbold-mb-5 formbold-file-input">
          <input type="file" name="file" id="file" />
          <label for="file">
            <div className="upload-block">
              <span class="formbold-drop-file"> Attach File </span>
             
              <span class="formbold-browse">Drag an drop a file or <span>browse file</span></span>
            </div>
          </label>
        </div>
         </div>

            <div class="action-btn">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn brows-btn">Upload file</button>
      </div>
         </form> 
      </div>
     
    </div>
  </div>
</div>
<div class="modal fade" id="lecture-captions" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Lecture Video</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <form>
         <div class="input-group mb-3">
         <div class="input-group mb-3">
         <label for="fcaption">Caption</label>
              
               <textarea class="form-control"  placeholder="Write your lecture caption here..." id="fcaption"></textarea>
               </div>
        
         </div>

            <div class="action-btn">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn brows-btn">Add Caption</button>
      </div>
         </form> 
      </div>
     
    </div>
  </div>
</div>
    <div class="modal fade" id="lecture-description" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Lecture Description</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <form>
         <div class="input-group mb-3">
         <div class="input-group mb-3">
         <label for="addDescription">Description</label>
             
   <textarea class="form-control"  placeholder="Write your lecture description here..." id="addDescription"></textarea>
               </div>
        
         </div>

            <div class="action-btn">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn brows-btn">Add Description</button>
      </div>
         </form> 
      </div>
     
    </div>
  </div>
</div>   
       
       <div class="modal fade" id="add-lecture-nots" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Lecture Notes</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <form>
         <div class="input-group mb-3">
         <div class="input-group mb-3">
         <label for="addnotes">notes</label>
             
               <textarea class="form-control"  placeholder="Write your lecture nots here..." id="addnotes"></textarea>
               </div>  
               <div class="input-group mb-3">
         
         <div class="formbold-mb-5 formbold-file-input">
           <input type="file" name="file" id="file" />
           <label for="file">
             <div className="upload-block">
               <span class="formbold-drop-file"> Attach File </span>
              
               <span class="formbold-browse">Drag an drop a file or <span>browse file</span></span>
             </div>
           </label>
         </div>
          </div>      
         </div>

            <div class="action-btn">
        <button type="button" class="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn brows-btn">Add Description</button>
      </div>
         </form> 
      </div>
     
    </div>
  </div>
</div>   
       
       
       <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#lecture-description" href="#">Description</a></li>
        <MobileMenu/>


       <LabDashboradFilter/>

 

    
        </>
     );
   }
 }

export default InstructorCreateCourse;