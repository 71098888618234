import React, { useState } from 'react';
import '../Calendar.css'; // Ensure this path is correct based on your project structure

const Calendar = () => {
  const [date, setDate] = useState(new Date());

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const addMonth = (delta) => {
    const newDate = new Date(date.getFullYear(), date.getMonth() + delta, 1);
    setDate(newDate);
  };

  const renderCalendarHeader = () => (
    <div className="calander">
      <div className="calander_left_section">
      {`${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`}
      </div>
      <div className="calander_right_section">
        <button onClick={() => addMonth(-1)}>&lt;</button>
        <button onClick={() => addMonth(1)}>&gt;</button>
      </div>
    </div>
  );

  const renderDaysOfWeek = () => (
    <div className="calander_grid">
      {daysOfWeek.map(day => (
        <span key={day}>{day}</span>
      ))}
    </div>
  );

  const renderDays = () => {
    const days = [];
    const startDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    const numDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

    for (let i = 0; i < startDay; i++) {
      days.push(<span key={`empty-${i}`} className="empty"> </span>);
    }

    for (let day = 1; day <= numDays; day++) {
      days.push(<span key={day}>{day}</span>);
    }

    return <div className="calander_grid">{days}</div>;
  };

  return (
    <div className="calander_box">
      {renderCalendarHeader()}
      {renderDaysOfWeek()}
      {renderDays()}
    </div>
  );
};

export default Calendar;
