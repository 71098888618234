import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../components/BookingCtWeek";
import BookingCtMonth from "../components/BookingCtMonth";
import RevieCtWeek from "../components/RevieCtWeek";
import RevieCtMonth from "../components/RevieCtMonth";
import CustomerCtweek from "../components/CustomerCtweek";
import CustomerCtMonth from "../components/CustomerCtMonth";
import dsicn from "../../src/images/ds-icon.png";
import clicn from "../../src/images/uil_calender.svg";
import coloricon from "../../src/images/cl-icon-color.svg";
import timeicon from "../../src/images/time.svg";
import useicon from "../../src/images/user-cion.svg";
import downloadicon from "../../src/images/download-icon.svg";
import startrating from "../../src/images/start-r.svg";
import editicon from "../../src/images/edit-icon.svg";
import filtericon from "../../src/images/filter-icon.svg";
import fileicon from "../../src/images/file.svg";
import roketicon from "../../src/images/rocket-icon.svg";
import flagicon from "../../src/images/flag-icon.svg";
import chckicon from "../../src/images/check-icon.svg";
import subicon from "../../src/images/file-ck.svg";
import BookingTable from "../components/BookingTable";
import BookingModal from "../components/BookingModal";
import roundck from "../../src/images/round-check.svg";
import specialicon from "../../src/images/sp-icons.svg";
import studentuser from "../../src/images/student_img.jpg";
import arrowright from "../../src/images/button-arrowright.svg";
import trophy from "../../src/images/Trophy.svg";
import playcirclec from "../../src/images/PlayCircle-c.svg"; 
import checksquare from "../../src/images/CheckSquare.svg";

import dotthree from "../../src/images/DotsThree.svg";
import usercourse from "../../src/images/User-course.svg";
import studentCourse1 from "../../src/images/studentCourse1.jpg";
import studentCourse2 from "../../src/images/studentCourse2.jpg";
import studentCourse3 from "../../src/images/studentCourse3.jpg";
import studentCourse4 from "../../src/images/studentCourse4.jpg";
import searchicon from "../../src/images/search-icon.svg";
import usersgroup from "../../src/images/users-group.svg";
import pRight from "../../src/images/p-ArrowRight.svg";
import pLeft from "../../src/images/p-ArrowLeft.svg";
import NewRevinew from "../components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "../components/MobileMenu";
//import Sildebar from "../Sildebar";
import TopMenu from"../components/TopMenu";
import FilerDashboard from "../components/FilerDashboard";
import CalenderMultiple from "../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../components/LabDashboradFilter";
import Newgrphadd from "../../src/images/customer-graph.svg";
import InstructorSidebar from "./components/InstructorSidebar";
import $ from "jquery";

class Faq extends React.Component {
   componentWillMount(){

      $(document).ready(function(){
        $(".togle-icon").click(function(e) {
            e.preventDefault();
            $("#navAccordion").toggleClass("active");
            $("main").toggleClass("full-width");
            $("footer").toggleClass("full-width");
      
      });
      });
      
    }
   render() {
     return(
        <>
        <div className='page-wrapper chiller-theme toggled instructor-main'>
        <div className="main-sec d-lg-flex ">
          <div className="sidebar-menu" id="navAccordion">
             <InstructorSidebar/>
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
          

            <div className="d-none d-lg-block">
                  <div className="top-bar-menu shadow float-start">
                     <a className="tog-btn me-2 togle-icon">
                     <FaBars/>
                     </a>
                     <div className="d-flex me-auto  align-items-center">
                        <h5 className="mb-0 crm-newadd"> Student ID: <span>  NS3608320 </span>  </h5>
                        <h5 className="mb-0 ms-3 crm-newadd"> Student Name:<span> Navdeep Singh </span>  </h5>
                     </div>
                     <TopMenu/>
                  </div>
            </div>
             
             <div className="dasb-div bk-div float-start w-100">
             <div className="bredadcrumb-section">
               <div className="container">
                  <div className="breadcrumb-menu">
                  <h3>FAQs</h3>
                     <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">Home</li>                            
                              <li className="breadcrumb-item active" aria-current="page">FAQs</li>
                           </ol>
                     </nav>
                  </div>
               </div>
            </div>  

            <div className="faq-section section-padding">
    <div className="container">
      <div className="faq-header">
        <h2>Frequently asked questions</h2>
        <div className="select-box ratting">
          <div className="custom-select-wrapper">
          <select className="custom-select sources  form-select" title="Students d-none">
            <option>4 Star Rating</option>
            <option>3 Star Rating</option>
            <option>2 Star Rating</option>
            <option>1 Star Rating</option>
          </select>
       </div>
        </div> 
      </div>
      <div className="row">
        <div className="col-lg-3">
          <div className="faq-left-sidebar">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="tab1" data-bs-toggle="pill" data-bs-target="#tab01" type="button" role="tab" aria-controls="tab01" aria-selected="true" fdprocessedid="kr5bu">Nulla tempor odio ut fringilla</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab2" data-bs-toggle="pill" data-bs-target="#tab02" type="button" role="tab" aria-controls="tab02" aria-selected="false" fdprocessedid="wdjh2r">Donec malesuada</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab3" data-bs-toggle="pill" data-bs-target="#tab03" type="button" role="tab" aria-controls="tab03" aria-selected="false" fdprocessedid="remcwu">Quisque </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab4" data-bs-toggle="pill" data-bs-target="#tab04" type="button" role="tab" aria-controls="tab04" aria-selected="false" fdprocessedid="c2dzhh">Toquam, in accumsan </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab5" data-bs-toggle="pill" data-bs-target="#tab05" type="button" role="tab" aria-controls="tab05" aria-selected="false" fdprocessedid="nnpih">Ut sed orci</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab6" data-bs-toggle="pill" data-bs-target="#tab06" type="button" role="tab" aria-controls="tab06" aria-selected="false" fdprocessedid="olho0p">Nullam non ante</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab7" data-bs-toggle="pill" data-bs-target="#tab07" type="button" role="tab" aria-controls="tab07" aria-selected="false" fdprocessedid="z0q5a7">Phasellus </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="tab" data-bs-toggle="pill" data-bs-target="#tab08" type="button" role="tab" aria-controls="tab08" aria-selected="false" fdprocessedid="pujh6u">Etiam eu libero elementum </button>
              </li>
            </ul>

          </div>
        </div>
        <div className="col-lg-6">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="tab01" role="tabpanel" aria-labelledby="tab1">
            <div className="accordion" id="faq1">
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar.
                        </button>
                     </h2>
                     <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faq1">
                        <div className="accordion-body">
                       
                    <p>
                      Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                      varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                    </p>
                    <div className="faq-description1">
                      <ul>
                        <li>
                          1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                        </li>
                        <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                        <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                      </ul>
                    </div>
                    <p>
                      Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                      massa
                    </p>
                    <div className="faq-description2">
                      <ul>
                        <li>Donec ut massa</li>
                        <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                      </ul>
                    </div>
                    <p>
                      Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                      vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                    </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Proin lacinia lobortis metus, ut faucibus eros ullamcorper et.
                        </button>
                     </h2>
                     <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq1">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq1">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq1">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq1">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingsix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq1">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingseven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq1">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="tab02" role="tabpanel" aria-labelledby="tab2">
               <div className="accordion" id="faq2">
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar.
                        </button>
                     </h2>
                     <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#faq2">
                        <div className="accordion-body">
                       
                    <p>
                      Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                      varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                    </p>
                    <div className="faq-description1">
                      <ul>
                        <li>
                          1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                        </li>
                        <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                        <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                      </ul>
                    </div>
                    <p>
                      Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                      massa
                    </p>
                    <div className="faq-description2">
                      <ul>
                        <li>Donec ut massa</li>
                        <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                      </ul>
                    </div>
                    <p>
                      Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                      vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                    </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Proin lacinia lobortis metus, ut faucibus eros ullamcorper et.
                        </button>
                     </h2>
                     <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq2">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq2">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq2">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq2">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingsix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq2">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingseven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                        Accordion Item #3
                        </button>
                     </h2>
                     <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq2">
                     <div className="accordion-body">
                       
                       <p>
                         Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed
                         varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis.
                       </p>
                       <div className="faq-description1">
                         <ul>
                           <li>
                             1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi.
                           </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                         </ul>
                       </div>
                       <p>
                         Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut
                         massa
                       </p>
                       <div className="faq-description2">
                         <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                         </ul>
                       </div>
                       <p>
                         Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse
                         vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat.
                       </p> </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="tab03" role="tabpanel" aria-labelledby="tab3">
               <div className="accordion" id="faq3">
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar. </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#faq3">
                           <div className="accordion-body">
                           <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                           <div className="faq-description1">
                              <ul>
                                 <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                                 <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                                 <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                              </ul>
                           </div>
                           <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                           <div className="faq-description2">
                              <ul>
                                 <li>Donec ut massa</li>
                                 <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                              </ul>
                           </div>
                           <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Proin lacinia lobortis metus, ut faucibus eros ullamcorper et. </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq3">
                           <div className="accordion-body">
                           <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                           <div className="faq-description1">
                              <ul>
                                 <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                                 <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                                 <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                              </ul>
                           </div>
                           <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                           <div className="faq-description2">
                              <ul>
                                 <li>Donec ut massa</li>
                                 <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                              </ul>
                           </div>
                           <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Accordion Item #3 </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq3">
                           <div className="accordion-body">
                           <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                           <div className="faq-description1">
                              <ul>
                                 <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                                 <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                                 <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                              </ul>
                           </div>
                           <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                           <div className="faq-description2">
                              <ul>
                                 <li>Donec ut massa</li>
                                 <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                              </ul>
                           </div>
                           <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfour">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour"> Accordion Item #3 </button>
                        </h2>
                        <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq3">
                           <div className="accordion-body">
                           <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                           <div className="faq-description1">
                              <ul>
                                 <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                                 <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                                 <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                              </ul>
                           </div>
                           <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                           <div className="faq-description2">
                              <ul>
                                 <li>Donec ut massa</li>
                                 <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                              </ul>
                           </div>
                           <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="headingfive">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"> Accordion Item #3 </button>
                        </h2>
                        <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq3">
                           <div className="accordion-body">
                           <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                           <div className="faq-description1">
                              <ul>
                                 <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                                 <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                                 <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                              </ul>
                           </div>
                           <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                           <div className="faq-description2">
                              <ul>
                                 <li>Donec ut massa</li>
                                 <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                              </ul>
                           </div>
                           <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="headingsix">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Accordion Item #3 </button>
                        </h2>
                        <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq3">
                           <div className="accordion-body">
                           <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                           <div className="faq-description1">
                              <ul>
                                 <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                                 <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                                 <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                              </ul>
                           </div>
                           <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                           <div className="faq-description2">
                              <ul>
                                 <li>Donec ut massa</li>
                                 <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                              </ul>
                           </div>
                           <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                           </div>
                        </div>
                     </div>
                     <div className="accordion-item">
                        <h2 className="accordion-header" id="headingseven">
                           <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"> Accordion Item #3 </button>
                        </h2>
                        <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq3">
                           <div className="accordion-body">
                           <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                           <div className="faq-description1">
                              <ul>
                                 <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                                 <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                                 <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                              </ul>
                           </div>
                           <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                           <div className="faq-description2">
                              <ul>
                                 <li>Donec ut massa</li>
                                 <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                              </ul>
                           </div>
                           <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                           </div>
                        </div>
                     </div>
               </div>
            </div>
            <div className="tab-pane fade" id="tab04" role="tabpanel" aria-labelledby="tab4">
               <div className="accordion" id="faq4">
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar. </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#faq4">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Proin lacinia lobortis metus, ut faucibus eros ullamcorper et. </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq4">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq4">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq4">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfive">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq4">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq4">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq4">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
             </div>
            </div>
            <div className="tab-pane fade" id="tab05" role="tabpanel" aria-labelledby="tab5">
               <div className="accordion" id="faq5">
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar. </button>
                     </h2>
                     <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#faq5">
                        <div className="accordion-body">
                        <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                        <div className="faq-description1">
                           <ul>
                              <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                              <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                              <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                           </ul>
                        </div>
                        <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                        <div className="faq-description2">
                           <ul>
                              <li>Donec ut massa</li>
                              <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                           </ul>
                        </div>
                        <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                        </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Proin lacinia lobortis metus, ut faucibus eros ullamcorper et. </button>
                     </h2>
                     <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq5">
                        <div className="accordion-body">
                        <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                        <div className="faq-description1">
                           <ul>
                              <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                              <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                              <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                           </ul>
                        </div>
                        <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                        <div className="faq-description2">
                           <ul>
                              <li>Donec ut massa</li>
                              <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                           </ul>
                        </div>
                        <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                        </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Accordion Item #3 </button>
                     </h2>
                     <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq5">
                        <div className="accordion-body">
                        <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                        <div className="faq-description1">
                           <ul>
                              <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                              <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                              <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                           </ul>
                        </div>
                        <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                        <div className="faq-description2">
                           <ul>
                              <li>Donec ut massa</li>
                              <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                           </ul>
                        </div>
                        <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                        </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingfour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour"> Accordion Item #3 </button>
                     </h2>
                     <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq5">
                        <div className="accordion-body">
                        <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                        <div className="faq-description1">
                           <ul>
                              <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                              <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                              <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                           </ul>
                        </div>
                        <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                        <div className="faq-description2">
                           <ul>
                              <li>Donec ut massa</li>
                              <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                           </ul>
                        </div>
                        <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                        </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingfive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"> Accordion Item #3 </button>
                     </h2>
                     <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq5">
                        <div className="accordion-body">
                        <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                        <div className="faq-description1">
                           <ul>
                              <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                              <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                              <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                           </ul>
                        </div>
                        <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                        <div className="faq-description2">
                           <ul>
                              <li>Donec ut massa</li>
                              <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                           </ul>
                        </div>
                        <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                        </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingsix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Accordion Item #3 </button>
                     </h2>
                     <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq5">
                        <div className="accordion-body">
                        <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                        <div className="faq-description1">
                           <ul>
                              <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                              <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                              <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                           </ul>
                        </div>
                        <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                        <div className="faq-description2">
                           <ul>
                              <li>Donec ut massa</li>
                              <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                           </ul>
                        </div>
                        <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                        </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="headingseven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"> Accordion Item #3 </button>
                     </h2>
                     <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq5">
                        <div className="accordion-body">
                        <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                        <div className="faq-description1">
                           <ul>
                              <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                              <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                              <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                           </ul>
                        </div>
                        <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                        <div className="faq-description2">
                           <ul>
                              <li>Donec ut massa</li>
                              <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                           </ul>
                        </div>
                        <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="tab-pane fade" id="tab06" role="tabpanel" aria-labelledby="tab6">
            <div className="accordion" id="faq6">
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar. </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#faq6">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Proin lacinia lobortis metus, ut faucibus eros ullamcorper et. </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq6">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq6">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq6">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfive">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq6">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq6">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq6">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
             </div>
            </div>
            <div className="tab-pane fade" id="tab07" role="tabpanel" aria-labelledby="tab7">
            <div className="accordion" id="faq7">
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar. </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#faq7">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Proin lacinia lobortis metus, ut faucibus eros ullamcorper et. </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq7">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq7">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq7">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfive">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq7">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq7">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq7">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
             </div>
            </div>
            <div className="tab-pane fade" id="tab08" role="tabpanel">
            <div className="accordion" id="faq8">
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                     <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Fusce placerat interdum magna, ut ultrices odio pharetra pulvinar. </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#faq8">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Proin lacinia lobortis metus, ut faucibus eros ullamcorper et. </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq8">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq8">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfour">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq8">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingfive">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq8">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingsix">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#faq8">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
               <div className="accordion-item">
                  <h2 className="accordion-header" id="headingseven">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven"> Accordion Item #3 </button>
                  </h2>
                  <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#faq8">
                     <div className="accordion-body">
                     <p> Aliquam semper tellus vel lacus rutrum mollis. Nunc vitae iaculis lacus, id fringilla leo. Nulla dictum, enim nec bibendum auctor, lorem mi rutrum urna, sed luctus urna nibh sit amet velit. Sed varius sem semper leo ultricies tincidunt. Etiam id ligula ut augue auctor molestie ut quis felis. </p>
                     <div className="faq-description1">
                        <ul>
                           <li> 1.Sed lorem elit, aliquam vel neque condimentum, blandit cursus nisi. </li>
                           <li>2.Cras ullamcorper posuere felis et vehicula.</li>
                           <li>3.Donec dignissim metus felis, non posuere arcu finibus a.</li>
                        </ul>
                     </div>
                     <p> Sed interdum dignissim odio, vitae mollis nisi congue nec. Ut tellus metus, posuere vel odio ut, ullamcorper rutrum ex. Curabitur porttitor sem nec felis mollis, nec laoreet leo iaculis. Donec ut massa </p>
                     <div className="faq-description2">
                        <ul>
                           <li>Donec ut massa</li>
                           <li>ac magna iaculis imperdiet ut viverra arcu.</li>
                        </ul>
                     </div>
                     <p> Proin quis elementum velit, eget efficitur nulla. Donec tellus massa, faucibus id nulla sit amet, feugiat viverra justo. Curabitur auctor nibh ut ante lacinia, ac finibus sem pulvinar. Suspendisse vestibulum in dolor eget sodales. Curabitur justo risus, vehicula ac mollis sit amet, gravida sed erat. </p>
                     </div>
                  </div>
               </div>
             </div>
            </div>
          </div>

        </div>
        <div className="col-lg-3">
          <div className="faq-right-sidebar">
            <h4>Don’t find your answer!</h4>
            <p>Don’t worry, write your question here and our support team will help you.</p>
            <form action="#">
              <div className="sidebar-field">
                <input type="text" placeholder="Subject" fdprocessedid="5tiqrk"/>
              </div>
              <div className="sidebar-field">
                <textarea name="textarea" placeholder="Message"></textarea>
              </div>
              <button className="brows-btn" fdprocessedid="sn7tm">Submit question</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

           
            
            </div>
          </div>
        </main>
       </div>

       
       
        <MobileMenu/>


       <LabDashboradFilter/>

        <div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <CalenderMultiple/>
                  </div>
                  
               </div>
            </div>
        </div>
        </>
     );
   }
 }

export default Faq;