import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    {
      name: '1',
      uv: 7000,
      pv: 7400,
      amt: 2400,
    },
    {
      name: '2',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: '3',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: '4',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: '5',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: '6',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: '7',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
        name: '8',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    
      {
        name: '9',
        uv: 5490,
        pv: 4300,
        amt: 2100,
      },
  ];

  function AnalGraphMonth(){
      return(
          <>
          <BarChart
          width={700}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
         
          <Bar dataKey="pv" fill="#6D88CF" background={{ fill: '#eee' }} />
          <Bar dataKey="uv" fill="#ECF1FE" />
        </BarChart>
          </>
      );
  }
  export default AnalGraphMonth;