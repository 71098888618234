import React from "react";
import fileicon from "../images/file.svg";
import roketicon from "../images/rocket-icon.svg";
import flagicon from "../images/flag-icon.svg";
import chckicon from "../images/check-icon.svg";
import subicon from "../images/file-ck.svg";
import user1 from '../images/user1.png';
import user2 from '../images/user2.png';
import user3 from '../images/user3.png';
import addplus from '../images/add-plus.svg';
import viewicon from "../images/view_icon.svg";
import filledcheck from '../images/Filled-check.svg';
import $ from "jquery";


class InternationSpacePass extends React.Component {
  componentWillMount(){

    $(document).ready(function(){
        
      $(function(){
         
        $('.showSingle').click(function(){
              $('.targetDiv').slideUp();
              $('#div'+$(this).attr('target')).slideDown();
        });
      });
    });
    
  }
  render() {
    return (

      <>
          <div className="transisition-block pass-area-block">
           <div className="pass-heading"> Recent Transaction </div>
             <div className="passList">
              <div className="in-box-tx">
                <div className="head-block"> Credit  
                  <span>  
                  500
                  </span>
                </div>
                   <div className="content-block">
                    <div className="mainbody"><div class="danger">15 April 2024, 7: 15 pm</div></div>
                     <div className="usr-block">
                      <div className="photo-circles">
                      <div className="circle" style={{ backgroundImage: `url(${user1})` }}></div>
                      <div className="circle" style={{ backgroundImage: `url(${user2})` }}></div>
                      <div className="circle" style={{ backgroundImage: `url(${user3})` }}></div>
                      </div>
                    <div className="usr-check">
                    <span className="adclick-img"> <img className="ad-img" src={addplus} alt="addplus"/></span> <img  src={filledcheck} alt="Filled-check"/></div>
                     </div>
                   </div>
              </div>
              <div className="in-box-tx">
                <div className="head-block"> Debit 
                  <span>  
                  <div class="ellipsis">…</div>
                  </span>
                </div>
                   <div className="content-block">
                    <div className="mainbody"><div class="debit">13 April 2024, 7: 15 pm</div></div>
                     <div className="usr-block">
                      <div className="photo-circles">
                      <div className="circle" style={{ backgroundImage: `url(${user1})` }}></div>
                      <div className="circle" style={{ backgroundImage: `url(${user2})` }}></div>
                      <div className="circle" style={{ backgroundImage: `url(${user3})` }}></div>
                      </div>
                    <div className="usr-check">
                    <span className="adclick-img"> <img className="ad-img" src={addplus} alt="addplus"/></span> <img  src={filledcheck} alt="Filled-check"/></div>
                     </div>
                   </div>
              </div>
              <div className="in-box-tx">
                <div className="head-block"> hold 
                  <span>  
                  <div class="ellipsis">…</div>
                  </span>
                </div>
                   <div className="content-block">
                    <div className="mainbody"><div class="hold">2 weeks left, 7pm</div></div>
                     <div className="usr-block">
                      <div className="photo-circles">
                      <div className="circle" style={{ backgroundImage: `url(${user1})` }}></div>
                      <div className="circle" style={{ backgroundImage: `url(${user2})` }}></div>
                      <div className="circle" style={{ backgroundImage: `url(${user3})` }}></div>
                      </div>
                    <div className="usr-check">
                    <span className="adclick-img"> <img className="ad-img" src={addplus} alt="addplus"/></span> <img  src={filledcheck} alt="Filled-check"/></div>
                     </div>
                   </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}
export default InternationSpacePass;