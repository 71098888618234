import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../components/BookingCtWeek";
import BookingCtMonth from "../components/BookingCtMonth";
import RevieCtWeek from "../components/RevieCtWeek";
import RevieCtMonth from "../components/RevieCtMonth";
import CustomerCtweek from "../components/CustomerCtweek";
import CustomerCtMonth from "../components/CustomerCtMonth";
import dsicn from "../../src/images/ds-icon.png";
import clicn from "../../src/images/uil_calender.svg";
import coloricon from "../../src/images/cl-icon-color.svg";
import timeicon from "../../src/images/time.svg";
import useicon from "../../src/images/user-cion.svg";
import downloadicon from "../../src/images/download-icon.svg";
import startrating from "../../src/images/start-r.svg";
import editicon from "../../src/images/edit-icon.svg";
import filtericon from "../../src/images/filter-icon.svg";
import fileicon from "../../src/images/file.svg";
import roketicon from "../../src/images/rocket-icon.svg";
import flagicon from "../../src/images/flag-icon.svg";
import chckicon from "../../src/images/check-icon.svg";
import subicon from "../../src/images/file-ck.svg";
import BookingTable from "../components/BookingTable";
import BookingModal from "../components/BookingModal";
import roundck from "../../src/images/round-check.svg";
import specialicon from "../../src/images/sp-icons.svg";
import studentuser from "../../src/images/student_img.jpg";
import arrowright from "../../src/images/button-arrowright.svg";
import heart from "../../src/images/Heart.svg";
import trophy from "../../src/images/Trophy.svg";
import playcirclec from "../../src/images/PlayCircle-c.svg";
import checksquare from "../../src/images/CheckSquare.svg";
import studentCourse1 from "../../src/images/studentCourse1.jpg";
import studentCourse2 from "../../src/images/studentCourse2.jpg";
import studentCourse3 from "../../src/images/studentCourse3.jpg";
import studentCourse4 from "../../src/images/studentCourse4.jpg";
import usersgroup from "../../src/images/users-group.svg";
import pRight from "../../src/images/p-ArrowRight.svg";
import pLeft from "../../src/images/p-ArrowLeft.svg";
import NewRevinew from "../components/NewRevinew";
import ins1 from "../../src/images/ins1.jpg";
import ins2 from "../../src/images/ins2.jpg";
import ins3 from "../../src/images/ins3.jpg";
import ins4 from "../../src/images/ins4.jpg";
import ins5 from "../../src/images/ins5.jpg";
import ins6 from "../../src/images/ins6.jpg";
import ins7 from "../../src/images/ins7.jpg";
import ins8 from "../../src/images/ins8.jpg";
import ins9 from "../../src/images/ins9.jpg";
import ins10 from "../../src/images/ins10.jpg";
import ins11 from "../../src/images/ins11.jpg";
import ins12 from "../../src/images/ins12.jpg";
import pencilline from "../../src/images/PencilLine.svg";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "../components/MobileMenu";
//import Sildebar from "../Sildebar";
import TopMenu from"../components/TopMenu";
import FilerDashboard from "../components/FilerDashboard";
import CalenderMultiple from "../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../components/LabDashboradFilter";
import Newgrphadd from "../../src/images/customer-graph.svg";
import StudentSidebar from "./components/StudentSidebar";
import $ from "jquery";

class StudentWatchlist extends React.Component {
   componentWillMount(){

      $(document).ready(function(){
        $(".togle-icon").click(function(e) {
            e.preventDefault();
            $("#navAccordion").toggleClass("active");
            $("main").toggleClass("full-width");
            $("footer").toggleClass("full-width");
      
      });
      });
      
    }
   render() {
     return(
        <>
        <div className='page-wrapper chiller-theme toggled'>
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
             <StudentSidebar/>
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
          

            <div className="d-none d-lg-block">
                  <div className="top-bar-menu shadow float-start">
                     <a className="tog-btn me-2 togle-icon">
                     <FaBars/>
                     </a>
                     <div className="d-flex me-auto  align-items-center">
                        <h5 className="mb-0 crm-newadd"> Student ID: <span>  NS3608320 </span>  </h5>
                        <h5 className="mb-0 ms-3 crm-newadd"> Student Name:<span> Navdeep Singh </span>  </h5>
                     </div>
                     <TopMenu/>
                  </div>
            </div>
             
            <div className="dasb-div bk-div float-start w-100">

<div className="student_wrapper ">
    
     <div className="student-top-head col-lg-12">
     <div className="row align-items-centerow">
        <div className="col-lg-7 ">
           <div className="student_info ">
              <div className="student_image"><img src={studentuser} alt="studentusercl"/> </div>
              <div className="student_content">
                 <h3>Kevin Gilbert</h3>
                 <p>Web Designer & Best-Selling Instructor</p>
              </div>
           </div>
        </div>
        <div className="col-lg-5 text-right d-flex justify-content-end"> <a className="brows-btn">Become Instructor <img src={arrowright} alt="headphones"/> </a>
        </div>
     </div>
       </div>
       <div className="student-course-list row"><div className="col-lg-12  heading-box"><h2>Wishlist <span>(3)</span></h2></div></div>
       <div className="wishlist_table">
<table className="table">
  <thead>
    <tr>
      <th className="course-th" scope="col">Course</th>
      <th className="price-th" scope="col">Prices</th>
      <th className="action-th" scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
   <td className="course-td">
      <div className="wishlist_course">
            <div className="wishlist_course-image">
            <img src={studentCourse1} alt="studentCourse1"/>
          </div>
            <div className="wishlist_course_detail">
               <div className="cr-rating">
               <span className="star-icon"><FaStar /></span><span>4.8</span> (134,633 review) </div>
               <div className="cr-name"><a>The Ultimate Drawing Course - Beginner to Advanced</a></div>
               <div className="author_ct-name">Course by: <span> Harry Potter <span> John Wick </span></span></div>  	  
         </div>
      </div>
      </td>
   <td className="prices-td">
      <div className=""><span className="price-value"><span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span></span></div>
   </td>
     <td className="action-td">
         <div className="action_btn">
            <a className="btn" href="">buy now</a>
            <a className="btn addcart-btn" href="">Add to Cart</a>
            <a className="btn wishlisbtn" href="#"><img src={heart} alt="heart"/></a>
         </div>
	 </td>
    </tr>
    <tr>
   <td className="course-td">
      <div className="wishlist_course">
            <div className="wishlist_course-image">
            <img src={studentCourse1} alt="studentCourse1"/>
          </div>
            <div className="wishlist_course_detail">
               <div className="cr-rating">
               <span className="star-icon"><FaStar /></span><span>4.8</span> (134,633 review) </div>
               <div className="cr-name"><a>The Ultimate Drawing Course - Beginner to Advanced</a></div>
               <div className="author_ct-name">Course by: <span> Harry Potter <span> John Wick </span></span></div>  	  
         </div>
      </div>
      </td>
   <td className="prices-td">
      <div className=""><span className="price-value"><span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span></span></div>
   </td>
     <td className="action-td">
         <div className="action_btn">
            <a className="btn" href="">buy now</a>
            <a className="btn addcart-btn" href="">Add to Cart</a>
            <a className="btn wishlisbtn" href="#"><img src={heart} alt="heart"/></a>
         </div>
	 </td>
    </tr>
    <tr>
   <td className="course-td">
      <div className="wishlist_course">
            <div className="wishlist_course-image">
            <img src={studentCourse1} alt="studentCourse1"/>
          </div>
            <div className="wishlist_course_detail">
               <div className="cr-rating">
               <span className="star-icon"><FaStar /></span><span>4.8</span> (134,633 review) </div>
               <div className="cr-name"><a>The Ultimate Drawing Course - Beginner to Advanced</a></div>
               <div className="author_ct-name">Course by: <span> Harry Potter <span> John Wick </span></span></div>  	  
         </div>
      </div>
      </td>
   <td className="prices-td">
      <div className=""><span className="price-value"><span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span></span></div>
   </td>
     <td className="action-td">
         <div className="action_btn">
            <a className="btn" href="">buy now</a>
            <a className="btn addcart-btn" href="">Add to Cart</a>
            <a className="btn wishlisbtn" href="#"><img src={heart} alt="heart"/></a>
         </div>
	 </td>
    </tr>
    <tr>
   <td className="course-td">
      <div className="wishlist_course">
            <div className="wishlist_course-image">
            <img src={studentCourse1} alt="studentCourse1"/>
          </div>
            <div className="wishlist_course_detail">
               <div className="cr-rating">
               <span className="star-icon"><FaStar /></span><span>4.8</span> (134,633 review) </div>
               <div className="cr-name"><a>The Ultimate Drawing Course - Beginner to Advanced</a></div>
               <div className="author_ct-name">Course by: <span> Harry Potter <span> John Wick </span></span></div>  	  
         </div>
      </div>
      </td>
   <td className="prices-td">
      <div className=""><span className="price-value"><span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span></span></div>
   </td>
     <td className="action-td">
         <div className="action_btn">
            <a className="btn" href="">buy now</a>
            <a className="btn addcart-btn" href="">Add to Cart</a>
            <a className="btn wishlisbtn" href="#"><img src={heart} alt="heart"/></a>
         </div>
	 </td>
    </tr>
    <tr>
   <td className="course-td">
      <div className="wishlist_course">
            <div className="wishlist_course-image">
            <img src={studentCourse1} alt="studentCourse1"/>
          </div>
            <div className="wishlist_course_detail">
               <div className="cr-rating">
               <span className="star-icon"><FaStar /></span><span>4.8</span> (134,633 review) </div>
               <div className="cr-name"><a>The Ultimate Drawing Course - Beginner to Advanced</a></div>
               <div className="author_ct-name">Course by: <span> Harry Potter <span> John Wick </span></span></div>  	  
         </div>
      </div>
      </td>
   <td className="prices-td">
      <div className=""><span className="price-value"><span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span></span></div>
   </td>
     <td className="action-td">
         <div className="action_btn">
            <a className="btn" href="">buy now</a>
            <a className="btn addcart-btn" href="">Add to Cart</a>
            <a className="btn wishlisbtn" href="#"><img src={heart} alt="heart"/></a>
         </div>
	 </td>
    </tr>
    <tr>
   <td className="course-td">
      <div className="wishlist_course">
            <div className="wishlist_course-image">
            <img src={studentCourse1} alt="studentCourse1"/>
          </div>
            <div className="wishlist_course_detail">
               <div className="cr-rating">
               <span className="star-icon"><FaStar /></span><span>4.8</span> (134,633 review) </div>
               <div className="cr-name"><a>The Ultimate Drawing Course - Beginner to Advanced</a></div>
               <div className="author_ct-name">Course by: <span> Harry Potter <span> John Wick </span></span></div>  	  
         </div>
      </div>
      </td>
   <td className="prices-td">
      <div className=""><span className="price-value"><span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span></span></div>
   </td>
     <td className="action-td">
         <div className="action_btn">
            <a className="btn" href="">buy now</a>
            <a className="btn addcart-btn" href="">Add to Cart</a>
            <a className="btn wishlisbtn" href="#"><img src={heart} alt="heart"/></a>
         </div>
	 </td>
    </tr>
    <tr>
   <td className="course-td">
      <div className="wishlist_course">
            <div className="wishlist_course-image">
            <img src={studentCourse1} alt="studentCourse1"/>
          </div>
            <div className="wishlist_course_detail">
               <div className="cr-rating">
               <span className="star-icon"><FaStar /></span><span>4.8</span> (134,633 review) </div>
               <div className="cr-name"><a>The Ultimate Drawing Course - Beginner to Advanced</a></div>
               <div className="author_ct-name">Course by: <span> Harry Potter <span> John Wick </span></span></div>  	  
         </div>
      </div>
      </td>
   <td className="prices-td">
      <div className=""><span className="price-value"><span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span></span></div>
   </td>
     <td className="action-td">
         <div className="action_btn">
            <a className="btn" href="">buy now</a>
            <a className="btn addcart-btn" href="">Add to Cart</a>
            <a className="btn wishlisbtn" href="#"><img src={heart} alt="heart"/></a>
         </div>
	 </td>
    </tr>
  </tbody>
</table>
</div>

 
     
  </div>
</div>
          </div>
        </main>
       </div>

       
        <div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg">
            <div className="modal-content">
               <div className="modal-header pb-0">
                  
               <ul className="nav nav-pills" id="pills-tab" role="tablist">
                     <li className="nav-item" role="presentation">
                     <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Paitent Details</button>
                     </li>
                     <li className="nav-item" role="presentation">
                     <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
                     </li>
                     
               </ul>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">

                  <div className="tabs-settings">
                     
                     <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              
                              <div className="patinent-details">
                                 <div className="inside-history-div col-lg-12 mx-auto mt-3">
                                    <h6 className="mn-head"> 10 Results Found </h6> 
                                    <div className="result-div mt-3">
                                       <div className="cm-history mx-auto row">
                                          <div className="col-lg-10">
                                             <div className="left-history">
                                                <h5 className="d-lg-flex"> Veena Rao  </h5>
                                                <div className="same-details">
                                                   <span> 30 Yrs, Female </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                   <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                             <div className="left-history mt-5">
                                                <h6 className="nw-add"> Beneficiary </h6>
                                                <h5> Jaswant Thakur </h5>
                                                <div className="same-details">
                                                   <span> 45 Yrs, Male </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                          </div>
                                          <div className="col-lg-2">
                                             <div className="right-sec d-grid justify-content-lg-end">
                                                <h5> Rs. 1500/- </h5>
                                                <h6 className="ps-st">
                                                   <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                                </h6>
                                                <figure className="mb-0">
                                                   <img src={startrating} alt="ck"/>
                                                </figure>
                                                <p className="text-center"> Satisfied </p>
                                             </div>
                                             
                                          </div>
                                       </div>



                                    </div>
                           
                                 </div>

                                 <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                                       <div className="row row-cols-7 justify-content-center">
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={fileicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Accepted 
                                                   <span className="d-block">
                                                   (09:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={roketicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Started
                                                   <span className="d-block">
                                                   (10:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm ">
                                                   <img src={flagicon} alt="file"/>
                                             </figure>
                                             <h5>
                                              Reached
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={specialicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Collected
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={subicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Submitted
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       </div>
                                 </div>
                              </div>

                              
                        
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        
                              <div className="inside-history-div col-lg-11 ms-auto mt-3">
                                 <h6 className="mn-head"> 10 Results Found </h6> 
                                 <div className="result-div mt-3">
                                    <div className="cm-history row">
                                       <div className="col-lg-11">
                                          <div className="left-history">
                                             <h5> Veena Rao
                                             </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span > <FaCircle/> 25-02-2022 </span>
                                                <span > <FaCircle/>  #ORD6848r4 </span>
                                                <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          <div className="left-history mt-5">
                                             <h6 className="nw-add"> Beneficiary </h6>
                                             <h5> Jaswant Thakur </h5>
                                             <div className="same-details">
                                                <span> 45 Yrs, Male </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                       </div>
                                       <div className="col-lg-1">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1500/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 500/- </h5>
                                             <h6 className="unps-st">
                                                Unpaid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 2000/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1200/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>


                                 </div>
                                 
                              </div>

                        </div>
                        
                     </div>
                  </div>






                  



               </div>
               
            </div>
         </div>
        </div>
       
        <MobileMenu/>


       <LabDashboradFilter/>

        <div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <CalenderMultiple/>
                  </div>
                  
               </div>
            </div>
        </div>
        </>
     );
   }
 }

export default StudentWatchlist;