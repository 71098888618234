import React from "react";
import fileicon from "../images/file.svg";
import roketicon from "../images/rocket-icon.svg";
import flagicon from "../images/flag-icon.svg";
import chckicon from "../images/check-icon.svg";
import subicon from "../images/file-ck.svg";
import { GoPrimitiveDot } from "react-icons/go";
function AdminLabLoginTable(){
    return(
        <>
        <table className="table mt-4 table-hover inside-sp table-responsive">
            <thead>
            <tr className="bg-row">
                <th scope="col">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    
                </div>
                </th>
                <th scope="col">User Name</th>
                <th scope="col">Login ID</th>
                <th scope="col">Password</th>
                <th scope="col">Phone number</th>
                <th scope="col">Login Date</th>
                <th scope="col">Login Time</th>
                <th scope="col">Log out time</th>
            </tr>
            </thead>
        <tbody>
        <tr>
            <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                
            </div>
            </td>
           
            <td>
            Sumit
            </td>
            <td>
            Sumit58656
            </td>
            <td>
            ANJJ5+8553%
            </td>
            <td>
            0000000000
            </td>
            <td>
            22-02-2022
            </td>
            <td>
            06:50 AM
            </td>
            <td>
            07:48 AM
            </td>
            

            
        </tr>

        <tr>
            <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                
            </div>
            </td>
           
            <td>
            Jhon
            </td>
            <td>
            Jhon58656
            </td>
            <td>
            ANJJ5+8553%
            </td>
            <td>
            0000000000
            </td>
            <td>
            22-02-2022
            </td>
            <td>
            06:50 AM
            </td>
            <td>
            07:48 AM
            </td>
            

            
        </tr>


        <tr>
            <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                
            </div>
            </td>
           
            <td>
            Sumit
            </td>
            <td>
            Sumit58656
            </td>
            <td>
            ANJJ5+8553%
            </td>
            <td>
            0000000000
            </td>
            <td>
            22-02-2022
            </td>
            <td>
            06:50 AM
            </td>
            <td>
            07:48 AM
            </td>
            

            
        </tr>


        <tr>
            <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                
            </div>
            </td>
           
            <td>
            Sam
            </td>
            <td>
            Sam58656
            </td>
            <td>
            ANJJ5+8553%
            </td>
            <td>
            0000000000
            </td>
            <td>
            22-02-2022
            </td>
            <td>
            06:50 AM
            </td>
            <td>
            07:48 AM
            </td>
            

            
        </tr>

        <tr>
            <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                
            </div>
            </td>
           
            <td>
            Robert
            </td>
            <td>
            Robert58656
            </td>
            <td>
            ANJJ5+8553%
            </td>
            <td>
            0000000000
            </td>
            <td>
            22-02-2022
            </td>
            <td>
            06:50 AM
            </td>
            <td>
            07:48 AM
            </td>
            

            
        </tr>

        <tr>
            <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                
            </div>
            </td>
           
            <td>
            James Smith
            </td>
            <td>
            James58656
            </td>
            <td>
            ANJJ5+8553%
            </td>
            <td>
            0000000000
            </td>
            <td>
            22-02-2022
            </td>
            <td>
            06:50 AM
            </td>
            <td>
            07:48 AM
            </td>
            

            
        </tr>

        <tr>
            <td>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1"/>
                
            </div>
            </td>
           
            <td>
            Ankush
            </td>
            <td>
            Ankush58656
            </td>
            <td>
            ANJJ5+8553%
            </td>
            <td>
            0000000000
            </td>
            <td>
            22-02-2022
            </td>
            <td>
            06:50 AM
            </td>
            <td>
            07:48 AM
            </td>
            

            
        </tr>

        </tbody>
      </table>
        </>
    );
}
export default AdminLabLoginTable;