const allbrunchdetails =[
    {
      id:1,
      title:"ABC Branch",
      labid:"#lab234",
      address:"#455, Abc colony, mohali",
      adminname:"Admkn455",
      phone:"4723445455",
      email:"test@gmail.com",
      username:"usera455",
      password:"bsj^&*rrd344",
    
    },

    {
      id:2,
      title:"ABC Branch",
      labid:"#lab234",
      address:"#455, Abc colony, mohali",
      adminname:"Admkn455",
      phone:"4723445455",
      email:"test@gmail.com",
      username:"usera455",
      password:"bsj^&*rrd344",
    },

    {
        id:3,
        title:"ABC Branch",
        labid:"#lab234",
        address:"#455, Abc colony, mohali",
        adminname:"Admkn455",
        phone:"4723445455",
        email:"test@gmail.com",
        username:"usera455",
        password:"bsj^&*rrd344",
      },


      {
        id:4,
        title:"ABC Branch",
        labid:"#lab234",
        address:"#455, Abc colony, mohali",
        adminname:"Admkn455",
        phone:"4723445455",
        email:"test@gmail.com",
        username:"usera455",
        password:"bsj^&*rrd344",
      },
   

]

export default allbrunchdetails;