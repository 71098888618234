const alluserdetails =[
    {
      id:1,
      name:"Veena@gmail.com",
      number:"723323556656",
      title:" Veena Rao ",
      adinnumber:"Ven456",
      Password:"bsj^&*rrd344",
    
    },

    {
      id:2,
      name:"Veena@gmail.com",
      number:"723323556656",
      title:" Veena Rao ",
      adinnumber:"Ven456",
      Password:"bsj^&*rrd344",
    },
   

]

export default alluserdetails;