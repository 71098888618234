import React from "react";
import Card from "react-credit-cards";
import html2canvas from "html2canvas";
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// Import the MathType plugin
//import MathType from '@wiris/mathtype-ckeditor5';
//import ClassicEditor from './ckeditor';

class CreateExam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFrontOfCardVisible: true
    };
  }
  

  render() {
    const { data } = this.props;
    const { isFrontOfCardVisible } = this.state;

    return (
      <div className="mt-3">
        <h4>Submitted:</h4>
        <div>{JSON.stringify(data)}</div>
        <div className="mt-3 cursor-pointer" onClick={this.toggleCardFlip}>
          <div id="card-details">
            
          </div>
        </div>

      </div>
    );
  }
}

export default CreateExam;