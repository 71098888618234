import React from "react";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

function DatePickerSection(){
    return(
        <>
        <DatePickerComponent placeholder='DD-MM-YY'></DatePickerComponent>
        </>
    );
}
export default DatePickerSection;