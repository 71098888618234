import React from "react";
import AdminSidebar from "./AdminSidebar";
import TopMenu from"./components/TopMenu";
import SupportIcon from "../src/images/sp-icon.png";
import { FaBars } from "react-icons/fa";
import $ from "jquery";
import AdminMobileMenu from "./components/AdminMobileMenu";
import CreateExam from "./CreateExam";



class AdminStudentExam extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      name: "",
      cardNumber: "",
      expiry: "",
      cvc: "",
      submittedData: {}
    };
  }
    componentWillMount(){

        $(document).ready(function(){
          $(".togle-icon").click(function(e) {
              e.preventDefault();
              $("#navAccordion").toggleClass("active");
              $("main").toggleClass("full-width");
              $("footer").toggleClass("full-width");
        
        });
        });
        
      }
	  

	  
	  handleSubmit = (e) => {
    e.preventDefault();
    const { name, cardNumber, expiry, cvc } = this.state;
	console.log(name + cardNumber + expiry + cvc);
    this.setState({ submittedData: { name, cardNumber, expiry, cvc } });
  }

  handleChange = (event, field) => {
    this.setState({ [field]: event.target.value });
  }
  
    render() {
      return(
          <>
           <div className='page-wrapper chiller-theme toggled'>

                <div className="main-sec d-md-flex">
                    <div className="sidebar-menu" id="navAccordion">
                    <AdminSidebar/>
                    </div>
                </div>
                <main className="body-total content-wrapper float-start w-100">
                    <div className="container-fluid">
                    <div className="d-none d-lg-block">
                        <div className="top-bar-menu shadow float-start">
                            <a className="tog-btn togle-icon">
                            <FaBars/>
                            </a>
                            <TopMenu/>
                        </div>
                    </div>
                    <div className="dasb-div bk-div  pe-lg-3 float-start w-100">
                            <div className="bk-inside-div">
                                <div className="col-lg-12 ps-lg-0">
                                    <div className="show-date-details d-lg-flex shadow mb-5 mb-lg-0">
                                        
                                        <h6 className="my-4 my-md-0 cm-heading-page"> <span> <img src={SupportIcon} alt="cl"/> </span> Student Exam Form</h6>
                                    
                                    </div>
                                </div>

                                
                            </div> 

                            <div className="support-sec-div mt-4">
                                <div className="row row-cols-1 row-cols-lg-2">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Card Holder Name</label>
                                            <input type="text" className="form-control" value={this.state.name} placeholder="Cardholder Name"
            onChange={(e) => this.handleChange(e, 'name')}/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Card Number</label>
                                            <input type="text" className="form-control" placeholder="Card Number"
            value={this.state.cardNumber}
            onChange={(e) => this.handleChange(e, 'cardNumber')}/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>MM/YY</label>
                                            <input type="text" className="form-control" placeholder="MM/YY"
              value={this.state.expiry}
              onChange={(e) => this.handleChange(e, 'expiry')}/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label>CVV</label>
                                            <input type="email" className="form-control" placeholder="CVC"
              value={this.state.cvc}
              onChange={(e) => this.handleChange(e, 'cvc')}/>
                                        </div>
                                    </div>

                                </div>
								
								<CreateExam />

								<button
          type="submit"
          className="btn d-table mx-auto mb-4 submit-btn"
          onClick={this.handleSubmit}
        >
          Submit
        </button>
                                <p className="text-center"> Talk to our support representative at <b> 123456789 </b> if any error found in student card.  </p>
                            </div>
							
							
							
							<hr />
      
	  
	  

                            
                        
                    
                    </div>
                    </div>
                </main>
            </div> 
            <AdminMobileMenu/>
          </>
      );
    }
  }


export default AdminStudentExam;