import React, { Component } from 'react';
import Chart from 'react-google-charts';

// Data for the line chart
const LineData = [
  ['Day', 'This Week', 'Last Week'],
  ['Sun', 25, 14],
  ['Mon', 25, 35],
  ['Tue', 23, 15],
  ['Wed', 17, 9],
  ['Thu', 18, 10],
  ['Fri', 20, 35],
  ['Sat', 11, 25],
];

// Chart options for appearance and behavior
const LineChartOptions = {
  hAxis: {
    title: '',
  },
  vAxis: {
    title: 'Overall Course Rating',
  },
  series: {
    1: { curveType: 'function' },
  },
  chartArea: { width: '90%', height: '60%' },
  legend: { position: 'bottom' },
};
 
class ReviewLineChart extends Component {
  render() {
    return (
      <div className="chart-graph-line cr ">
        <Chart
          width={'100%'}
          height={'180px'}
          chartType="LineChart"
          loader={<div>Loading Chart...</div>}
          data={LineData}
          options={LineChartOptions}
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
    );
  }
}

export default ReviewLineChart;
