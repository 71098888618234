import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "./components/BookingCtWeek";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';
import user3 from '../src/images/user3.png';
import filters from '../src/images/filters-icon.svg';
import search from '../src/images/search-icon.svg';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';
import passport from '../src/images/passport-image.jpg';
import CoursesTable from "./components/CoursesTable"; 
import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import visa from "../src/images/Visa.svg";
import mastercasd from "../src/images/master-casd.svg";
import paypal from "../src/images/paypal.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";
import cpu from "../src/images/cpu.svg";
import handshake from "../src/images/Handshake.svg";
import crditCard from "../src/images/CreditCard.svg";
import chartBar from "../src/images/ChartBarHorizontal.svg";
import bugdroid from "../src/images/bugdroid.svg";
import receipt from "../src/images/Receipt.svg";
import megaphone from "../src/images/MegaphoneSimple.svg";
import camerac from "../src/images/Camerac.svg";
import packagec from "../src/images/Package.svg";
import PenNib from "../src/images/PenNib.svg";
import firstaid from "../src/images/FirstAidKit.svg";
import headphones from "../src/images/Headphones.svg";
import arrowright from "../src/images/button-arrowright.svg";
import courseimages from "../src/images/course-images.jpg";
import featureimages from "../src/images/feature-course.jpg";
import usercourse from "../src/images/User-course.svg";
import barchart from "../src/images/bar-chart.svg";
import clock from "../src/images/Clock.svg";
import star from "../src/images/star.svg";
import keven from "../src/images/keven.png";
import instructor from "../src/images/instructor-img.jpg";
import pRight from "../src/images/p-ArrowRight.svg";
import pLeft from "../src/images/p-ArrowLeft.svg";
import netflix from "../src/images/netflix.svg";
import youtube from "../src/images/youtube.svg";
import google from "../src/images/google.svg";
import lenovo from "../src/images/lenovo.svg";
import slack from "../src/images/slack.svg";
import lexmark from "../src/images/lexmark.svg";
import microsoft from "../src/images/microsoft.svg";
import verizon from "../src/images/verizon.svg";
import beinstructor from "../src/images/become-instructor.png";
import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery";

class  GiftCourse extends React.Component {
componentWillMount(){
$(document).ready(function(){
$(".togle-icon").click(function(e) {
e.preventDefault();
$("#navAccordion").toggleClass("active");
$("main").toggleClass("full-width");
$("footer").toggleClass("full-width");
});
});
}
render() {
return(
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
            <div className="bredadcrumb-section">
               <div className="container">
                  <div className="breadcrumb-menu">
                  <h3>Gift Course</h3>
                     <nav aria-label="breadcrumb">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">Home</li>
                              <li className="breadcrumb-item">Course</li>
                              <li className="breadcrumb-item">Complete Web...Esign</li>    
                              <li className="breadcrumb-item active" aria-current="page">Gift Course</li>
                           </ol>
                     </nav>
                  </div>
               </div>
            </div> 
            <div className="course-gift-section section-padding">
               <div className="container">
                  <div className="row">
                  <div className="col-lg-7">
                     <div className="course-gift-wrap">
                        <div className="section-title">
                        <h2>Gift Course</h2>
                        </div>
                        <div className="contact-wrap">
                        <div className="contact-title">
                           <h6>Recipient's Information</h6>
                        </div>
                        <form action="#">
                           <div className="form-field mb-3">
                              <label>Recipient's Name</label>
                              <div className="field-wrapper">
                              <input type="text" placeholder="Full name" fdprocessedid="e0jiu"/>
                              </div>
                           </div>
                           <div className="form-field mb-3">
                              <label>Recipient's Email:</label>
                              <div className="field-wrapper">
                              <input type="email" placeholder="Email Address" fdprocessedid="pu2skg"/>
                              </div>
                           </div>
                           <div className="default-input-field">
                              <label>Message</label>
                              <textarea name="textarea" placeholder="Message Subject"></textarea>
                           </div>
                        </form>
                        </div>
                        <div className="contact-wrap mb-0">
                        <div className="contact-title">
                           <h6>Payment Method</h6>
                        </div>
                        <div className="plans">
                           <label className="plan complete-plan">
                              <input type="radio" id="one" name="plan"/>
                              <small className="plan-content">               
                              <img src={visa} alt="visa"/>
                              <span>4855 **** **** ****</span>
                              <span>04/24</span>
                              <span>Vako Shvili</span>
                              </small>
                           </label>
                        </div>
                        <div className="plans">
                           <label className="plan complete-plan">
                              <input type="radio" id="two" name="plan"/>
                              <small className="plan-content">
                              <img src={mastercasd} alt="mastercasd"/>
                              <span>4855 **** **** ****</span>
                              <span>04/24</span>
                              <span>Vako Shvili</span>
                              </small>
                           </label>
                        </div>
                        <div className="plans">
                           <label className="plan complete-plan">
                              <input type="radio" id="three" name="plan"/>
                              <small className="plan-content paypal-column">
                              <img src={paypal} alt="paypal"/>
                              <strong>
                                                   You will be redirected to the PayPal site after reviewing your order.
                                                </strong>
                              </small>
                           </label>
                        </div>
                        <div className="plans">
                           <label className="plan complete-plan">
                              <input type="radio" id="four" name="plan"/>
                              <small className="plan-content">
                                 <img src={crditCard} alt="crditCard"/>
                              
                              <span>New Payment Cards</span>
                              </small>
                           </label>
                        </div>
                        </div>
                        <div className="contact-wrap">
                        <form>
                           <div className="form-field mb-3">
                              <label>Name</label>
                              <div className="field-wrapper">
                              <input type="text" placeholder="Name on card" fdprocessedid="rvd04"/>
                              </div>
                           </div>
                           <div className="form-field form-field-social mb-3 card-check-field">
                              <label>Card Number</label>
                              <div className="field-wrapper">
                              <span className="icon">
                                 <img src={crditCard} alt="crditCard"/>
                                             </span>
                              <input type="text" placeholder="Label" fdprocessedid="dqomc8"/>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-6">
                              <div className="form-field mb-3">
                                 <label>MM / YY</label>
                                 <div className="field-wrapper">
                                    <input type="text" placeholder="MM / YY" fdprocessedid="uadkbi"/>
                                 </div>
                              </div>
                              </div>
                              <div className="col-lg-6">
                              <div className="form-field mb-3">
                                 <label>CVC</label>
                                 <div className="field-wrapper">
                                    <input type="text" placeholder="Security Code" fdprocessedid="8mgy4h"/>
                                 </div>
                              </div>
                              </div>
                           </div>
                           <div className="form-check remember-card">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
                              <label className="form-check-label" for="flexCheckChecked">
                              Remember this card, save it on my card list
                              </label>
                              </div>
                           
                        </form>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-5">
                     <div className="amount-sidebar">
                        <div className="course-details-wrap">
                        <div className="course-details-item">
                           <a href="cours-list.html" className="course-details-thumb">
                              <img src={courseimages} alt="courseimages"/>
                           </a>
                           <div className="course-details-data">
                              <p>Course by:<span>Courtney Henry</span></p>
                              <a className="course-detalis-title" href="cours-list.html">Graphic Design Masterclass - Learn GREAT Design</a>
                              <h5>$13.00</h5>
                           </div>
                        </div>
                        </div>
                        <div className="about-sidebar-wrap">
                        <div className="amunt-detalis">
                           <h4>Order Summery</h4>
                           <ul>
                              <li>Subtotal<span>$61.97 USD</span></li>
                              <li>Coupon Discount<span>8%</span></li>
                           </ul>
                           <h3>Total:<span></span>$75.00 USD</h3>
                        </div>
                        <div className="amaunt-confirm-button">
                           <a className="button payment-btn" href="#">
                              Coplete Payment
                           </a>
                        </div>
                        </div>
                     </div>
                  </div>
                  </div>
               </div>
            </div>
            
              



         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
      </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}
export default GiftCourse;