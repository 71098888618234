import React, { Component } from 'react';
import "../Card.css"; // Ensure this path is correct

// Import images
import user1 from '../images/user1.png';
import user2 from '../images/user2.png';
import user3 from '../images/user3.png';

class CourseUpdates extends Component {
    items = [
        { header: 'Rocket Modeling', body: '2 Weeks left','status':'success', footer: '...', photos: [user1, user2, user3] },
        { header: 'Satelight Design', body: '2 Weeks left','status':'danger', footer: '...', photos: [user1, user2, user3] },
        { header: 'Auto Cad', body: '2 Weeks left','status':'warning', footer: '...', photos: [user1, user2, user3] },
		
    ];

    render() {
        return (
		<div className="coursesbody">
            <ul className="courselist">
                {this.items.map((item, index) => (
                    <li key={index} className="list-item">
                        <div className="content">
                            <div className="header">{item.header}</div>
                            <div className="body">
                                <div className="mainbody"><div className={item.status}>{item.body}</div></div>
                                <div className="photo-circles">
                                    {item.photos.map((photo, idx) => (
                                        <div key={idx} className="circle" style={{ backgroundImage: `url(${photo})` }}></div>
                                    ))}
                                </div>
                            </div>
                            
                        </div>
                        <div className="ellipsis">&#8230;</div>
                    </li>
                ))}
            </ul>
			</div>
        );
    }
}

export default CourseUpdates;
