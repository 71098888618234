import React from "react";
import fileicon from "../images/file.svg";
import roketicon from "../images/rocket-icon.svg";
import flagicon from "../images/flag-icon.svg";
import chckicon from "../images/check-icon.svg";
import subicon from "../images/file-ck.svg";
import arrowup from "../images/arrow-up-icon.svg";
import pre from "../images/pre-icon.svg";
import nxt from "../images/nxt-icon.svg";
import $ from "jquery";


class TransistionTable extends React.Component {
    // Pagination state
  state = {
    currentPage: 1,
    totalPages: 4  // Assuming there are 5 pages total
  };

  // Function to change page
  setPage = (page) => {
    if (page < 1 || page > this.state.totalPages) return;
    this.setState({ currentPage: page });
    // You would also need to call a function here to load the data for the selected page
  };
  componentWillMount(){

    $(document).ready(function(){
        
      $(function(){
         
        $('.showSingle').click(function(){
              $('.targetDiv').slideUp();
              $('#div'+$(this).attr('target')).slideDown();
        });
      });
    });
    
  }
  render() {
    const { currentPage, totalPages } = this.state;
    return (

      <>
      <table className="table transistion-table table-hover inside-sp table-responsive">
        <thead>
        <tr className="bg-row">
            <th scope="col"> Description         </th>
            <th scope="col">Transaction ID</th>
            <th scope="col">Type</th>
            <th scope="col">Card</th>
            <th scope="col"> Date</th>
            <th scope="col">Amount</th>
            <th scope="col">Receipt</th>
		
        </tr>
        </thead>
      <tbody>
      <tr>
          <td> <div className="description"> <a className="up-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
      <tr>
          <td> <div className="description"> <a className="up-link check-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
      <tr>
          <td> <div className="description"> <a className="up-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
      <tr>
          <td> <div className="description"> <a className="up-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
      <tr>
          <td> <div className="description"> <a className="up-link check-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
      <tr>
          <td> <div className="description"> <a className="up-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
      <tr>
          <td> <div className="description"> <a className="up-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
      <tr>
          <td> <div className="description"> <a className="up-link check-link" target="1"><img src={arrowup} alt="arrow-up-icon"/> </a> Rocket Modeling</div></td>
          <td><a className="showSingle" target="1"> #12548796 </a> </td>
          <td > Purchase </td>
          <td className="cm-width"> 1234 ****</td>
          <td >28 Jan, 12.30 AM </td>
          <td>-$2,500 </td>
		      <td> <a className="Download-link" target="1">Download</a></td>          
      </tr>
    
      
      </tbody>
      <tfoot>
            <tr>
              <td colSpan="7">
                <div className="pagination">
                  <button onClick={() => this.setPage(currentPage - 1)} disabled={currentPage === 1}> <img src={pre} alt="pre-icon"/> Previous</button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button key={i + 1} onClick={() => this.setPage(i + 1)} className={currentPage === i + 1 ? "active" : ""}>
                      {i + 1}
                    </button>
                  ))}
                  <button onClick={() => this.setPage(currentPage + 1)} disabled={currentPage === totalPages}>Next <img src={nxt} alt="nxt-icon"/> </button>
                </div>
              </td>
            </tr>
          </tfoot>
      </table>
      </>
    );
  }
}
export default TransistionTable;