import React from "react";
import fileicon from "../images/file.svg";
import roketicon from "../images/rocket-icon.svg";
import flagicon from "../images/flag-icon.svg";
import chckicon from "../images/check-icon.svg";
import subicon from "../images/file-ck.svg";
import $ from "jquery";

class AdminPcakBulkTable extends React.Component {
    componentWillMount(){

        
      }
    render() {
      return (
          <>
          <table className="table new-buil-table3 mt-4 table-hover inside-sp table-responsive">
                <thead>
                <tr className="bg-row">
                    <th scope="col">


                    </th>
                    <th scope="col">Lab ID</th>
                    <th scope="col">Lab
                    Name</th>
                    <th scope="col">Package <br/>
                    Code</th>
                    <th scope="col">Package <br/>
                    Name </th>
                    <th scope="col">Parameters</th>
                    <th scope="col">Sample <br/>
                    Qty </th>
                    <th scope="col">Report <br/>
                    TAT </th>
                    
                    <th scope="col"> Commission <br/>
                    %</th>
                    
                    
                   
                   
                   <th scope="col"> Price</th>
                   

                    
                    
                </tr>
                </thead>
                <tbody>
                <tr>
                        <td>
                        
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                
                            </div>
                        </td>
                    
                        
                        <td>
                        #55662323
                        </td>
                        <td>
                        ABC
                        </td>
                        <td>
                        Pac5656
                        </td>
                        <td>
                        Full Checkup
                        </td>
                        <td>
                        40
                        </td>
                        <td>
                        10
                        </td>
                        <td>
                       10
                        </td>
                        <td>
                        12%
                        </td>
                        <td>
                        Rs. 1800/-
                        </td>
                        
                        
                    

            
                 </tr>
        


                 <tr>
                    <td>
                    
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                            
                        </div>
                    </td>
                
                    <td>
                        #55662323
                        </td>
                        <td>
                        ABC
                        </td>
                        <td>
                        Pac5656
                        </td>
                        <td>
                        Full Checkup
                        </td>
                        <td>
                        40
                        </td>
                        <td>
                        10
                        </td>
                        <td>
                       10
                        </td>
                        <td>
                        12%
                        </td>
                        <td>
                        Rs. 1800/-
                        </td>
                        
                    
                    
                
    
                
                </tr>


                <tr>
                    <td>
                    
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                            
                        </div>
                    </td>
                
                    <td>
                        #55662323
                        </td>
                        <td>
                        ABC
                        </td>
                        <td>
                        Pac5656
                        </td>
                        <td>
                        Full Checkup
                        </td>
                        <td>
                        40
                        </td>
                        <td>
                        10
                        </td>
                        <td>
                       10
                        </td>
                        <td>
                        12%
                        </td>
                        <td>
                        Rs. 1800/-
                        </td>
                        
                    
                
    
                
                </tr>
        
                   
            
                <tr>
                    <td>
                    
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                            
                        </div>
                    </td>
                
                    <td>
                        #55662323
                        </td>
                        <td>
                        ABC
                        </td>
                        <td>
                        Pac5656
                        </td>
                        <td>
                        Full Checkup
                        </td>
                        <td>
                        40
                        </td>
                        <td>
                        10
                        </td>
                        <td>
                       10
                        </td>
                        <td>
                        12%
                        </td>
                        <td>
                        Rs. 1800/-
                        </td>
                        
                    
                
    
                
                </tr>


                    
        
            <tr>
            <td>
               
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    
                </div>
            </td>
           
            <td>
                        #55662323
                        </td>
                        <td>
                        ABC
                        </td>
                        <td>
                        Pac5656
                        </td>
                        <td>
                        Full Checkup
                        </td>
                        <td>
                        40
                        </td>
                        <td>
                        10
                        </td>
                        <td>
                       10
                        </td>
                        <td>
                        12%
                        </td>
                        <td>
                        Rs. 1800/-
                        </td>
                        

            
            </tr>


            <tr>
            <td>
               
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    
                </div>
            </td>
           
            <td>
                        #55662323
                        </td>
                        <td>
                        ABC
                        </td>
                        <td>
                        Pac5656
                        </td>
                        <td>
                        Full Checkup
                        </td>
                        <td>
                        40
                        </td>
                        <td>
                        10
                        </td>
                        <td>
                       10
                        </td>
                        <td>
                        12%
                        </td>
                        <td>
                        Rs. 1800/-
                        </td>
                        
            
            

            
            </tr>


                   
        
                   
        

                
        
                    
                
                </tbody>
          </table>
          </>
      );
    }
  }

export default AdminPcakBulkTable;