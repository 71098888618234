import React from "react";
import fileicon from "../images/file.svg";
import roketicon from "../images/rocket-icon.svg";
import flagicon from "../images/flag-icon.svg";
import chckicon from "../images/check-icon.svg";
import subicon from "../images/file-ck.svg";
import arrowup from "../images/arrow-up-icon.svg";
import pre from "../images/pre-icon.svg";
import nxt from "../images/nxt-icon.svg";
import userimg from "../images/usericon.png";
import $ from "jquery";


class TransistionTable extends React.Component {
    // Pagination state
  state = {
    currentPage: 1,
    totalPages: 4  // Assuming there are 5 pages total
  };

  // Function to change page
  setPage = (page) => {
    if (page < 1 || page > this.state.totalPages) return;
    this.setState({ currentPage: page });
    // You would also need to call a function here to load the data for the selected page
  };
  componentWillMount(){

    $(document).ready(function(){
        
      $(function(){
         
        $('.showSingle').click(function(){
              $('.targetDiv').slideUp();
              $('#div'+$(this).attr('target')).slideDown();
        });
      });
    });
    
  }
  render() {
    const { currentPage, totalPages } = this.state;
    return (

      <>
      <table className="table  table-hover inside-sp table-responsive">
        <thead>
        <tr className="bg-row">
            <th scope="col"> Space Pass No. </th>
            <th scope="col">Event Name </th>
            <th scope="col">Team Members </th>
            <th scope="col">Registration Date</th>
            <th scope="col">Event Date</th>
            <th scope="col"> Position</th>
            <th scope="col">Status</th>
            <th scope="col">Receipt</th>
		
        </tr>
        </thead>
      <tbody>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
      <tr>
          <td><a className="space-link" target="1">145634 </a> </td>
          <td><a className="showSingle" target="1"> World Space Cup </a> </td>
          <td ><div className="team-member"> <figure className="m-0 ms-2"> <img src={userimg} alt="user"/></figure> Jane Cooper </div> </td>
          <td className="cm-width"> 7/2/2020</td>
          <td >15/2/2020</td>
          <td>First</td>
          <td>Done</td>
		      <td><div className="d-flex justify-content-center">  <button data-bs-toggle="modal" data-bs-target="#settingsModal" className="view1-btn ms-2">...</button>
            </div>
           </td>          
      </tr>
     


      
      </tbody>
      <tfoot>
            <tr>
              <td colSpan="8">
                <div className="pagination">
                  <button onClick={() => this.setPage(currentPage - 1)} disabled={currentPage === 1}> <img src={pre} alt="pre-icon"/> Previous</button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button key={i + 1} onClick={() => this.setPage(i + 1)} className={currentPage === i + 1 ? "active" : ""}>
                      {i + 1}
                    </button>
                  ))}
                  <button onClick={() => this.setPage(currentPage + 1)} disabled={currentPage === totalPages}>Next <img src={nxt} alt="nxt-icon"/> </button>
                </div>
              </td>
            </tr>
          </tfoot>
      </table>
      </>
    );
  }
}
export default TransistionTable;