import React, { Component } from 'react';
import Chart from 'react-google-charts';

// Data for the line chart
const LineData = [
  ['Day', 'This Week', 'Last Week'],
  ['Sun', 0, 0],
  ['Mon', 10, 5],
  ['Tue', 23, 15],
  ['Wed', 17, 9],
  ['Thu', 18, 10],
  ['Fri', 9, 5],
  ['Sat', 11, 3],
];

// Chart options for appearance and behavior
const LineChartOptions = {
  hAxis: {
    title: 'Day of the Week',
  },
  vAxis: {
    title: 'Course Popularity',
  },
  series: {
    1: { curveType: 'function' },
  },
  chartArea: { width: '90%', height: '60%' },
  legend: { position: 'bottom' },
};
 
class MultiLineChart extends Component {
  render() {
    return (
      <div className="chart-graph-line ">
        <Chart
          width={'100%'}
          height={'300px'}
          chartType="LineChart"
          loader={<div>Loading Chart...</div>}
          data={LineData}
          options={LineChartOptions}
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
    );
  }
}

export default MultiLineChart;
