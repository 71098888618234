import React from "react";
import { NavLink } from "react-router-dom";
import logologin from "../src/images/lms_logo_new.png";
import leftarrow from "../src/images/left-arrow-back.svg";
import logobodyimg from "../src/images/mapmyvisa_home.jpg";
import rightarrow from "../src/images/arrow-right-submit.svg";

import line from "../src/images/line.svg";
function Forget(){
    return(
        <>  
        <div className="login-page float-start w-100">
            <div className="topLeftLoginBtn">
            <button className="btn btn-default topleftloginbtn">Login Page</button>
            </div>
        <div className="container-fluid">
                <div className="row"> 
                <NavLink to="/login" className="back-link"><img src={leftarrow} alt="leftarrow"/>Back </NavLink>
               
              </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="fog-get-fomm login-right">
                    
                            <NavLink to="/" className="text-center d-table mx-auto"> 
                            <figure className="text-center ">
                                <img src={logologin} alt="logoadmin"/>
                            </figure>
                            </NavLink>
                        <h2 className="mt-2 text-center"> Forgot password? </h2>
                        <p className="mt-3 text-center"> Please enter your email address to reset your password </p>
                        <div className="form-sec-login">
                            <form action="" method="get">
                                <div className="form-group ">
                                    <div className="lable-input">
                                        <input type="text" className="form-control" placeholder=" Email Id or Phone Number"/>
                                    </div>
                                </div>
                                <div className="from-group">
                                    <NavLink to="/" className="signup-btn"> 
                                        <button type="submit"> Send OTP <span><img src={rightarrow} alt="rightarrow"/></span></button>  
                                    </NavLink>
                                </div>
                                <div className="from-group mt-4">
                                <p className="text-right lg-text">Log in  Insted ?</p>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                

                 
                <div className="col-lg-6">
                    <div className="login-left d-flex align-items-center justify-content-start">
                        <figure className="mb-0">
                            <img src={logobodyimg} alt='bn'/>
                        </figure>
                    </div>
                </div>
            </div>
            <div className="row login-footer"><div className="col-lg-12 pt-5  pb-5 text-center"><p>Powered by World Space Council (Dyanmic Footer)</p></div></div>
        </div>
        </div>
        </>
    );
}
export default Forget;