import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "./components/BookingCtWeek";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
import TranisitionInfo from "./components/TranisitionInfo";
import TranisitionSingle from "./components/TranisitionSingle";
import TransistionHistoryTable from "./components/TransistionHistoryTable";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import searchicon from "../src/images/search-button-icon.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import passport from '../src/images/passport-image.jpg';
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';
import user3 from '../src/images/user3.png';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';

import CoursesTable from "./components/CoursesTable"; 
import TransistionTable from "./components/TransistionTable"; 

import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";

import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery";

class ParticipationHistory extends React.Component {
componentWillMount(){
$(document).ready(function(){
$(".togle-icon").click(function(e) {
e.preventDefault();
$("#navAccordion").toggleClass("active");
$("main").toggleClass("full-width");
$("footer").toggleClass("full-width");
});
});
}
render() {
return(
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
            
            
         <div className="row g-lg-4 ">
               
               <div className="col-md-3 ps-lg-0">
                  <div className="comon-overview shadow mb-4 mb-lg-0">
                     <div className="d-flex w-100  justify-content-between align-items-center">
                        <div className="date-tx d-flex  align-items-center">
                           <figure className="tc-bg icon-f mb-0">
                              <img src={coloricon} alt="icon"/>
                           </figure>
                           <h5 className="ms-2">
                           Total Courses
                           </h5>
						         
                        </div>
                        <div className="progess_circle">
                        <CircularProgress bgcolor="#4CAF50" percentage={70} />
                        </div>
                     </div>
                     <div className="cinfo">
                     <div className="cinfo-count"><p>50 <span>All Over</span></p></div>
                     <div className="cinfo-value"><p>1.3% from past week</p></div>
                     </div>
                  </div>
               </div>
               <div className="col-md-3">
                  <div className="comon-overview md-overview shadow mb-4 mb-lg-0">
                     <div className="d-flex w-100 justify-content-between align-items-center">
                        <div className="date-tx d-flex align-items-center">
                           <figure className="ac-bg icon-f mb-0">
                              <img src={timeicon} alt="icon"/>
                           </figure>
                           <h5 className="ms-2">
                              Active Courses
                           </h5>
                        </div> <div className="progess_circle">
                        <CircularProgress bgcolor="#85d8db" percentage={70} />
                        </div>
                     </div>
                     <div className="cinfo">
                     <div className="cinfo-count"><p>50 <span>All Over</span></p></div>
                     <div className="cinfo-value"><p>1.3% from past week</p></div>
                     </div>
                  </div>
               </div>
               <div className="col-md-3">
                  <div className="comon-overview lst-overview shadow mb-4 mb-lg-0">
                     <div className="d-flex w-100 justify-content-between align-items-center">
                        <div className="date-tx d-flex align-items-center">
                           <figure className="as-bg icon-f mb-0">
                              <img src={useicon} alt="icon"/>
                           </figure>
                           <h5 className="ms-2">
                              Active Students
                           </h5>
                        </div>
                        <div className="progess_circle">
                        <CircularProgress bgcolor="#4c5aaf" percentage={75} />
                        </div>
                     </div>
                     <div className="cinfo">
                     <div className="cinfo-count"><p>50 <span>All Over</span></p></div>
                     <div className="cinfo-value"><p>1.3% from past week</p></div>
                     </div>
                     
                  </div>
               </div>
               <div className="col-md-3">
                  <div className="comon-overview lst-overview shadow mb-4 mb-lg-0">
                     <div className="d-flex w-100 justify-content-between align-items-center">
                        <div className="date-tx d-flex align-items-center">
                           <figure className="tca-bg icon-f mb-0">
                              <img src={useicon} alt="icon"/>
                           </figure>
                           <h5 className="ms-2">
                              Total Connect Partners
                           </h5>
                        </div>
                        <div className="progess_circle">
                        <CircularProgress bgcolor="#e5532a" percentage={70} />
                        </div>
                     </div>
                     <div className="cinfo">
                     <div className="cinfo-count"><p>50 <span>All Over</span></p></div>
                     <div className="cinfo-value"><p>1.3% from past week</p></div>
                     </div>
                  </div>
               </div>
               </div>
               
                
              
               <div className="row">


                  <div className="col-lg-9 main-content-left">
                  <div className="row course-table-block mt-4 mb-2">
                  <div className="col-lg-6">
                  <div className="toptable-heading">Space Pass : View or Download</div>
                  </div>
                  <div className="col-lg-6 text-right np-links">
                  <NavLink to="/" className="text-center ">                            
                     <img src={angleleft} alt="angleleft"/> Previous
                     </NavLink>  
                  <NavLink to="/" className="text-center ">   Next<img src={angleright} alt="angleright"/>
                  </NavLink>                
                  
                  </div>
                  </div>
                <div className="row course-table-block">
                     <div className="col-lg-12 ">  <div className="course-table-view">
                     <TransistionHistoryTable/>
                      </div>
                      </div>
                  </div>
                  
                  </div>
                  <div className="col-lg-3 right-course-list">
                  <div className="passport-block">
                  <img  src={passport} alt="passport"/></div>
                  <TranisitionSingle/>


                  <div className="astronautsicon">
                  <img src={astronauticon} alt="ck"/>
                  </div>
                  </div>
               </div>
              
			   
			  
			
			
         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
      </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}
export default ParticipationHistory;