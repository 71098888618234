import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "./components/BookingCtWeek";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';
import user3 from '../src/images/user3.png';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';
import passport from '../src/images/passport-image.jpg';
import CoursesTable from "./components/CoursesTable"; 
import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";
import cpu from "../src/images/cpu.svg";
import handshake from "../src/images/Handshake.svg";
import crditCard from "../src/images/CreditCard.svg";
import chartBar from "../src/images/ChartBarHorizontal.svg";
import bugdroid from "../src/images/bugdroid.svg";
import receipt from "../src/images/Receipt.svg";
import megaphone from "../src/images/MegaphoneSimple.svg";
import camerac from "../src/images/Camerac.svg";
import packagec from "../src/images/Package.svg";
import PenNib from "../src/images/PenNib.svg";
import firstaid from "../src/images/FirstAidKit.svg";
import headphones from "../src/images/Headphones.svg";
import arrowright from "../src/images/button-arrowright.svg";
import courseimages from "../src/images/course-images.jpg";
import featureimages from "../src/images/feature-course.jpg";
import usercourse from "../src/images/User-course.svg";
import barchart from "../src/images/bar-chart.svg";
import clock from "../src/images/Clock.svg";
import star from "../src/images/star.svg";
import keven from "../src/images/keven.png";
import instructor from "../src/images/instructor-img.jpg";
import netflix from "../src/images/netflix.svg";
import youtube from "../src/images/youtube.svg";
import google from "../src/images/google.svg";
import lenovo from "../src/images/lenovo.svg";
import slack from "../src/images/slack.svg";
import lexmark from "../src/images/lexmark.svg";
import microsoft from "../src/images/microsoft.svg";
import verizon from "../src/images/verizon.svg";
import beinstructor from "../src/images/become-instructor.png";
import HeartBlank from "../src/images/Heart-blank.svg";
import ShoppingCartSimple from "../src/images/ShoppingCartSimple.svg";

import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope,FaStar  } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery";

class CreateCourses extends React.Component {
componentWillMount(){
$(document).ready(function(){
$(".togle-icon").click(function(e) {
e.preventDefault();
$("#navAccordion").toggleClass("active");
$("main").toggleClass("full-width");
$("footer").toggleClass("full-width");
});
});
}
render() {
return( 
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
            
            
         <div className="row g-lg-4 ">
               <div className="col-lg-12 pb-4" >
                  <h2>Popular tools</h2>

               </div>
               </div>

               <div className="row g-lg-4 papulat-tool-list ">
               <div className="col-xs-6 col-sm-2 col-md-2 ">
                  <div className="box tool-box">
                  <h5>HTML 5</h5>
                  <p>2,736 Courses</p>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-2 col-md-2 ">
                  <div className="box tool-box">
                  <h5>CSS 3</h5>
                  <p>13,932 Courses</p>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-2 col-md-2  ">
                  <div className="box tool-box">
                  <h5>Javascript</h5>
                  <p>52,822 Courses</p>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-2 col-md-2 ">
                  <div className="box tool-box">
                  <h5>Saas</h5>
                  <p>20,126 Courses</p>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-2 col-md-2 ">
                  <div className="box tool-box">
                  <h5>Saas</h5>
                  <p>20,126 Courses</p>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-2 col-md-2 ">
                  <div className="box tool-box">
                  <h5>Saas</h5>
                  <p>20,126 Courses</p>
                  </div>
               </div>
               <div className="col-lg-12 popular-keyword">
               <span>Popular keyword:</span>
               <ul className="list-group list-group-horizontal">
                  <li className="list-group-item"><a>HTLM 5</a></li>
                  <li className="list-group-item"><a>Web Development</a></li>
                  <li className="list-group-item"><a>Responsive Developments</a></li>
                  <li className="list-group-item"><a> Developments</a></li>
                  <li className="list-group-item"><a>Programing</a></li>
                  <li className="list-group-item"><a>Website</a></li>
                  <li className="list-group-item"><a>Technology</a></li>
                  <li className="list-group-item"><a>Wordpress</a></li>
                  </ul>
        
               </div>
                  
               </div>

               <div className="row g-lg-4 topcategory mt-5 ">
               <div className="col-lg-12 category-heading">
                  <h2>Browse top category</h2>

               </div>

               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat1">
                   <div className="cat-image"><img src={cpu} alt="Cpu"/></div>
                     <div className="cat-content ">
                     <h5>Label</h5>
                     <p>63,476 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat2">
                   <div className="cat-image"><img src={handshake} alt="handshake"/></div>
                     <div className="cat-content ">
                     <h5>Business</h5>
                     <p>63,476 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat3">
                   <div className="cat-image"><img src={crditCard} alt="crditCard"/></div>
                     <div className="cat-content ">
                     <h5>Finance & Accounting</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat4">
                   <div className="cat-image"><img src={chartBar} alt="chartBar"/></div>
                     <div className="cat-content ">
                     <h5>IT & Software</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat5">
                   <div className="cat-image"><img src={bugdroid} alt="bugdroid"/></div>
                     <div className="cat-content ">
                     <h5>Personal Development</h5>
                     <p>33,841 Courses</p> 
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat6">
                   <div className="cat-image"><img src={receipt} alt="receipt"/></div>
                     <div className="cat-content ">
                     <h5>Office Productivity</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat7">
                   <div className="cat-image"><img src={megaphone} alt="megaphone"/></div>
                     <div className="cat-content ">
                     <h5>Marketing</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat8">
                   <div className="cat-image"><img src={camerac} alt="camerac"/></div>
                     <div className="cat-content ">
                     <h5>Photography & Video</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat9">
                   <div className="cat-image"><img src={packagec} alt="packagec"/></div>
                     <div className="cat-content ">
                     <h5>Lifestyle</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat10">
                   <div className="cat-image"><img src={PenNib} alt="PenNib"/></div>
                     <div className="cat-content ">
                     <h5>Design</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat11">
                   <div className="cat-image"><img src={firstaid} alt="firstaid"/></div>
                     <div className="cat-content ">
                     <h5>Health & Fitness</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-6 col-sm-4 col-md-3 ">
                  <div className="inline-box cat12">
                   <div className="cat-image"><img src={headphones} alt="headphones"/></div>
                     <div className="cat-content ">
                     <h5>Music</h5>
                     <p>33,841 Courses</p>
                     </div>
                  </div>
               </div>
               <div className="col-xs-12 col-sm-12 text-center more-view ">
               We have more category & subcategory. <a>Browse All <img src={arrowright} alt="headphones"/> </a>
                  </div>
               </div>

               <div className="row g-lg-4 topcategory mt-5 ">
               <div className="col-lg-12 category-heading">
                  <h2>Best selling courses</h2>

               </div>
               <div className="best-course-list">
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/>5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/>5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                 
             </div>
             </div>

             <div className="row g-lg-4 feature-courses mt-5 ">
               <div className="col-lg-8 ">
                  <h2>Our feature courses</h2>

               </div>
               <div className="col-lg-4 ">
                  <p>Vestibulum sed dolor sed diam mollis maximus vel nec dolor. Donec varius purus et eleifend porta.</p>

               </div> </div>
               <div className="row g-lg-4 feature-courses-list mt-5 ">
               <div className="col-lg-6 feature-item">
                  <div className="row gy-0">
                     <div className="col-lg-4 img-block p-0">
                     <div className="course-image"><img src={featureimages} alt="featureimages"/></div>
                     </div>
                     <div className="col-lg-8 content-block p-0">
                        <div className="course-content">
                           <div className="course_price_cat">
                              <span className="course_category">Health & Fitness</span>
                              <span className="course_price">
                                 <span className="sale-price">$14</span>
                                 <span className="reg-price">$16</span>
                              </span>
                           </div>
                           <div className="course_link"><a>Investing In Stocks The Complete Course! (13 H...</a></div>
                           <div className="course-user-rating">
                              <span className="course_user">
                                 <span className="user-image"><img src={keven} alt="keven"/></span>
                                 <span className="user_name">Kevin Gilbert</span>
                              </span>
                              <span className="corse-rating"><FaStar/>5.0 <span>(357,914)</span></span>
                           </div>
                           <div className="course-footer">
                              <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                              <a><img src={barchart} alt="barchart"/> Beginner</a>
                              <a><img src={clock} alt="clock"/> 6 hour</a>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
            <div className="col-lg-6 feature-item">
                  <div className="row gy-0">
                     <div className="col-lg-4 img-block p-0">
                     <div className="course-image"><img src={featureimages} alt="featureimages"/></div>
                     </div>
                     <div className="col-lg-8 content-block p-0">
                        <div className="course-content">
                           <div className="course_price_cat">
                              <span className="course_category">Health & Fitness</span>
                              <span className="course_price">
                                 <span className="sale-price">$14</span>
                                 <span className="reg-price">$16</span>
                              </span>
                           </div>
                           <div className="course_link"><a>Investing In Stocks The Complete Course! (13 H...</a></div>
                           <div className="course-user-rating">
                              <span className="course_user">
                                 <span className="user-image"><img src={keven} alt="keven"/></span>
                                 <span className="user_name">Kevin Gilbert</span>
                              </span>
                              <span className="corse-rating"><FaStar/>5.0 <span>(357,914)</span></span>
                           </div>
                           <div className="course-footer">
                              <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                              <a><img src={barchart} alt="barchart"/> Beginner</a>
                              <a><img src={clock} alt="clock"/> 6 hour</a>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
            <div className="col-lg-6 feature-item">
                  <div className="row gy-0">
                     <div className="col-lg-4 img-block p-0">
                     <div className="course-image"><img src={featureimages} alt="featureimages"/></div>
                     </div>
                     <div className="col-lg-8 content-block p-0">
                        <div className="course-content">
                           <div className="course_price_cat">
                              <span className="course_category">Health & Fitness</span>
                              <span className="course_price">
                                 <span className="sale-price">$14</span>
                                 <span className="reg-price">$16</span>
                              </span>
                           </div>
                           <div className="course_link"><a>Investing In Stocks The Complete Course! (13 H...</a></div>
                           <div className="course-user-rating">
                              <span className="course_user">
                                 <span className="user-image"><img src={keven} alt="keven"/></span>
                                 <span className="user_name">Kevin Gilbert</span>
                              </span>
                              <span className="corse-rating"><FaStar/>5.0 <span>(357,914)</span></span>
                           </div>
                           <div className="course-footer">
                              <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                              <a><img src={barchart} alt="barchart"/> Beginner</a>
                              <a><img src={clock} alt="clock"/> 6 hour</a>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
                <div className="col-lg-6 feature-item">
                  <div className="row gy-0">
                     <div className="col-lg-4 img-block p-0">
                     <div className="course-image"><img src={featureimages} alt="featureimages"/></div>
                     </div>
                     <div className="col-lg-8 content-block p-0">
                        <div className="course-content">
                           <div className="course_price_cat">
                              <span className="course_category">Health & Fitness</span>
                              <span className="course_price">
                                 <span className="sale-price">$14</span>
                                 <span className="reg-price">$16</span>
                              </span>
                           </div>
                           <div className="course_link"><a>Investing In Stocks The Complete Course! (13 H...</a></div>
                           <div className="course-user-rating">
                              <span className="course_user">
                                 <span className="user-image"><img src={keven} alt="keven"/></span>
                                 <span className="user_name">Kevin Gilbert</span>
                              </span>
                              <span className="corse-rating"><FaStar/>5.0 <span>(357,914)</span></span>
                           </div>
                           <div className="course-footer">
                              <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                              <a><img src={barchart} alt="barchart"/> Beginner</a>
                              <a><img src={clock} alt="clock"/> 6 hour</a>
                           </div>
                        </div>
                     </div>
                  </div>
            </div>

               </div>
         <div className="row recent-course">
         <div className="col-lg-12 category-heading">
                           <h2>Recently added courses</h2>

                        </div>
                  <div className="col-lg-3"> 
                     <div className="course-card-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                     <div className="course-hover-detalis">
                           <div className="feature-course-right-content">
                              <div className="feature-course-right-content-header">
                                 <div className="feature-course-info">
                                 <button className="course-item-5-card-info-tag tag-color2">Developments</button>
                                 </div>
                                 <a href="#" className="feature-course-head">2021 Complete Python Bootcamp From Zero to Hero in Python</a>
                                 <div className="feature-course-mid">
                                 <a href="#">
                                    <div className="feature-course-user">
                                       <div className="feature-course-user-thumb">
                                       <img src={keven} alt="keven"/>
                                       </div>
                                       <div className="feature-course-user-data">
                                       <p>Course by</p>
                                       <h5>Kevin Gilbert</h5>
                                       </div>
                                    </div>
                                 </a>
                                 <div className="ratingreview">
                                 <span className="icon"><FaStar/></span>
                                 <p> 5.0 <span>(50000.0)</span> </p>                                   
                                 </div>
                                 </div>
                              </div>
                              <div className="feature-course-footer">
                           <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                           <a><img src={barchart} alt="barchart"/> Beginner</a>
                           <a><img src={clock} alt="clock"/> 6 hour</a>
                              
                              </div>
                           </div>
                           <div className="price-discount">
                              <h5>$14.00 <del>$26.00</del> <span>56% off</span></h5>
                              <div className="heart-icon">
                              <img src={HeartBlank} alt="HeartBlank"/>
                              </div>
                           </div>
                           <div className="what-your-learn">
                              <h5>What you’ll learn</h5>
                              <ul>
                                 <li>                  
                                 Learn to use Python professionally, learning both Python 2 and Python 3!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                              </ul>
                           </div>
                           <div className="hover-footesection">
                              <a className="theme-btn brows-btn" href="#">
                                 <span>
                                          <img src={ShoppingCartSimple} alt="ShoppingCartSimple"/>
                                       </span>

                                 Add to Cart
                              </a>
                              <a className="button border-btn" href="/single-course">Course Detail</a>
                           </div>
                         </div>
                     </div>
                  </div>
                  <div className="col-lg-3">
                  <div className="course-card-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                     <div className="course-hover-detalis">
                           <div className="feature-course-right-content">
                              <div className="feature-course-right-content-header">
                                 <div className="feature-course-info">
                                 <button className="course-item-5-card-info-tag tag-color2">Developments</button>
                                 </div>
                                 <a href="#" className="feature-course-head">2021 Complete Python Bootcamp From Zero to Hero in Python</a>
                                 <div className="feature-course-mid">
                                 <a href="#">
                                    <div className="feature-course-user">
                                       <div className="feature-course-user-thumb">
                                       <img src={keven} alt="keven"/>
                                       </div>
                                       <div className="feature-course-user-data">
                                       <p>Course by</p>
                                       <h5>Kevin Gilbert</h5>
                                       </div>
                                    </div>
                                 </a>
                                 <div className="ratingreview">
                                 <span className="icon"><FaStar/></span>
                                 <p> 5.0 <span>(50000.0)</span> </p>                                   
                                 </div>
                                 </div>
                              </div>
                              <div className="feature-course-footer">
                           <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                           <a><img src={barchart} alt="barchart"/> Beginner</a>
                           <a><img src={clock} alt="clock"/> 6 hour</a>
                              
                              </div>
                           </div>
                           <div className="price-discount">
                              <h5>$14.00 <del>$26.00</del> <span>56% off</span></h5>
                              <div className="heart-icon">
                              <img src={HeartBlank} alt="HeartBlank"/>
                              </div>
                           </div>
                           <div className="what-your-learn">
                              <h5>What you’ll learn</h5>
                              <ul>
                                 <li>                  
                                 Learn to use Python professionally, learning both Python 2 and Python 3!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                              </ul>
                           </div>
                           <div className="hover-footesection">
                              <a className="theme-btn brows-btn" href="#">
                                 <span>
                                          <img src={ShoppingCartSimple} alt="ShoppingCartSimple"/>
                                       </span>

                                 Add to Cart
                              </a>
                              <a className="button border-btn" href="/single-course">Course Detail</a>
                           </div>
                         </div>
                  </div>
                  </div>
                  <div className="col-lg-3">
                  <div className="course-card-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div><div className="course-hover-detalis">
                           <div className="feature-course-right-content">
                              <div className="feature-course-right-content-header">
                                 <div className="feature-course-info">
                                 <button className="course-item-5-card-info-tag tag-color2">Developments</button>
                                 </div>
                                 <a href="#" className="feature-course-head">2021 Complete Python Bootcamp From Zero to Hero in Python</a>
                                 <div className="feature-course-mid">
                                 <a href="#">
                                    <div className="feature-course-user">
                                       <div className="feature-course-user-thumb">
                                       <img src={keven} alt="keven"/>
                                       </div>
                                       <div className="feature-course-user-data">
                                       <p>Course by</p>
                                       <h5>Kevin Gilbert</h5>
                                       </div>
                                    </div>
                                 </a>
                                 <div>
                                 <div className="ratingreview">
                                 <span className="icon"><FaStar/></span>
                                 <p> 5.0 <span>(50000.0)</span> </p>                                   
                                 </div>
                                 </div>
                                 </div>
                              </div>
                              <div className="feature-course-footer">
                           <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                           <a><img src={barchart} alt="barchart"/> Beginner</a>
                           <a><img src={clock} alt="clock"/> 6 hour</a>
                              
                              </div>
                           </div>
                           <div className="price-discount">
                              <h5>$14.00 <del>$26.00</del> <span>56% off</span></h5>
                              <div className="heart-icon">
                              <img src={HeartBlank} alt="HeartBlank"/>
                              </div>
                           </div>
                           <div className="what-your-learn">
                              <h5>What you’ll learn</h5>
                              <ul>
                                 <li>                  
                                 Learn to use Python professionally, learning both Python 2 and Python 3!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                              </ul>
                           </div>
                           <div className="hover-footesection">
                              <a className="theme-btn brows-btn" href="#">
                                 <span>
                                          <img src={ShoppingCartSimple} alt="ShoppingCartSimple"/>
                                       </span>

                                 Add to Cart
                              </a>
                              <a className="button border-btn" href="/single-course">Course Detail</a>
                           </div>
                         </div> 
                     </div>
                  </div>
                  <div className="col-lg-3">
                  <div className="course-card-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div>
                     <div className="course-hover-detalis">
                           <div className="feature-course-right-content">
                              <div className="feature-course-right-content-header">
                                 <div className="feature-course-info">
                                 <button className="course-item-5-card-info-tag tag-color2">Developments</button>
                                 </div>
                                 <a href="#" className="feature-course-head">2021 Complete Python Bootcamp From Zero to Hero in Python</a>
                                 <div className="feature-course-mid">
                                 <a href="#">
                                    <div className="feature-course-user">
                                       <div className="feature-course-user-thumb">
                                       <img src={keven} alt="keven"/>
                                       </div>
                                       <div className="feature-course-user-data">
                                       <p>Course by</p>
                                       <h5>Kevin Gilbert</h5>
                                       </div>
                                    </div>
                                 </a>
                                 <div>
                                 <div className="ratingreview">
                                 <span className="icon"><FaStar/></span>
                                 <p> 5.0 <span>(50000.0)</span> </p>                                   
                                 </div>
                                 </div>
                                 </div>
                              </div>
                              <div className="feature-course-footer">
                           <a><img src={usercourse} alt="usercourse"/> <span className="corse-student"><span className="cr-value">265.7K</span> students</span></a>
                           <a><img src={barchart} alt="barchart"/> Beginner</a>
                           <a><img src={clock} alt="clock"/> 6 hour</a>
                              
                              </div>
                           </div>
                           <div className="price-discount">
                              <h5>$14.00 <del>$26.00</del> <span>56% off</span></h5>
                              <div className="heart-icon">
                              <img src={HeartBlank} alt="HeartBlank"/>
                              </div>
                           </div>
                           <div className="what-your-learn">
                              <h5>What you’ll learn</h5>
                              <ul>
                                 <li>                  
                                 Learn to use Python professionally, learning both Python 2 and Python 3!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                                 <li>
                                 
                                 Create games with Python, like Tic Tac Toe and Blackjack!
                                 </li>
                              </ul>
                           </div>
                           <div className="hover-footesection">
                              <a className="theme-btn brows-btn" href="#">
                                 <span>
                                          <img src={ShoppingCartSimple} alt="ShoppingCartSimple"/>
                                       </span>

                                 Add to Cart
                              </a>
                              <a className="button border-btn" href="/single-course">Course Detail</a>
                           </div>
                         </div> 
                      </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 text-center more-view pt-4 pb-5">
                <a className="brows-btn">Browse all Course <img src={arrowright} alt="headphones"/> </a>
                  </div>
</div>

               <div className="became-instuctor row">
               <div className="col-lg-6">
               <div className="row instructor-inner">
               <div className="became-instructor-content">
                     <h2>Become an instructor</h2>
                     <p>Instructors from around the world teach millions of students on Udemy. We provide the tools and skills to teach what you love.</p>
                     <a className="brows-btn">Start teaching <img src={arrowright} alt="headphones"/> </a>
                  </div>
                  <div className="became-instructor-image">
                  <img src={beinstructor} alt="beinstructor"/>
                  </div>
               </div>
                  
               </div>
               <div className="col-lg-6">
               <div className="step-block">
                  <h2>Your teaching & earning steps</h2>
                  <ul className="step-list">
                     <li><span className="step1">1</span>Apply to become instructor</li>
                     <li><span className="step2">2</span>Build & edit your profile</li>
                     <li><span className="step3">3</span>Create your new course</li>
                     <li><span className="step4">4</span>Start teaching & earning</li>
                  </ul>
               </div>


               </div>

               </div>
      <div className="row instructor-section">
            <div className="col-lg-12 category-heading">
                  <h2>Top instructor of the month</h2>

               </div>

               <div className="best-course-list">
                  <div className="col-s-5 instructor-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={instructor} alt="instructor"/></div>
                        <div className="course-body text-center">
                              <div className="instructor-name">Devon Lane</div>
                              <p className="instructor-position">Senior Developer</p>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5 instructor-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={instructor} alt="instructor"/></div>
                        <div className="course-body text-center">
                              <div className="instructor-name">Devon Lane</div>
                              <p className="instructor-position">Senior Developer</p>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5 instructor-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={instructor} alt="instructor"/></div>
                        <div className="course-body text-center">
                              <div className="instructor-name">Devon Lane</div>
                              <p className="instructor-position">Senior Developer</p>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                   <div className="col-s-5 instructor-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={instructor} alt="instructor"/></div>
                        <div className="course-body text-center">
                              <div className="instructor-name">Devon Lane</div>
                              <p className="instructor-position">Senior Developer</p>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5 instructor-block">
                     <div className="best-course-card">
                        <div className="course-image"><img src={instructor} alt="instructor"/></div>
                        <div className="course-body text-center">
                              <div className="instructor-name">Devon Lane</div>
                              <p className="instructor-position">Senior Developer</p>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><FaStar/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-xs-12 col-sm-12 text-center more-view ">
                  Thousands of students waiting for a instructor. Start teaching & earning now!. <a>Become Instructor <img src={arrowright} alt="headphones"/> </a>
                  </div>
                  </div>
                  

</div>

            <div className="row ourclient align-items-center">
            <div className="col-lg-4">
               <h2>6.3k trusted companies</h2>
               <p>Nullam egestas tellus at enim ornare tristique. Class aptent taciti sociosqu ad litora torquent per conubia nostra.</p>
            </div>
            <div className="col-lg-8">
            <ul className="client-list">
               <li><div className="logo-client"><img src={netflix} alt="netflix"/></div></li>
               <li><div className="logo-client"><img src={youtube} alt="youtube"/></div></li>
               <li><div className="logo-client"><img src={google} alt="google"/></div></li>
               <li><div className="logo-client"><img src={lenovo} alt="lenovo"/></div></li>
               <li><div className="logo-client"><img src={slack} alt="slack"/></div></li>
               <li><div className="logo-client"><img src={verizon} alt="verizon"/></div></li>
               <li><div className="logo-client"><img src={lexmark} alt="lexmark"/></div></li>  
               <li><div className="logo-client"><img src={microsoft} alt="microsoft"/></div></li>
            </ul>

            </div>
            </div>


         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
      </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}
export default CreateCourses;