import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../components/BookingCtWeek";
import BookingCtMonth from "../components/BookingCtMonth";
import RevieCtWeek from "../components/RevieCtWeek";
import RevieCtMonth from "../components/RevieCtMonth";
import CustomerCtweek from "../components/CustomerCtweek";
import CustomerCtMonth from "../components/CustomerCtMonth";
import dsicn from "../../src/images/ds-icon.png";
import clicn from "../../src/images/uil_calender.svg";
import coloricon from "../../src/images/cl-icon-color.svg";
import timeicon from "../../src/images/time.svg";
import useicon from "../../src/images/user-cion.svg";
import downloadicon from "../../src/images/download-icon.svg";
import startrating from "../../src/images/start-r.svg";
import editicon from "../../src/images/edit-icon.svg";
import filtericon from "../../src/images/filter-icon.svg";
import fileicon from "../../src/images/file.svg";
import roketicon from "../../src/images/rocket-icon.svg";
import flagicon from "../../src/images/flag-icon.svg";
import chckicon from "../../src/images/check-icon.svg";
import subicon from "../../src/images/file-ck.svg";
import BookingTable from "../components/BookingTable";
import BookingModal from "../components/BookingModal";
import roundck from "../../src/images/round-check.svg";
import specialicon from "../../src/images/sp-icons.svg";
import studentuser from "../../src/images/student_img.jpg";
import arrowright from "../../src/images/button-arrowright.svg";
import trophy from "../../src/images/Trophy.svg";
import playcirclec from "../../src/images/PlayCircle-c.svg";
import checksquare from "../../src/images/CheckSquare.svg";
import arrowdown from "../../src/images/ArrowDown.svg";

import studentCourse1 from "../../src/images/studentCourse1.jpg";
import studentCourse2 from "../../src/images/studentCourse2.jpg";
import studentCourse3 from "../../src/images/studentCourse3.jpg";
import studentCourse4 from "../../src/images/studentCourse4.jpg";
import usersgroup from "../../src/images/users-group.svg";
import pRight from "../../src/images/p-ArrowRight.svg";
import pLeft from "../../src/images/p-ArrowLeft.svg";
import NewRevinew from "../components/NewRevinew";
import MultiLineChart from "../components/MultiLineChart";
import ReviewLineChart from "./components/ReviewLineChart";
import ProfileViewBar from "./components/ProfileViewBar";

import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "../components/MobileMenu";
//import Sildebar from "../Sildebar";
import TopMenu from"../components/TopMenu";
import FilerDashboard from "../components/FilerDashboard";
import CalenderMultiple from "../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../components/LabDashboradFilter";
import Newgrphadd from "../../src/images/customer-graph.svg";
import InstructorSidebar from "./components/InstructorSidebar";
import $ from "jquery";

class InstructorDashboard extends React.Component {
   componentWillMount(){

      $(document).ready(function(){
        $(".togle-icon").click(function(e) {
            e.preventDefault();
            $("#navAccordion").toggleClass("active");
            $("main").toggleClass("full-width");
            $("footer").toggleClass("full-width");
      
      });
      });
      
    }
   render() {
     return(
        <>
        <div className='page-wrapper chiller-theme toggled instructor-main'>
        <div className="main-sec d-lg-flex instructor-main">
          <div className="sidebar-menu" id="navAccordion">
             <InstructorSidebar/>
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
          

            <div className="d-none d-lg-block">
                  <div className="top-bar-menu shadow float-start">
                     <a className="tog-btn me-2 togle-icon">
                     <FaBars/>
                     </a>
                     <div className="d-flex me-auto  align-items-center">
                        <h5 className="mb-0 crm-newadd"> Student ID: <span>  NS3608320 </span>  </h5>
                        <h5 className="mb-0 ms-3 crm-newadd"> Student Name:<span> Navdeep Singh </span>  </h5>
                     </div>
                     <TopMenu/>
                  </div>
            </div>
             
            <div className="dasb-div bk-div float-start w-100">

<div className="instructor_wrapper ">
         <div className="row g-lg-4 student_dashboard-info topcategory row">

            <div className="col-lg-12 heading-box">
               <h2>Dashboard</h2>
            </div>   
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={playcirclec} alt="playcirclec"/></div>
                  <div className="cat-content ">
                  <h5>957</h5>
                  <p>Enrolled Courses</p>
                  </div>
               </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={checksquare} alt="checksquare"/></div>
                  <div className="cat-content ">
                  <h5>6</h5>
                  <p>Active Courses</p>
                  </div>
               </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={trophy} alt="trophy"/></div>
                  <div className="cat-content ">
                  <h5>951</h5>
                  <p>Completed Courses</p>
                  </div>
               </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={usersgroup} alt="usersgroup"/></div>
                  <div className="cat-content ">
                  <h5>241</h5>
                  <p>Course Instructors</p>
                  </div>
               </div>
            </div>  
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={playcirclec} alt="playcirclec"/></div>
                  <div className="cat-content ">
                  <h5>957</h5>
                  <p>Enrolled Courses</p>
                  </div>
               </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={checksquare} alt="checksquare"/></div>
                  <div className="cat-content ">
                  <h5>6</h5>
                  <p>Active Courses</p>
                  </div>
               </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={trophy} alt="trophy"/></div>
                  <div className="cat-content ">
                  <h5>951</h5>
                  <p>Completed Courses</p>
                  </div>
               </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3 ">
               <div className="inline-box cat1">
               <div className="cat-image"><img src={usersgroup} alt="usersgroup"/></div>
                  <div className="cat-content ">
                  <h5>241</h5>
                  <p>Course Instructors</p>
                  </div>
               </div>
            </div>

         </div>
         <div className="instructor_information ">
            <div className="col-lg-12 instructor-box">
               <div className="row align-items-center">
                  <div className="col-md-3">
                  <div className="instructor_info ">
                     <div className="instructor_image"><img src={studentuser} alt="studentusercl"/> </div>
                     <div className="instructor_content">
                        <h3>Vako Shvili</h3>
                        <p>vako.shvili@gmail.com</p>
                     </div>
                  </div>
                  </div>
                  <div className="col-md-6">
                     <div className="step-info">
                        <span>1/4 Steps</span>
                        <div className="line-bar"></div>
                        <div className="process_complete">25% Completed</div>
                     </div>
                  </div>
                  <div className="col-md-3 text-right">
                  <div className="group-links">
                     <a  className="brows-btn" href="" >Edit Biography</a> <a className="down-link" href=""><img src={arrowdown} alt="arrowdown"/></a></div>
                  </div>
               </div>
            </div>
         </div>
         <div className="row g-lg-4 instructor_colms  ">

              
            <div className="col-xs-12 col-sm-6 col-md-3 notification-box ">
            <div className="activity-box">
               <div className="card-header">
                  <h2 className="card-title">Recent Activity</h2>
                     <div className="dropdown">
                        <a className=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Today</a>
                           <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                              <a className="dropdown-item" href="#">Action</a>
                              <a className="dropdown-item" href="#">Another action</a>
                              <a className="dropdown-item" href="#">Something else here</a>                              
                              <a className="dropdown-item" href="#">Separated link</a>
                           </div>
                      </div>
               </div>
               <div className="card-body">
                  <ul className="activity">
                     <li className="activity-item">
                        <div className="activity-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M2.83927 11.0622C2.09381 9.80558 1.83274 8.32 2.10509 6.88447C2.37744 5.44894 3.16447 4.16221 4.3184 3.26589C5.47232 2.36958 6.91376 1.92534 8.37204 2.0166C9.83033 2.10785 11.2051 2.72833 12.2383 3.7615C13.2715 4.79468 13.892 6.16949 13.9832 7.62777C14.0745 9.08606 13.6303 10.5275 12.734 11.6814C11.8377 12.8354 10.5509 13.6224 9.1154 13.8947C7.67988 14.1671 6.1943 13.906 4.93763 13.1606L4.93764 13.1605L2.86536 13.7526C2.77962 13.7771 2.68889 13.7782 2.60257 13.7559C2.51626 13.7335 2.43749 13.6885 2.37444 13.6254C2.31139 13.5624 2.26634 13.4836 2.24398 13.3973C2.22161 13.311 2.22273 13.2202 2.24723 13.1345L2.83931 11.0622L2.83927 11.0622Z" fill="white"></path>
                          <path d="M8 8.75C8.41421 8.75 8.75 8.41421 8.75 8C8.75 7.58579 8.41421 7.25 8 7.25C7.58579 7.25 7.25 7.58579 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75Z" fill="#FF6636"></path>
                            <path d="M5 8.75C5.41421 8.75 5.75 8.41421 5.75 8C5.75 7.58579 5.41421 7.25 5 7.25C4.58579 7.25 4.25 7.58579 4.25 8C4.25 8.41421 4.58579 8.75 5 8.75Z" fill="#FF6636"></path>
                           <path d="M11 8.75C11.4142 8.75 11.75 8.41421 11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8C10.25 8.41421 10.5858 8.75 11 8.75Z" fill="#FF6636"></path>
                           </svg>

               </div>
                           <div className="activity-info">
                           <p> <span className="username">Kevin</span> comments on your lecture “What is ux” in “2021 ui/ux design with figma”</p>
                           <span className="time">Just now</span>
                           </div>
                        </li>
                        <li className="activity-item">
                           <div className="activity-icon">
                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.27569 11.9208L11.4279 13.9179C11.8308 14.1732 12.3311 13.7935 12.2115 13.3232L11.3008 9.74052C11.2752 9.64073 11.2782 9.53573 11.3096 9.4376C11.3409 9.33946 11.3994 9.25218 11.4781 9.18577L14.3049 6.83306C14.6763 6.52392 14.4846 5.90751 14.0074 5.87654L10.3159 5.63696C10.2165 5.62986 10.1211 5.59465 10.0409 5.53545C9.96069 5.47625 9.89896 5.39548 9.86289 5.30255L8.48612 1.83549C8.44869 1.73685 8.38215 1.65194 8.29532 1.59201C8.2085 1.53209 8.1055 1.5 8 1.5C7.89451 1.5 7.79151 1.53209 7.70468 1.59201C7.61786 1.65194 7.55131 1.73685 7.51389 1.83549L6.13712 5.30255C6.10104 5.39548 6.03932 5.47625 5.95912 5.53545C5.87892 5.59465 5.78355 5.62986 5.68412 5.63696L1.99263 5.87654C1.51544 5.90751 1.32373 6.52392 1.69515 6.83306L4.52186 9.18577C4.60063 9.25218 4.65907 9.33946 4.69044 9.4376C4.72181 9.53573 4.72485 9.64073 4.6992 9.74052L3.85459 13.063C3.71111 13.6274 4.31143 14.083 4.79495 13.7767L7.72431 11.9208C7.8067 11.8683 7.90234 11.8405 8 11.8405C8.09767 11.8405 8.19331 11.8683 8.27569 11.9208Z" fill="white"></path>
                           </svg>

                           </div>
                           <div className="activity-info">
                           <p> <span className="username">Kevin</span> comments on your lecture “What is ux” in “2021 ui/ux design with figma”</p>
                           <span className="time">5 min ago</span>
                           </div>
                        </li>
                        <li className="activity-item">
                           <div className="activity-icon">
                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.2" d="M11.5007 5H2.50073C2.22459 5 2.00073 5.22386 2.00073 5.5V12.5C2.00073 12.7761 2.22459 13 2.50073 13H11.5007C11.7769 13 12.0007 12.7761 12.0007 12.5V5.5C12.0007 5.22386 11.7769 5 11.5007 5Z" fill="white"></path>
                              <path d="M11.5007 5H2.50073C2.22459 5 2.00073 5.22386 2.00073 5.5V12.5C2.00073 12.7761 2.22459 13 2.50073 13H11.5007C11.7769 13 12.0007 12.7761 12.0007 12.5V5.5C12.0007 5.22386 11.7769 5 11.5007 5Z" fill="white"></path>
                              <path d="M4.00073 3H13.5007C13.6333 3 13.7605 3.05268 13.8543 3.14645C13.9481 3.24021 14.0007 3.36739 14.0007 3.5V11" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"></path>
                           </svg>

                           </div>
                           <div className="activity-info">
                           <p> <span className="username">Kevin</span> comments on your lecture “What is ux” in “2021 ui/ux design with figma”</p>
                           <span className="time">6 min ago</span>
                           </div>
                        </li>
                     
                  
                     </ul>
                     </div>
             </div>
               
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 ">
               <div className="revenu-div activity-box ">
                  <div className="card-header d-flex justify-content-between align-items-center">
                  <h2 className="card-title"> Total Revenue </h2>
                  <div className="dropdown ms-3">
                              <button className="cs-dropdown btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              Today
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" href="#">Action</a></li>
                              <li><a className="dropdown-item" href="#">Another action</a></li>
                              <li><a className="dropdown-item" href="#">Something else here</a></li>
                              </ul>
                           </div>
                  </div>
                  <div className="cardbody">  <RevieCtWeek/></div>
                

               </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3 ">
                  <div className="total-content-div new-dit-add activity-box">
                  <div className="card-header d-flex justify-content-between align-items-center">
                     
                     <h2 className="card-title"> Profile View </h2>
                           <div className="dropdown ms-3">
                              <button className="cs-dropdown btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              Today
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item" href="#">Action</a></li>
                              <li><a className="dropdown-item" href="#">Another action</a></li>
                              <li><a className="dropdown-item" href="#">Something else here</a></li>
                              </ul>
                           </div>
                   
                     
                   

                  </div>
                  <div className="cardbody"> <ProfileViewBar/>
                  <div className="total-earning">$7,443</div>
                  <span className="earning-text">USD Dollar you earned.</span>
                  </div>
                
               </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 ">
               <div className="comon-chart-globe activity-box border-0">
                  <div className="card-header d-flex justify-content-between align-items-center">
                     <h2 className="card-title">Overall Course Rating </h2>
                     <div className="dropdown">
                        <a className=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                         data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">This week</a>
                           <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                              <a className="dropdown-item" href="#">Action</a>
                              <a className="dropdown-item" href="#">Another action</a>
                              <a className="dropdown-item" href="#">Something else here</a>                              
                              <a className="dropdown-item" href="#">Separated link</a>
                           </div>
                      </div>
                  </div>

                  <div className="cardbody">
                  <div className="instructoru_reviews row">
                     <div className="col-md-5">
                     <div className="rating-box reviewsinf0">
                                    <h4>4.8</h4>
                                    <ul className="total-star list-inline">
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                       <span className="star-icon"> <FaStarHalfAlt /> </span>

                                    </li>
                                    </ul>
                                    <p>Course Rating</p>
                                 </div>
                      </div>
                      <div className="col-md-7">
                      <ReviewLineChart />
                      </div>
                      </div>
                      <div className="instructoru_reviews-all row">
                      <div className="col-md-12">
                      <div className="rating-percentage">
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       5 Star 
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage four"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>75%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       4 Star 
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage four"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>21%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       3 Star 
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage three"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>3%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       2 Star 
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage four"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>2%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       1 Star 
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage one"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>2%</span>
                                    </div>
                                    </div>
                                 </div>
                      </div>
                  </div>
                  
                 
                  
                  
                  </div>

                     

               </div>
               
            </div>
            <div className="col-xs-12 col-sm-6 col-md-7 ">
               <div className="comon-chart-globe border-0 activity-box ">
                              
               <div className="card-header d-flex justify-content-between align-items-center">
                  <h2 className="card-title"> Course Overview </h2>
                  <div className="dropdown">
                        <a className=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                         data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">This week</a>
                           <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                              <a className="dropdown-item" href="#">Action</a>
                              <a className="dropdown-item" href="#">Another action</a>
                              <a className="dropdown-item" href="#">Something else here</a>                              
                              <a className="dropdown-item" href="#">Separated link</a>
                           </div>
                      </div>
                  </div>
                <div className="cardbody"><MultiLineChart /></div>
             </div>
            </div>
        </div>
</div>
            


        
   
  
</div>
          </div>
        </main>
       </div>

       
        <div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg">
            <div className="modal-content">
               <div className="modal-header pb-0">
                  
               <ul className="nav nav-pills" id="pills-tab" role="tablist">
                     <li className="nav-item" role="presentation">
                     <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Paitent Details</button>
                     </li>
                     <li className="nav-item" role="presentation">
                     <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
                     </li>
                     
               </ul>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">

                  <div className="tabs-settings">
                     
                     <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              
                              <div className="patinent-details">
                                 <div className="inside-history-div col-lg-12 mx-auto mt-3">
                                    <h6 className="mn-head"> 10 Results Found </h6> 
                                    <div className="result-div mt-3">
                                       <div className="cm-history mx-auto row">
                                          <div className="col-lg-10">
                                             <div className="left-history">
                                                <h5 className="d-lg-flex"> Veena Rao  </h5>
                                                <div className="same-details">
                                                   <span> 30 Yrs, Female </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                   <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                             <div className="left-history mt-5">
                                                <h6 className="nw-add"> Beneficiary </h6>
                                                <h5> Jaswant Thakur </h5>
                                                <div className="same-details">
                                                   <span> 45 Yrs, Male </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                          </div>
                                          <div className="col-lg-2">
                                             <div className="right-sec d-grid justify-content-lg-end">
                                                <h5> Rs. 1500/- </h5>
                                                <h6 className="ps-st">
                                                   <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                                </h6>
                                                <figure className="mb-0">
                                                   <img src={startrating} alt="ck"/>
                                                </figure>
                                                <p className="text-center"> Satisfied </p>
                                             </div>
                                             
                                          </div>
                                       </div>



                                    </div>
                           
                                 </div>

                                 <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                                       <div className="row row-cols-7 justify-content-center">
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={fileicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Accepted 
                                                   <span className="d-block">
                                                   (09:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={roketicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Started
                                                   <span className="d-block">
                                                   (10:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm ">
                                                   <img src={flagicon} alt="file"/>
                                             </figure>
                                             <h5>
                                              Reached
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={specialicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Collected
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={subicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Submitted
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       </div>
                                 </div>
                              </div>

                              
                        
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        
                              <div className="inside-history-div col-lg-11 ms-auto mt-3">
                                 <h6 className="mn-head"> 10 Results Found </h6> 
                                 <div className="result-div mt-3">
                                    <div className="cm-history row">
                                       <div className="col-lg-11">
                                          <div className="left-history">
                                             <h5> Veena Rao
                                             </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span > <FaCircle/> 25-02-2022 </span>
                                                <span > <FaCircle/>  #ORD6848r4 </span>
                                                <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          <div className="left-history mt-5">
                                             <h6 className="nw-add"> Beneficiary </h6>
                                             <h5> Jaswant Thakur </h5>
                                             <div className="same-details">
                                                <span> 45 Yrs, Male </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                       </div>
                                       <div className="col-lg-1">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1500/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 500/- </h5>
                                             <h6 className="unps-st">
                                                Unpaid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 2000/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1200/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>


                                 </div>
                                 
                              </div>

                        </div>
                        
                     </div>
                  </div>






                  



               </div>
               
            </div>
         </div>
        </div>
       
        <MobileMenu/>


       <LabDashboradFilter/>

        <div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <CalenderMultiple/>
                  </div>
                  
               </div>
            </div>
        </div>
        </>
     );
   }
 }

export default InstructorDashboard;