import React from "react";
import {NavLink } from "react-router-dom";
import sidebarfilter from "./components/sidebarfilter";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';

import user3 from '../src/images/user3.png';
import uparrow from '../src/images/up-arrow.svg';
import arrowCaret from '../src/images/arrowCaretDown.svg';
import filters from '../src/images/filters-icon.svg';
import search from '../src/images/search-icon.svg';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';
import passport from '../src/images/passport-image.jpg';
import CoursesTable from "./components/CoursesTable"; 
import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";
import cpu from "../src/images/cpu.svg";
import handshake from "../src/images/Handshake.svg";
import crditCard from "../src/images/CreditCard.svg";
import chartBar from "../src/images/ChartBarHorizontal.svg";
import bugdroid from "../src/images/bugdroid.svg";
import receipt from "../src/images/Receipt.svg";
import megaphone from "../src/images/MegaphoneSimple.svg";
import camerac from "../src/images/Camerac.svg";
import packagec from "../src/images/Package.svg";
import PenNib from "../src/images/PenNib.svg";
import firstaid from "../src/images/FirstAidKit.svg";
import headphones from "../src/images/Headphones.svg";
import arrowright from "../src/images/button-arrowright.svg";
import courseimages from "../src/images/course-images.jpg";
import featureimages from "../src/images/feature-course.jpg";
import usercourse from "../src/images/User-course.svg";
import barchart from "../src/images/bar-chart.svg";
import clock from "../src/images/Clock.svg";
import star from "../src/images/star.svg";
import keven from "../src/images/keven.png";
import instructor from "../src/images/instructor-img.jpg";
import pRight from "../src/images/p-ArrowRight.svg";
import pLeft from "../src/images/p-ArrowLeft.svg";
import netflix from "../src/images/netflix.svg";
import youtube from "../src/images/youtube.svg";
import google from "../src/images/google.svg";
import lenovo from "../src/images/lenovo.svg";
import slack from "../src/images/slack.svg";
import lexmark from "../src/images/lexmark.svg";
import microsoft from "../src/images/microsoft.svg";
import verizon from "../src/images/verizon.svg";
import beinstructor from "../src/images/become-instructor.png";
import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery";

class  CoursesListFilter extends React.Component {
componentWillMount(){
$(document).ready(function(){
$(".togle-icon").click(function(e) {
e.preventDefault();
$("#navAccordion").toggleClass("active");
$("main").toggleClass("full-width");
$("footer").toggleClass("full-width");
});
});
}
render() {
return(
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
            
            
   <div className="course-list-section">
         <div className="filter-wrapper row">
            <div className="filter_head col-lg-7">
               <div className="filter-left">
                     <button className="filter_toggle"><img src={filters} alt="filters"/> Filter <span class="course_count">3</span></button>
                     <div className="course-search">
                  <form>
                     <input type="text" placeholder="UI/UX Design" value=""/>
                     <button type="submit"><img src={search} alt="search-icon"/></button>
                  </form>
               </div>
               </div>
              
            </div>
            <div className="filter_head col-lg-5">
               <div className="filter-shorting">
               <form><label>Sort by</label>
               <select className="form-select" aria-label="Default select example">
                  <option selected>Trending</option>
                  <option value="1">Trending One</option>
                  <option value="2">Trending Two</option>
                  <option value="3">Trending Three</option>
                  </select>
                  </form>
               </div> 
            </div>
         </div>
         <div className="row filter-suggestion">
         <div className=" col-lg-7">
            <div className="suggestion-tags"><span>Suggestion:</span> <a>user interface</a> <a>user experience</a> <a>web design</a><a>interface</a> <a>app</a></div>
         </div>
         <div className=" col-lg-5">
         <div className="filter_result-text"><span>3,145,684</span> results find for “ui/ux design”</div>
         </div>
         </div> 
         <div className="course-block-filter row">
            <div className="col-lg-3">
               <div className="filter-sidebar">
                  <div className="filter-item">
                  <h2 className="toggle-heading">  Category <span className="ft-toggle"><img src={uparrow} alt="uparrow"/></span></h2>
                  
                     <ul className="toggle-content">
                        <li className="cat-item active" ><span className="cat-icon"><img src={cpu} alt="Cpu"/></span>  Development
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span>
                              <ul className="submenu-list">
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="webdevelopment" />
                                 <label className="form-check-label" for="webdevelopment">
                                  Web development  </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                     <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="data-science" />
                                 <label className="form-check-label" for="data-science">
                                 Data Science  </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="mobile-development" />
                                 <label className="form-check-label" for="mobile-development">
                                 Mobile Development </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="software-testing" />
                                 <label className="form-check-label" for="software-testing">
                                 Software Testing </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="software-e" />
                                 <label className="form-check-label" for="software-e">
                                 Software Engineering </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="software-dt" />
                                 <label className="form-check-label" for="software-dt">
                                 Software Development Tools </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="ncd-dc" />
                                 <label className="form-check-label" for="No-Code Development">
                                 No-Code Development</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                              </ul>
                     </li>
                     <li className="cat-item"><span className="cat-icon"><img src={cpu} alt="Cpu"/></span>  Business
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={handshake} alt="handshake"/></span>  Finance & Accounting
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={crditCard} alt="crditCard"/></span>  IT & Software
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={bugdroid} alt="bugdroid"/></span>  Office Productivity
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={receipt} alt="receipt"/></span>  Personal Development
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={PenNib} alt="PenNib"/></span>  Design
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={megaphone} alt="megaphone"/></span>  Marketing
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={packagec} alt="packagec"/></span>  Lifestyle
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={camerac} alt="Camerac"/></span>  Photography & Video
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={headphones} alt="headphones"/></span>  Music
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                             <li className="cat-item"><span className="cat-icon"><img src={firstaid} alt="firstaid"/></span>  Health & Fitness
                             <span className="dropdown-icon">  <img src={arrowCaret} alt="arrowCaret"/></span></li>
                     </ul>

                  </div>
                  <div className="filter-item">
                  <h2 className="toggle-heading">  Tools <span className="ft-toggle"><img src={uparrow} alt="uparrow"/></span></h2>
                  
                     <ul className="toggle-content">
                     <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="html5" />
                                 <label className="form-check-label" for="html5">
                                 HTML 5 </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="css3" />
                                 <label className="form-check-label" for="css3">
                                 CSS 3</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="react" />
                                 <label className="form-check-label" for="react">
                                 React</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="webflow" />
                                 <label className="form-check-label" for="webflow">
                                 Webflow </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                   <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="nodejs" />
                                 <label className="form-check-label" for="nodejs">
                                 Node.js </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="laravel" />
                                 <label className="form-check-label" for="laravel">
                                 Laravel </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="saas" />
                                 <label className="form-check-label" for="saas">
                                 Saas </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="wordpress" />
                                 <label className="form-check-label" for="wordpress">
                                 Saas </label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 
                    
                     </ul>

                  </div>
                  <div className="filter-item">
                  <h2 className="toggle-heading">  Rating <span className="ft-toggle"><img src={uparrow} alt="uparrow"/></span></h2>
                  
                     <ul className="toggle-content">
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="5star" />
                                 <label className="form-check-label" for="5star">
                                 <img src={star} alt="star"/> 5 Star</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="4starup" />
                                 <label className="form-check-label" for="4starup">
                                 <img src={star} alt="star"/> 4 Star & up</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="3starup" />
                                 <label className="form-check-label" for="3starup">
                                 <img src={star} alt="star"/> 3 Star & up</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="2starup" />
                                 <label className="form-check-label" for="2starup">
                                 <img src={star} alt="star"/>2 Star & up</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="1starup" />
                                 <label className="form-check-label" for="1starup">
                                 <img src={star} alt="star"/>1 Star & up</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                
                    
                     </ul>

                  </div>
                  <div className="filter-item">
                  <h2 className="toggle-heading">  Course level <span className="ft-toggle"><img src={uparrow} alt="uparrow"/></span></h2>
                  
                     <ul className="toggle-content">
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="alllevel" />
                                 <label className="form-check-label" for="alllevel">
                                 All Level</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="beginner" />
                                 <label className="form-check-label" for="beginner">
                                 Beginner</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="intermediate" />
                                 <label className="form-check-label" for="intermediate">
                                  Intermediate</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="expert" />
                                 <label className="form-check-label" for="expert">
                                 Expert</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 </ul>

                  </div>
                  <div className="filter-item">
                  <h2 className="toggle-heading"> Price<span className="ft-toggle"><img src={uparrow} alt="uparrow"/></span></h2>
                  
                     <ul className="toggle-content">
                                 <li className="sub-cat-item">  
                                 
                                 <input type="range" class="form-range" id="customRange1"/>
                                 </li>
                              
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="Free" />
                                 <label className="form-check-label" for="Free">
                                 Free</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="paid" />
                                 <label className="form-check-label" for="paid">
                                 Paid</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 </ul>

                  </div>


                  <div className="filter-item">
                  <h2 className="toggle-heading"> Duration<span className="ft-toggle"><img src={uparrow} alt="uparrow"/></span></h2>
                  
                     <ul className="toggle-content">
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="612Months" />
                                 <label className="form-check-label" for="612Months">
                                 6-12 Months</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="36Months" />
                                 <label className="form-check-label" for="36Months">
                                 3-6 Months</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="13Months" />
                                 <label className="form-check-label" for="13Months">
                                 1-3 Months</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="14Weeks" />
                                 <label className="form-check-label" for="14Weeks">
                                 1-4 Weeks</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 <li className="sub-cat-item">  
                                 <div className="form-check"> <input className="form-check-input" type="checkbox" value="" id="17Days" />
                                 <label className="form-check-label" for="17Days">
                                 1-7 Days</label>
                                 </div> 
                                  <span className="badge-value ">574</span>
                                 </li>
                                 </ul>

                  </div>
            </div>

            </div>
         <div className="col-lg-9">
            <div className="filter-course-list row"> 
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>
                     <div className="col-lg-4">
                        <div className="best-course-card">
                           <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                           <div className="course-body">
                                 <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                 <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                           </div>
                              <div className="course-footer">
                              <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                              <span className="corse-student"><img src={usercourse} alt="usercourse"/>  <span className="cr-value">265.7K</span> students</span>
                              </div>
                        </div> 
                     </div>


            </div>
            <div className="course-pagination "> 
                        <nav aria-label="navigation ">
               <ul className="pagination">
                  <li className="page-item">
                     <a className="page-link" href="#" aria-label="Previous">
                     <span aria-hidden="true"><img src={pLeft} alt="pLeft"/></span>
                     </a>
                  </li>
                  <li className="page-item"><a class="page-link active" href="#">1</a></li>
                  <li className="page-item"><a class="page-link" href="#">2</a></li>
                  <li className="page-item"><a class="page-link" href="#">3</a></li>
                  <li className="page-item"><a class="page-link" href="#">3</a></li>
                  <li className="page-item"><a class="page-link" href="#">5</a></li>
                  <li className="page-item">
                     <a className="page-link" href="#" aria-label="Next">
                     <span aria-hidden="true"><img src={pRight} alt="pRight"/></span>
                     </a>
                  </li>
               </ul>
               </nav>
            </div>
         </div>
         </div>

   </div>   
 
              



         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
      </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}

export default CoursesListFilter;