import { FaRegEnvelope } from "react-icons/fa";
import { BsCheck2Circle, BsFillCartFill, BsFillGeoAltFill } from "react-icons/bs";


const DashbordNotificationPin =[

    {
        id:1,
        // icon:<FaRegEnvelope/>,
        titel:"Veena Rao Paid the invoice",
        days:"4 mins ago",
        orderid:"#orderid456666",
    },
    {
        id:2,
        // icon:<BsCheck2Circle/>,
        titel:"ABC Lab, issue raised",
        days:"4 mins ago",
        orderid:"#labid86566",
    },
    {
        id:3,
        // icon:<BsFillCartFill/>,
        titel:"Veena Rao Paid the invoice",
        days:"4 mins ago",
        orderid:"#labid86566",
    },
    {
        id:4,
        // icon:<BsFillGeoAltFill/>,
        titel:"Veena Rao Paid the invoice",
        days:"4 mins ago",
        orderid:"#labid86566",
    }
]
export default DashbordNotificationPin;