import React from "react";
import Sidebar from "./Sidebar";
import TopMenu from"./components/TopMenu";
import Testanal from "../src/images/test-icon.svg";
import graphicon from "../src/images/graph-icon.svg";
import TestTable from "./components/TestTable";
import downloadicon from "../src/images/download-icon.svg";
import editicon from "../src/images/edit-icon.svg";
import cmaddicon from "../src/images/add-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import DrodownTestSerach from "./components/DrodownTestSerach";
import AnalGraphWeek from "./components/AnalGraphWeek";
import AnalGraphMonth from "./components/AnalGraphMonth";
import AddTestSection from "./components/AddTestSection";
import FileUploadSection from "./components/FileUploadSection";
import Packgicon from "../src/images/packages-icon.svg";
import reddeleteicon from "../src/images/red-dele.svg"; 
import TestsTable from "./components/TestsTable";
import { FaBars, FaChevronLeft } from "react-icons/fa";
import $ from "jquery";
import MobileMenu from "./components/MobileMenu";
import PackagesTable from "./components/PackagesTable";
import LabTestFilter from "./components/LabTestFilter";
import { NavLink } from "react-router-dom";

class myJavaScriptFile extends React.Component {
    componentWillMount(){

        $(document).ready(function(){
            

            $('.accordion .accordion-head').click(function () {
                  if ($(this).next('div').is(':visible')) {
                    $(this).next('div').slideUp('normal');
                    $(this).find('i').removeClass('arrow-open').addClass('arrow-close');
                  } else if ($(this).next('div').is(':hidden')) {
                    $(this).next('div').slideDown('normal');
                    $(this).find('i').removeClass('arrow-close').addClass('arrow-open');
                  } else {
                    $('.accordion-body').slideUp('normal');
                    $(this).next('div').slideToggle('normal');
                    $(this).find('i').removeClass('arrow-open').addClass('arrow-close');
                  }
                }); //this month form
            
            
        });


        
      }
    render() {
      return(
          <>
        
            <MobileMenu/>

            <LabTestFilter/>
          </>
      );
    }
  }

export default myJavaScriptFile;