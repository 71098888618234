import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../components/BookingCtWeek";
import BookingCtMonth from "../components/BookingCtMonth";
import RevieCtWeek from "../components/RevieCtWeek";
import RevieCtMonth from "../components/RevieCtMonth";
import CustomerCtweek from "../components/CustomerCtweek";
import CustomerCtMonth from "../components/CustomerCtMonth";
import dsicn from "../../src/images/ds-icon.png";
import clicn from "../../src/images/uil_calender.svg";
import coloricon from "../../src/images/cl-icon-color.svg";
import timeicon from "../../src/images/time.svg";
import useicon from "../../src/images/user-cion.svg";
import downloadicon from "../../src/images/download-icon.svg";
import startrating from "../../src/images/start-r.svg";
import editicon from "../../src/images/edit-icon.svg";
import filtericon from "../../src/images/filter-icon.svg";
import fileicon from "../../src/images/file.svg";
import roketicon from "../../src/images/rocket-icon.svg";
import flagicon from "../../src/images/flag-icon.svg";
import chckicon from "../../src/images/check-icon.svg";
import subicon from "../../src/images/file-ck.svg";
import BookingTable from "../components/BookingTable";
import BookingModal from "../components/BookingModal";
import roundck from "../../src/images/round-check.svg";
import specialicon from "../../src/images/sp-icons.svg";
import studentuser from "../../src/images/student_img.jpg";
import arrowright from "../../src/images/button-arrowright.svg";
import usercourse from "../../src/images/User-course.svg";
import trophy from "../../src/images/Trophy.svg";
import playcircle from "../../src/images/play-circle.svg";
import instructor from "../../src/images/instructor-img.jpg";
import playcirclec from "../../src/images/PlayCircle-c.svg";
import checksquare from "../../src/images/CheckSquare.svg";
import studentCourse1 from "../../src/images/studentCourse1.jpg";
import studentCourse2 from "../../src/images/studentCourse2.jpg";
import studentCourse3 from "../../src/images/studentCourse3.jpg";
import studentCourse4 from "../../src/images/studentCourse4.jpg";
import usersgroup from "../../src/images/users-group.svg";
import pRight from "../../src/images/p-ArrowRight.svg";
import pLeft from "../../src/images/p-ArrowLeft.svg";
import NewRevinew from "../components/NewRevinew";
import ins1 from "../../src/images/ins1.jpg";
import ins2 from "../../src/images/ins2.jpg";
import ins3 from "../../src/images/ins3.jpg";
import ins4 from "../../src/images/ins4.jpg";
import ins5 from "../../src/images/ins5.jpg";
import ins6 from "../../src/images/ins6.jpg";
import ins7 from "../../src/images/ins7.jpg";
import ins8 from "../../src/images/ins8.jpg";
import ins9 from "../../src/images/ins9.jpg";
import ins10 from "../../src/images/ins10.jpg";
import ins11 from "../../src/images/ins11.jpg";
import ins12 from "../../src/images/ins12.jpg";
import crown from "../../src/images/Crown.svg";
import globe from "../../src/images/globe-icon.svg";
import facebook from "../../src/images/facebook.svg";
import twitter from "../../src/images/twitter.svg";
import envelope from "../../src/images/Envelope.svg";
import whatsup from "../../src/images/whatsup.svg";
import inc1 from "../../src/images/in-c1.jpg";
import inc2 from "../../src/images/in-c2.jpg";
import inc3 from "../../src/images/in-c3.jpg";
import inc4 from "../../src/images/in-c4.jpg";
import inc5 from "../../src/images/in-c5.jpg";
import inc6 from "../../src/images/in-c6.jpg";
import inc7 from "../../src/images/in-c7.jpg";
import spinner from '../../src/images/Spinner.svg';
import instagram from '../../src/images/instagram.svg';

import pencilline from "../../src/images/PencilLine.svg";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "../components/MobileMenu";
//import Sildebar from "../Sildebar";
import TopMenu from"../components/TopMenu";
import FilerDashboard from "../components/FilerDashboard";
import CalenderMultiple from "../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../components/LabDashboradFilter";
import Newgrphadd from "../../src/images/customer-graph.svg";
import StudentSidebar from "./components/StudentSidebar";
import $ from "jquery";

class StudentinstructorProfile extends React.Component {
   componentWillMount(){

      $(document).ready(function(){
        $(".togle-icon").click(function(e) {
            e.preventDefault();
            $("#navAccordion").toggleClass("active");
            $("main").toggleClass("full-width");
            $("footer").toggleClass("full-width");
      
      });
      });
      
    }
   render() {
     return(
        <>
        <div className='page-wrapper chiller-theme toggled'>
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
             <StudentSidebar/>
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
          

            <div className="d-none d-lg-block">
                  <div className="top-bar-menu shadow float-start">
                     <a className="tog-btn me-2 togle-icon">
                     <FaBars/>
                     </a>
                     <div className="d-flex me-auto  align-items-center">
                        <h5 className="mb-0 crm-newadd"> Student ID: <span>  NS3608320 </span>  </h5>
                        <h5 className="mb-0 ms-3 crm-newadd"> Student Name:<span> Navdeep Singh </span>  </h5>
                     </div>
                     <TopMenu/>
                  </div>
            </div>
             
            <div className="dasb-div bk-div float-start w-100">

<div className="student_wrapper ">
    
     <div className="student-top-head col-lg-12">
         <div className="row align-items-center">
            <div className="col-lg-8">
                  <div className="profile-wrap course-instructor instructor_profile">
                     <div className="profile-left">
                        <div className="profile-thumb">
                           <img src={instructor} alt="instructor"/>
                        </div>
                        <div className="profile-data">
                        <h3>Vako Shvili<span className="top_rated"> <img src={crown} alt="crown"/> Top Rated</span> </h3>
                           <p>Web Designer &amp; Best-Selling Instructor</p>
                              <div className="profile-review">
                                 <ul>
                                    <li><span className="star-icon">  <FaStar /></span>   <span>4.8</span>  (134,633 review)    </li>
                                    <li> <img src={usercourse} alt="usercourse"/><span>430,117</span> Students </li>
                                    <li><img src={playcircle} alt="playcircle"/><span>7</span>courses</li>
                                 </ul>
                              </div>
                        </div>
                     </div>
            </div></div>
         <div className="col-lg-4 text-right d-flex justify-content-end"> 
         
         <div className="share_list profile-share">
         <div className="website-link">
         <a href=""> <img src={globe} alt="globe"/> https://www.vakoshvili.com</a>       
         </div>
         <div className="instructor-share">         
             <a className=" share-link"><img src={facebook} alt="facebook"/></a>
               <a className=" share-link"><img src={twitter} alt="twitter"/></a>
               <a className="share-link"><img src={instagram} alt="instagram"/></a>
               <a className=" share-link"><img src={envelope} alt="envelope"/></a>
                  <a className="share-link"><img src={whatsup} alt="whatsup"/></a>
               </div>
               </div>
         </div>
      </div>
    </div>
  <div className="instructor_detail row">
      <div className="col-lg-4 about_istructor"> 
      <div className="instructor-about-content">
            <h4>About Me</h4>
            <p>One day Vako had enough with the 9-to-5 grind, or more like 9-to-9 in his case, and quit his job, or more like got himself fired from his own startup.</p>
            <p>He decided to work on his dream: be his own boss, travel the world, only do the work he enjoyed, and make a lot more money in the process. No more begging for vacation days and living from paycheck to paycheck. After trying everything from e-commerce stores to professional poker his lucky break came when he started freelance design. Vako fell in love with the field that gives him the lifestyle of his dreams.</p>
            <p>Vako realizes that people who take courses on Udemy want to transform their lives. Today with his courses and mentoring Vako is helping thousands of people transform their lives, just like he did once.</p>
          </div>
      </div>
      <div className="col-lg-8 about_information"> 
         <ul className="nav nav-tabs" role="tablist">
         <li className="nav-item" role="presentation">
            <a className="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="true">Courses</a>
         </li>
         <li className="nav-item" role="presentation">
            <a className="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="false">Review</a>
         </li>
    
         </ul>
         <div className="tab-content pt-5" id="tab-content">
         <div className="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
            <div className="course-single-title"><h3>Vako Courses (02)</h3></div>
            <div className="instructor_courses row">
            <div className="col-lg-6">
                           <div className="best-course-card">
                              <div className="course-image"><img src={inc1} alt="inc1"/></div>
                              <div className="course-body">
                                    <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                                    <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                              </div>
                                 <div className="course-footer">
                                 <span className="corse-rating"> <FaStar /> 5.0</span>
                                 <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                                 </div>
                           </div> 
                        </div>
                  <div className="col-lg-6">
                     <div className="best-course-card">
                     <div className="course-image"><img src={inc2} alt="inc2"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"> <FaStar /> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-lg-6">
                     <div className="best-course-card">
                     <div className="course-image"><img src={inc3} alt="inc3"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"> <FaStar /> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-lg-6">
                     <div className="best-course-card">
                     <div className="course-image"><img src={inc4} alt="inc4"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"> <FaStar /> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-lg-6">
                     <div className="best-course-card">
                     <div className="course-image"><img src={inc5} alt="inc5"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"> <FaStar /> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-lg-6">
                     <div className="best-course-card">
                     <div className="course-image"><img src={inc5} alt="inc5"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"> <FaStar /> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-lg-6">
                     <div className="best-course-card">
                     <div className="course-image"><img src={inc7} alt="inc7"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"> <FaStar /> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
             </div>
             
             <div id="review" className="student-feedback-section">
                           <div className="course-single-title">
                              <h3>Students Feedback</h3>
                           
                                    <select className="form-select select-rating" aria-label="Default select example">
                                    <option selected>5 Star Rating</option>
                                    <option value="1">4 Star Rating</option>
                                    <option value="2">3 Star Rating</option>
                                    <option value="3">2 Star Rating</option>
                                       <option value="3">1 Star Rating</option>
                                    </select>
                           
                           </div>
                           <div className="review-wrap">
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Guy Hawkins <span> 1 week ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>I appreciate the precise short videos (10 mins or less each) because overly long videos tend to make me
                                    lose focus. The instructor is very knowledgeable in Web Design and it shows as he shares his knowledge.
                                    These were my best 6 months of training. Thanks, Vako.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Dianne Russell <span> 51 mins ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>This course is just amazing! has great course content, the best practices, and a lot of real-world knowledge. I love the way of giving examples, the best tips by the instructor which are pretty interesting, fun and knowledgable and I was never getting bored throughout the course. This course meets more than my expectation and, I made the best investment of time to learn and practice what I am passionate about. Thank you so much to our excellent instructor Vako!! Highly recommend this course! Take the next step.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Bessie Cooper <span> 6 hours ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>Webflow course was good, it coves design secrtes, and to build responsive web pages, blog, and some more tricks and tips about webflow. I enjoyed the course and it helped me to add web development skills related to webflow in my toolbox. Thank you Vako.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Eleanor Pena <span> 1 days ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>I appreciate the precise short videos (10 mins or less each) because overly long videos tend to make me lose focus. The instructor is very knowledgeable in Web Design and it shows as he shares his knowledge. These were my best 6 months of training. Thanks, Vako.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Ralph Edwards <span> 2 days ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>GREAT Course! Instructor was very descriptive and professional. I learned a TON that is going to apply immediately to real life work. Thanks so much, cant wait for the next one!
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Arlene McCoy <span> 1 week ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>This should be one of the best course I ever made about UXUI in Udemy. Highly recommend to those who is new to UXUI and want to become UXUI freelancer!
                                 </p>
                                 </div>
                              </div>
                           </div>

                           <a className="button loadmore-btn" >Load more
                              <span><img src={spinner} alt="spinner"/>
                                             
                                             </span>
                           </a>
                           </div>

         
         </div>
         <div className="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
              <div className="course-ratting-section">
                              <div className="course-single-title">
                                 <h3>Course Reting</h3>
                              </div>
                              <div className="rating-area">
                                 <div className="rating-box">
                                    <h4>4.8</h4>
                                    <ul className="total-star list-inline">
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                       <span className="star-icon"> <FaStarHalfAlt /> </span>

                                    </li>
                                    </ul>
                                    <p>Course Rating</p>
                                 </div>
                                 <div className="rating-percentage">
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       5 Star Rating
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage four"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>75%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       4 Star Rating
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage four"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>21%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       3 Star Rating
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage three"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>3%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       2 Star Rating
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage four"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>2%</span>
                                    </div>
                                    </div>
                                    <div className="percentage-line">
                                    <ul className="total-star total-star-sm list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                       </li>
                                    </ul>
                                    <div className="rating">
                                       1 Star Rating
                                    </div>
                                    <div className="rating-area-percentage">
                                       <div className="main-percentage-bg"></div>
                                       <div className="main-percentage one"></div>
                                    </div>
                                    <div className="percentage-text">
                                       <span>2%</span>
                                    </div>
                                    </div>
                                 </div>
                              </div>
                </div>

         
         </div>
       
         </div>
      </div>
  </div>

     
  </div>
</div>
          </div>
        </main>
       </div>

       
        <div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog modal-lg">
            <div className="modal-content">
               <div className="modal-header pb-0">
                  
               <ul className="nav nav-pills" id="pills-tab" role="tablist">
                     <li className="nav-item" role="presentation">
                     <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Paitent Details</button>
                     </li>
                     <li className="nav-item" role="presentation">
                     <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
                     </li>
                     
               </ul>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body">

                  <div className="tabs-settings">
                     
                     <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              
                              <div className="patinent-details">
                                 <div className="inside-history-div col-lg-12 mx-auto mt-3">
                                    <h6 className="mn-head"> 10 Results Found </h6> 
                                    <div className="result-div mt-3">
                                       <div className="cm-history mx-auto row">
                                          <div className="col-lg-10">
                                             <div className="left-history">
                                                <h5 className="d-lg-flex"> Veena Rao  </h5>
                                                <div className="same-details">
                                                   <span> 30 Yrs, Female </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                   <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                             <div className="left-history mt-5">
                                                <h6 className="nw-add"> Beneficiary </h6>
                                                <h5> Jaswant Thakur </h5>
                                                <div className="same-details">
                                                   <span> 45 Yrs, Male </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                          </div>
                                          <div className="col-lg-2">
                                             <div className="right-sec d-grid justify-content-lg-end">
                                                <h5> Rs. 1500/- </h5>
                                                <h6 className="ps-st">
                                                   <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                                </h6>
                                                <figure className="mb-0">
                                                   <img src={startrating} alt="ck"/>
                                                </figure>
                                                <p className="text-center"> Satisfied </p>
                                             </div>
                                             
                                          </div>
                                       </div>



                                    </div>
                           
                                 </div>

                                 <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                                       <div className="row row-cols-7 justify-content-center">
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={fileicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Accepted 
                                                   <span className="d-block">
                                                   (09:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={roketicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Started
                                                   <span className="d-block">
                                                   (10:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm ">
                                                   <img src={flagicon} alt="file"/>
                                             </figure>
                                             <h5>
                                              Reached
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={specialicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Collected
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={subicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Submitted
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       </div>
                                 </div>
                              </div>

                              
                        
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        
                              <div className="inside-history-div col-lg-11 ms-auto mt-3">
                                 <h6 className="mn-head"> 10 Results Found </h6> 
                                 <div className="result-div mt-3">
                                    <div className="cm-history row">
                                       <div className="col-lg-11">
                                          <div className="left-history">
                                             <h5> Veena Rao
                                             </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span > <FaCircle/> 25-02-2022 </span>
                                                <span > <FaCircle/>  #ORD6848r4 </span>
                                                <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          <div className="left-history mt-5">
                                             <h6 className="nw-add"> Beneficiary </h6>
                                             <h5> Jaswant Thakur </h5>
                                             <div className="same-details">
                                                <span> 45 Yrs, Male </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                       </div>
                                       <div className="col-lg-1">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1500/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 500/- </h5>
                                             <h6 className="unps-st">
                                                Unpaid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 2000/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1200/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>


                                 </div>
                                 
                              </div>

                        </div>
                        
                     </div>
                  </div>






                  



               </div>
               
            </div>
         </div>
        </div>
       
        <MobileMenu/>


       <LabDashboradFilter/>

        <div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <CalenderMultiple/>
                  </div>
                  
               </div>
            </div>
        </div>
        </>
     );
   }
 }

export default StudentinstructorProfile;