import React, { useState } from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../../components/BookingCtWeek";
import BookingCtMonth from "../../components/BookingCtMonth";
import RevieCtWeek from "../../components/RevieCtWeek";
import RevieCtMonth from "../../components/RevieCtMonth";
import CustomerCtweek from "../../components/CustomerCtweek";
import CustomerCtMonth from "../../components/CustomerCtMonth";
import dsicn from "../../../src/images/ds-icon.png";
import clicn from "../../../src/images/uil_calender.svg";
import coloricon from "../../../src/images/cl-icon-color.svg";
import timeicon from "../../../src/images/time.svg";
import useicon from "../../../src/images/user-cion.svg";
import downloadicon from "../../../src/images/download-icon.svg";
import startrating from "../../../src/images/start-r.svg";
import editicon from "../../../src/images/edit-icon.svg";
import filtericon from "../../../src/images/filter-icon.svg";
import fileicon from "../../../src/images/file.svg";
import roketicon from "../../../src/images/rocket-icon.svg";
import flagicon from "../../../src/images/flag-icon.svg";
import chckicon from "../../../src/images/check-icon.svg";
import subicon from "../../../src/images/file-ck.svg";
import BookingTable from "../../components/BookingTable";
import BookingModal from "../../components/BookingModal";
import roundck from "../../../src/images/round-check.svg";
import specialicon from "../../../src/images/sp-icons.svg";
import NewRevinew from "../../components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope } from "react-icons/fa";
import MobileMenu from "../../components/MobileMenu";
import TopMenu from"../../components/TopMenu";
import FilerDashboard from "../../components/FilerDashboard";
import CalenderMultiple from "../../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../../components/LabDashboradFilter";
import Newgrphadd from "../../../src/images/customer-graph.svg";
import $ from "jquery";
import logo from "../../../src/images/lms_logo_new.png";
import logosmall from "../../../src/images/small-logo-new.svg";
import searchicon from "../../../src//images/search-icon.svg";
import dashboardicon from "../../../src/images/dashboard-icon.svg";
import courseicon from "../../../src/images/course-icon.svg";
import projecticon from "../../../src/images/project-icon.svg";
import eventicon from "../../../src/images/event-icon.svg";
import videoicon from "../../../src/images/video-icon.svg";
import messageicon from "../../../src/images/message-icon.svg";
import teamicon from "../../../src/images/team-icon.svg";
import outreachicon from "../../../src/images/outreach-icon.svg";
import updateicon from "../../../src//images/update-icon.svg";
import orginationicon from "../../../src/images/orgination-icon.svg";
import usericon from "../../../src//images/user-icon.svg";
import settingicon from "../../../src/images/setting-icon.svg";
import logouticon from "../../../src/images/logout-icon.svg";
import bookingicon from "../../../src//images/icon2.svg";

function InstructorSidebar(){
  const [showTeamsSubmenu, setShowTeamsSubmenu] = useState(false);
    const [showMessagesSubmenu, setShowMessagesSubmenu] = useState(false);

    const toggleSubMenu = (menu) => {
        if (menu === "teams") {
            setShowTeamsSubmenu(!showTeamsSubmenu);
        } else if (menu === "messages") {
            setShowMessagesSubmenu(!showMessagesSubmenu);
        }
    };

    return(
        <>
        <div className="inside0div">
            <div className="logo-admin">
              <NavLink to="/" >
                 <img src={logo} className="big-logo" alt="logo"/>
                 <img src={logosmall} className="closap-logo" alt="logosm"/>
              </NavLink>
              <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample"
               className="d-block d-lg-none bar-icon1">  
                 <FaBars/> 
              </a>
            </div>
            <div className="search-form-side"><div className="input-icon">
    <input type="text" className="form-control" placeholder="Search Here"/>
    
    <button type="submit"><img src={searchicon} className="serch-icn" alt="serch-icn"/></button>
		</div></div>

            <div className="main-menu d-none d-lg-block">
               <ul className="list-unstyled">
                 <li>
                   <NavLink to="/dashbord" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={dashboardicon} alt="dashboardicon"/> </span> <span className="menu-text"> Dashboard </span>  </NavLink>
                   <NavLink to="/courses" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={courseicon} alt="courseicon"/> </span> <span className="menu-text"> Courses </span> </NavLink>
                   <NavLink to="/projects" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={projecticon} alt="projecticon"/> </span> <span className="menu-text"> Projects </span> </NavLink>
                   <NavLink to="/events" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={eventicon} alt="eventicon"/> </span> <span className="menu-text"> Events </span> </NavLink>
                   <NavLink to="/meetings" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={videoicon} alt="videoicon"/> </span> <span className="menu-text"> Meetings </span>  </NavLink>
                   {/*<NavLink to="/messages" activeClassName="menu_active"> <span className="menu-icon-new dropdown"> <img src={messageicon} alt="messageicon"/> </span> <span className="menu-text"> Messages </span>  </NavLink>*/}
                   {/* Messages link with submenu */}
                   <div onClick={() => toggleSubMenu("messages")}>
                                <NavLink to="/messages" activeClassName="menu_active">
                                    <span className="menu-icon-new"><img src={messageicon} alt="messageicon"/></span>
                                    <span className="menu-text">Messages</span>
                                </NavLink>
                                {showMessagesSubmenu && (
                                    <ul className="submenu">
                                        <li><NavLink to="/messages/inbox">Inbox</NavLink></li>
                                        <li><NavLink to="/messages/sent">Sent</NavLink></li>
                                    </ul>
                                )}
                            </div>
                   <NavLink to="/teams" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={teamicon} alt="teamicon"/> </span> <span className="menu-text"> Teams </span>  </NavLink>
                   <NavLink to="/outreach" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={outreachicon} alt="outreachicon"/> </span> <span className="menu-text"> Outreach </span> </NavLink>
				   <NavLink to="/updates" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={updateicon} alt="updateicon"/> </span> <span className="menu-text"> Updates </span> </NavLink>
                   <NavLink to="/organization" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={orginationicon} alt="orginationicon"/> </span> <span className="menu-text"> Organization </span> </NavLink>
				   <NavLink to="/account" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={usericon} alt="usericon"/> </span> <span className="menu-text"> Account </span> </NavLink>
				   <NavLink to="/settings" activeClassName="menu_active"> <span className="menu-icon-new"> <img src={settingicon} alt="settingicon"/> </span> <span className="menu-text"> Settings </span> </NavLink>
                   
                 </li>
               </ul>
               <div className="logout_block">
            <a className="logout-link">
            <img src={logouticon} alt="logouticon"/>Logout
               </a>
            </div>
            </div>
            
        </div>
        </>
    );
}
export default InstructorSidebar;