import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "./components/BookingCtWeek";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import myJavaScriptFile from './myJavaScriptFile.js';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';
import user3 from '../src/images/user3.png';
import trailer from '../src/images/Trailer.jpg';
import spinner from '../src/images/Spinner.svg';

import filters from '../src/images/filters-icon.svg';
import search from '../src/images/search-icon.svg';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';
import passport from '../src/images/passport-image.jpg';
import CoursesTable from "./components/CoursesTable"; 
import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";
import cpu from "../src/images/cpu.svg";
import handshake from "../src/images/Handshake.svg";
import crditCard from "../src/images/CreditCard.svg";
import chartBar from "../src/images/ChartBarHorizontal.svg";
import bugdroid from "../src/images/bugdroid.svg";
import receipt from "../src/images/Receipt.svg";
import megaphone from "../src/images/MegaphoneSimple.svg";
import camerac from "../src/images/Camerac.svg";
import packagec from "../src/images/Package.svg";
import PenNib from "../src/images/PenNib.svg";
import firstaid from "../src/images/FirstAidKit.svg";
import headphones from "../src/images/Headphones.svg";
import arrowright from "../src/images/button-arrowright.svg";
import courseimages from "../src/images/course-images.jpg";
import featureimages from "../src/images/feature-course.jpg";
import usercourse from "../src/images/User-course.svg";
import barchart from "../src/images/bar-chart.svg";
import clock from "../src/images/Clock.svg";
import star from "../src/images/star.svg";
import alarm from "../src/images/Alarm.svg";
import notepad from "../src/images/Notepad.svg";
import notebook from "../src/images/Notebook.svg";
import studentsen from "../src/images/Students-Enrolled.svg";
import Stack from "../src/images/Stack.svg";
import trophy from "../src/images/Trophy.svg";
import currencydollar from "../src/images/CurrencyDollar.svg";
import monitor from "../src/images/Monitor.svg";
import copy from "../src/images/Copy.svg";
import facebook from "../src/images/facebook.svg";
import twitter from "../src/images/twitter.svg";
import envelope from "../src/images/Envelope.svg";
import whatsup from "../src/images/whatsup.svg";
import playcircle from "../src/images/play-circle.svg";
import foldericon from "../src/images/folder-icon.svg";
import playicon from "../src/images/play-icon.svg";
import fileicons from "../src/images/file-icon.svg";
import clockicon from "../src/images/Clock-icon.svg";
import keven from "../src/images/keven.png";
import instructor from "../src/images/instructor-img.jpg";
import pRight from "../src/images/p-ArrowRight.svg";
import pLeft from "../src/images/p-ArrowLeft.svg";
import netflix from "../src/images/netflix.svg";
import youtube from "../src/images/youtube.svg";
import google from "../src/images/google.svg";
import lenovo from "../src/images/lenovo.svg";
import slack from "../src/images/slack.svg";
import lexmark from "../src/images/lexmark.svg";
import microsoft from "../src/images/microsoft.svg";
import verizon from "../src/images/verizon.svg";
import beinstructor from "../src/images/become-instructor.png";
import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery";

class  SingleCourse extends React.Component {
componentWillMount(){
$(document).ready(function(){
$('.accordion .accordion-head').click(function () {
                  if ($(this).next('div').is(':visible')) {
                    $(this).next('div').slideUp('normal');
                    $(this).find('i').removeClass('arrow-open').addClass('arrow-close');
                  } else if ($(this).next('div').is(':hidden')) {
                    $(this).next('div').slideDown('normal');
                    $(this).find('i').removeClass('arrow-close').addClass('arrow-open');
                  } else {
                    $('.accordion-body').slideUp('normal');
                    $(this).next('div').slideToggle('normal');
                    $(this).find('i').removeClass('arrow-open').addClass('arrow-close');
                  }
                }); //this month form
});
}
render() {
return(
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
           <div className="single-course-wrapper">
               <div className="single-course-block row">
                  <div className="col-lg-8 single-course-left">
                     <nav aria-label="breadcrumb">
                     <ol className="breadcrumb">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item">Development</li>
                        <li className="breadcrumb-item">Web Development</li>    
                        <li className="breadcrumb-item active" aria-current="page">Webflow</li>
                     </ol>
                     </nav>
                     <div className="single-course-content">
                                 <h2>Complete Website Responsive Design: from Figma to Webflow to Website Design</h2>
                                 <p>3 in 1 Course: Learn to design websites with Figma, build with Webflow, and make a living freelancing.</p>
                                 <div className="row course-author">
                                    <div className="col-lg-7 course-author-content">
                                       <div className="author-image"><span className="img1"><img src={user2} alt="user2"/></span><span className="user3"><img src={user3} alt="user3"/></span></div>
                                       <div className="author-name">
                                       <p>Created by:</p>
                                       <div className="author_ct-name"><a>Dianne Russell</a> • <a>Kristin Watson</a></div>
                                       </div>
                                    </div>
                                    <div className="col-lg-5 course-author-rating">
                                       <div className="rating-content"> 
                                       <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                          <span className="rating_value">4.8</span>
                                          <span className="rating_view"> (451,444 Rating)</span>
                                       </div>
                                    </div>
                                 </div>
                        <div className="course-featureed-image">
                        <img src={trailer} alt="trailer"/>
                        </div> 
                        <div className="student-profile-menu single-course">
                                 <ul>
                                    <li><a className="active" href="#overview">Overview</a></li>
                                    <li><a href="#curriculum">Curriculum</a></li>
                                    <li><a href="#instructor">Instructor</a></li>
                                    <li><a href="#review">Review</a></li>
                                 </ul>
                        </div>
               
                           <div id="overview" className="overview-section">
                           <div className="overview-description">
                              <div className="course-single-title">
                                 <h3>Description</h3>
                              </div>
                              
                              
                              
                                 <p>It gives you a huge self-satisfaction when you look at your work and say, "I made this!". 
                                    I love that feeling after I'm done working on something. When I lean back in my chair, look 
                                    at the final result with a smile, and have this little "spark joy" moment. It's especially satisfying when I know I just made $5,000. </p>
                                    <p>       I do! And that's why I got into this field. Not for the love of Web Design, which I do now. But for the LIFESTYLE! There are many ways one can 
                                       achieve this lifestyle. This is my way. This is how I achieved a lifestyle I've been fantasizing about for five years. And I'm going to teach 
                                       you the same. Often people think Web Design is complicated. That it needs some creative talent or knack for computers. Sure, a lot of people
                                       make it very complicated. People make the simplest things complicated. Like most subjects taught in the universities. But I don't like complicated.
                                       I like easy. I like life hacks. I like to take the shortest and simplest route to my destination. I haven't gone to an art school or have a computer
                                          science degree. I'm an outsider to this field who hacked himself into it, somehow ending up being a sought-after professional. That's how I'm going 
                                          to teach you Web Design. So you're not demotivated on your way with needless complexity. So you enjoy the process because it's simple and fun. 
                                          So you can become a Freelance Web Designer in no time. </p>
                                          <p>
                                 For example, this is a Design course but I don't teach you Photoshop. Because Photoshop is needlessly complicated for Web Design. But people still
                                 teach it to web designers. I don't. I teach Figma – a simple tool that is taking over the design world. You will be designing a complete website within 
                                 a week while others are still learning how to create basic layouts in Photoshop. </p>
                                 <p>
                                 Second, this is a Development course. But I don't teach you how to code. Because for Web Design coding is needlessly complicated and takes too
                                 long to learn. Instead, I teach Webflow – a tool that is taking over the web design world. You will be building complex websites within two weeks
                                 while others are still learning the basics of HTML & CSS. Third, this is a Freelancing course. But I don't just teach you how to write great proposals.
                                    I give you a winning proposal template. When you're done with the course, you will have a stunning portfolio website with portfolio pieces already in it.
                                    Buy this course now and take it whenever the time is right for you.</p>


                           <div className="course-learn-section">
                              <div className="course-single-title">
                                 <h3>What you will learn in this course</h3>
                              </div>
                              <div className="row">
                                 <div className="col-lg-6">
                                 <div className="checkbox-field">
                                    <ul>
                                       <li>
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                       You will learn how to design beautiful websites using Figma, an interface design tool used by designers at Uber, Airbnb and Microsoft.
                                       </li>
                                       <li>
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                       You will learn secret tips of Freelance Web Designers and how they make great money freelancing online.
                                       </li>
                                       <li>
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                       Understand how to use both the Jupyter Notebook and create .py files
                                       </li>
                                    </ul>
                                 </div>
                                 </div>
                                 <div className="col-lg-6">
                                 <div className="checkbox-field">
                                    <ul>
                                       <li>
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                       You will learn how to take your designs and build them into powerful websites using Webflow, a state of the art site builder used by teams at Dell, NASA and more.
                                       </li>
                                       <li>
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                       Learn to use Python professionally, learning both Python 2 and Python 3!
                                       </li>
                                       <li>
                                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#23BD33" stroke="#23BD33" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                          <path d="M16.125 9.75L10.625 15L7.875 12.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                       </svg>
                                       Get an understanding of how to create GUIs in the Jupyter Notebook system!
                                       </li>
                                    </ul>
                                 </div>
                                 </div>
                              </div>
                           
                           
                           
                           
                           
                           </div>

                           <div className="course-list-section">
                              <div className="course-single-title">
                                 <h3>Who this course is for:</h3>
                              </div>
                              <ul>
                                 <li>
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 This course is for those who want to launch a Freelance Web Design career.
                                 </li>
                                 <li>
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 Praesent eget consequat elit. Duis a pretium purus.
                                 </li>
                                 <li>
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 Sed sagittis suscipit condimentum pellentesque vulputate feugiat libero nec accumsan.
                                 </li>
                                 <li>
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 Sed nec dapibus orci integer nisl turpis, eleifend sit amet aliquam vel, lacinia quis ex.
                                 </li>
                                 <li>
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 Those who are looking to reboot their work life and try a new profession that is fun, rewarding and highly in-demand.
                                 </li>
                                 <li>
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 Nunc auctor consequat lorem, in posuere enim hendrerit sed.
                                 </li>
                                 <li>
                                 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 12H20.25" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="#FF6636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                 </svg>
                                 Duis ornare enim ullamcorper congue consectetur suspendisse interdum tristique est sed molestie.
                                 </li>
                              </ul>
                           </div>
                           <div className="course-list-section course-requirements">
                           <div className="course-single-title">
                              <h3>Course requirements</h3>
                           </div>
                           <ul>
                              <li>Nunc auctor consequat lorem, in posuere enim hendrerit sed.</li>
                              <li>Sed sagittis suscipit condimentum pellentesque vulputate feugiat libero nec accumsan.</li>
                              <li>Duis ornare enim ullamcorper congue consectetur suspendisse interdum tristique est sed molestie.</li>
                              <li>Those who are looking to reboot their work life and try a new profession that is fun, rewarding and highly in-demand.</li>
                              <li>Praesent eget consequat elit. Duis a pretium purus.</li>
                              <li>Sed nec dapibus orci integer nisl turpis, eleifend sit amet aliquam vel, lacinia quis ex.</li>
                              <li>This course is for those who want to launch a Freelance Web Design career.</li>
                           </ul>
                           </div>

                           </div>
                                 
                           </div>
                           <div id="curriculum" className="course-curriculum-section">
                           <div className="course-single-title">
                              <h3>Curriculum</h3>
                              <div className="sub-heading-meta">
                                 <ul>
                                 <li>
                                 <img src={foldericon} alt="foldericon"/>
                                    6 Sections
                                 </li>
                                 <li>
                                 <img src={playcircle} alt="playcircle"/>
                                    52 lectures
                                 </li>
                                 <li>
                                 <img src={clock} alt="clock"/>
                                    5m 49m
                                 </li>
                                 </ul>
                              </div>
                           </div>
                           <div className="accordion accordion-wrap-faq lecture-wrap" id="accordionExample">
                                 <div className="accordion-item accordion__list2">
                                    <h2 className="accordion-header " id="headingOne">
                                       <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       <div className="linkwrap"><div className="accordion-header-content">
                                                      
                                                      Getting Started
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div> </div>
                                       </button>  
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                       <div className="accordion-body accordion-body-text">
                                       
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         What’s is Webflow?
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={playicon} alt="playicon"/>
                                                         Sign up in Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         Teaser of Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header  " id="headingTwo">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       <div className="linkwrap"> <div className="accordion-header-content">
                                                      
                                             Secret of Good Design
                                    </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div> </div>
                                       </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                       <div className="accordion-body accordion-body-text">
                                       
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         What’s is Webflow?
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={playicon} alt="playicon"/>
                                                         Sign up in Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         Teaser of Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingThree">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                       <div className="linkwrap">  <div className="accordion-header-content">
                                                      
                                    Practice Design Like an Artist
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div>  </div>
                                       </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                       <div className="accordion-body accordion-body-text">
                                       
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         What’s is Webflow?
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={playicon} alt="playicon"/>
                                                         Sign up in Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         Teaser of Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingfour">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                       <div className="linkwrap"> <div className="accordion-header-content">
                                                      
                                                      Web Development (webflow)
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div></div>
                                       </button>
                                    </h2>
                                    <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                       <div className="accordion-body accordion-body-text">
                                       
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         What’s is Webflow?
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={playicon} alt="playicon"/>
                                                         Sign up in Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         Teaser of Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingfive">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                       <div className="linkwrap">   <div className="accordion-header-content">
                                                      
                                                      Secrets of Making Money Freelancing
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div>   </div>
                                       </button>
                                    </h2>
                                    <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                                       <div className="accordion-body accordion-body-text">
                                       
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         What’s is Webflow?
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={playicon} alt="playicon"/>
                                                         Sign up in Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         Teaser of Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
                              <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingsix">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                       <div className="linkwrap">  <div className="accordion-header-content">
                                                      
                                                      Advanced
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div>
                                                   </div>
                                       </button>
                                    </h2>
                                    <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                       <div className="accordion-body accordion-body-text">
                                       
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         What’s is Webflow?
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={playicon} alt="playicon"/>
                                                         Sign up in Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                         <img src={playicon} alt="playicon"/>
                                                         Teaser of Webflow
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         07:31
                                                      </div>
                                                   </div>
                                                   <div className="lectue-body-item">
                                                      <div className="curriculum-body-left">
                                                      <img src={fileicons} alt="playicon"/>
                                                         Webflow Terms &amp; Conditions
                                                      </div>
                                                      <div className="curriculum-body-right">
                                                         5.3 MB
                                                      </div>
                                                   </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
               
                              </div>
                           </div>
                     
                           <div id="instructor" className="course-instructor-section">
                           <div className="course-single-title">
                              <h3>Course instructor (02)</h3>
                           </div>
                           <div className="profile-wrap course-instructor">
                              <div className="profile-left">
                                 <div className="profile-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="profile-data">
                                 <h3>
                                    Vako Shvili
                                 </h3>
                                 <p>Web Designer &amp; Best-Selling Instructor</p>
                                 <div className="profile-review">
                                    <ul>
                                       <li>
                                       <span className="star-icon">  <FaStar /></span>
                                       <span>4.8</span>
                                       (134,633 review)
                                       </li>
                                       <li>
                                       <img src={usercourse} alt="usercourse"/> 
                                       <span>430,117</span>
                                       students
                                       </li>
                                       <li>
                                       <img src={playcircle} alt="playcircle"/>
                                       <span>7</span>
                                       courses
                                       </li>
                                    </ul>
                                 </div>
                                 <p>One day Vako had enough with the 9-to-5 grind, or more like 9-to-9 in his case, and quit his job, or more like got himself fired from his own startup. He decided to work on his dream: be his own boss, travel the world, only do the work he enjoyed...a <a href="#">Read more</a></p>
                                 </div>

                              </div>
                           </div>
                           <div className="profile-wrap course-instructor">
                              <div className="profile-left">
                                 <div className="profile-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="profile-data">
                                 <h3>
                                    Nima Tahami
                                 </h3>
                                 <p>Entrepreneur &amp; Designer • Founder of ShiftRide</p>
                                 <div className="profile-review">
                                    <ul>
                                       <li>
                                       <span className="star-icon">  <FaStar /></span>
                                       <span>4.8</span>
                                       (134,633 review)
                                       </li>
                                       <li>
                                       <img src={usercourse} alt="usercourse"/> 
                                       <span>430,117</span>
                                       students
                                       </li>
                                       <li>
                                       <img src={playcircle} alt="playcircle"/>
                                       <span>7</span>
                                       courses
                                       </li>
                                    </ul>
                                 </div>
                                 <p>One day Vako had enough with the 9-to-5 grind, or more like 9-to-9 in his case, and quit his job, or more like got himself fired from his own startup. He decided to work on his dream: be his own boss, travel the world, only do the work he enjoyed...a <a href="#">READ MORE</a></p>
                                 </div>

                              </div>
                           </div>
                           </div>
                           <div className="course-ratting-section">
                           <div className="course-single-title">
                              <h3>Course Reting</h3>
                           </div>
                           <div className="rating-area">
                              <div className="rating-box">
                                 <h4>4.8</h4>
                                 <ul className="total-star list-inline">
                                 <li className="list-inline-item">
                                 <span className="star-icon">  <FaStar /></span>
                                 </li>
                                 <li className="list-inline-item">
                                 <span className="star-icon">  <FaStar /></span>
                                 </li>
                                 <li className="list-inline-item">
                                 <span className="star-icon">  <FaStar /></span>
                                 </li>
                                 <li className="list-inline-item">
                                 <span className="star-icon">  <FaStar /></span>
                                 </li>
                                 <li className="list-inline-item">
                                 <span className="star-icon">  <FaStar /></span>
                                 </li>
                                 <li className="list-inline-item">
                                    <span className="star-icon"> <FaStarHalfAlt /> </span>

                                 </li>
                                 </ul>
                                 <p>Course Rating</p>
                              </div>
                              <div className="rating-percentage">
                                 <div className="percentage-line">
                                 <ul className="total-star total-star-sm list-inline">
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                 </ul>
                                 <div className="rating">
                                    5 Star Rating
                                 </div>
                                 <div className="rating-area-percentage">
                                    <div className="main-percentage-bg"></div>
                                    <div className="main-percentage four"></div>
                                 </div>
                                 <div className="percentage-text">
                                    <span>75%</span>
                                 </div>
                                 </div>
                                 <div className="percentage-line">
                                 <ul className="total-star total-star-sm list-inline">
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                       <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                 </ul>
                                 <div className="rating">
                                    4 Star Rating
                                 </div>
                                 <div className="rating-area-percentage">
                                    <div className="main-percentage-bg"></div>
                                    <div className="main-percentage four"></div>
                                 </div>
                                 <div className="percentage-text">
                                    <span>21%</span>
                                 </div>
                                 </div>
                                 <div className="percentage-line">
                                 <ul className="total-star total-star-sm list-inline">
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon">  <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                 </ul>
                                 <div className="rating">
                                    3 Star Rating
                                 </div>
                                 <div className="rating-area-percentage">
                                    <div className="main-percentage-bg"></div>
                                    <div className="main-percentage three"></div>
                                 </div>
                                 <div className="percentage-text">
                                    <span>3%</span>
                                 </div>
                                 </div>
                                 <div className="percentage-line">
                                 <ul className="total-star total-star-sm list-inline">
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                 </ul>
                                 <div className="rating">
                                    2 Star Rating
                                 </div>
                                 <div className="rating-area-percentage">
                                    <div className="main-percentage-bg"></div>
                                    <div className="main-percentage four"></div>
                                 </div>
                                 <div className="percentage-text">
                                    <span>2%</span>
                                 </div>
                                 </div>
                                 <div className="percentage-line">
                                 <ul className="total-star total-star-sm list-inline">
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                    <li className="list-inline-item">
                                    <span className="star-icon"> <FaRegStar /></span>
                                    </li>
                                 </ul>
                                 <div className="rating">
                                    1 Star Rating
                                 </div>
                                 <div className="rating-area-percentage">
                                    <div className="main-percentage-bg"></div>
                                    <div className="main-percentage one"></div>
                                 </div>
                                 <div className="percentage-text">
                                    <span>2%</span>
                                 </div>
                                 </div>
                              </div>
                           </div>
                           </div>

                           <div id="review" className="student-feedback-section">
                           <div className="course-single-title">
                              <h3>Students Feedback</h3>
                           
                                    <select className="form-select select-rating" aria-label="Default select example">
                                    <option selected>5 Star Rating</option>
                                    <option value="1">4 Star Rating</option>
                                    <option value="2">3 Star Rating</option>
                                    <option value="3">2 Star Rating</option>
                                       <option value="3">1 Star Rating</option>
                                    </select>
                           
                           </div>
                           <div className="review-wrap">
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Guy Hawkins <span> 1 week ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>I appreciate the precise short videos (10 mins or less each) because overly long videos tend to make me
                                    lose focus. The instructor is very knowledgeable in Web Design and it shows as he shares his knowledge.
                                    These were my best 6 months of training. Thanks, Vako.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Dianne Russell <span> 51 mins ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>This course is just amazing! has great course content, the best practices, and a lot of real-world knowledge. I love the way of giving examples, the best tips by the instructor which are pretty interesting, fun and knowledgable and I was never getting bored throughout the course. This course meets more than my expectation and, I made the best investment of time to learn and practice what I am passionate about. Thank you so much to our excellent instructor Vako!! Highly recommend this course! Take the next step.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Bessie Cooper <span> 6 hours ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>Webflow course was good, it coves design secrtes, and to build responsive web pages, blog, and some more tricks and tips about webflow. I enjoyed the course and it helped me to add web development skills related to webflow in my toolbox. Thank you Vako.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Eleanor Pena <span> 1 days ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>I appreciate the precise short videos (10 mins or less each) because overly long videos tend to make me lose focus. The instructor is very knowledgeable in Web Design and it shows as he shares his knowledge. These were my best 6 months of training. Thanks, Vako.
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Ralph Edwards <span> 2 days ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>GREAT Course! Instructor was very descriptive and professional. I learned a TON that is going to apply immediately to real life work. Thanks so much, cant wait for the next one!
                                 </p>
                                 </div>
                              </div>
                              <div className="review-item">
                                 <div className="review-thumb">
                                 <img src={instructor} alt="instructor"/>
                                 </div>
                                 <div className="review-data">
                                 <h5>Arlene McCoy <span> 1 week ago</span></h5>
                                 <div className="review">
                                 <span className="star-icons">
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                          <FaStar />
                                       </span>
                                 </div>
                                 <p>This should be one of the best course I ever made about UXUI in Udemy. Highly recommend to those who is new to UXUI and want to become UXUI freelancer!
                                 </p>
                                 </div>
                              </div>
                           </div>

                           <a className="button loadmore-btn" >Load more
                              <span><img src={spinner} alt="spinner"/>
                                             
                                             </span>
                           </a>
                           </div>

                     </div>
                   </div>
                  <div className="col-lg-4 single-course-sidebar">
                        <div className="sidebar-block">
                           <div className="course_top">
                                 <div className="course_price">
                                    <span className="price-value">
                                    <span className="saleprice">$14.00</span> <span className="regular-price">$26.00</span>
                                    </span> 
                                    <span className="price-discount">56% off</span>
                                 </div>
                                 <div className="timer_value"><img src={alarm} alt="clock"/> 2 days left at this price!</div>
                           </div>
                              <div className="course_feature">

                                    <ul className="feature-list">
                                       <li><span className="fname-item"><img src={clock} alt="clock"/> Course Duration</span><span className="value-item">6 Month</span></li>
                                       <li><span className="fname-item"><img src={barchart} alt="barchart"/>Course Level</span><span className="value-item">Beginner and Intermediate</span></li>
                                       <li><span className="fname-item"><img src={studentsen} alt="studentsen"/>Students Enrolled</span><span className="value-item">69,419,618</span></li>
                                       <li><span className="fname-item"><img src={notebook} alt="notebook"/>Language</span><span className="value-item">Mandarin</span></li>
                                       <li><span className="fname-item"><img src={notepad} alt="notepad"/>Subtittle Language</span><span className="value-item">English</span></li>
                                    </ul>
                              </div>

                              <div className="course_buy-buttons">
                                       <div className="courbutton-group">
                                       <a className="add-cart-btn btn">Add to Cart</a>
                                       <a className="add-buy-btn btn">Buy now</a>
                                       <a className="wishlist-btn btn">Add to wishlist</a>
                                       <a className="gift-btn btn">Gift Course</a>
                                       </div>
                                    <p><strong>Note: </strong>all course have 30-days money-back guarantee</p>
                              </div>
                              <div className="course_included">
                                 <h4>This course includes:</h4>
                                 <ul className="feature-list">
                                       <li><span className="fname-item"><img src={clock} alt="clock"/> Lifetime access</span></li>
                                       <li><span className="fname-item"><img src={currencydollar} alt="currencydollar"/>30-days money-back guarantee</span></li>
                                       <li><span className="fname-item"><img src={notebook} alt="notebook"/>Free exercises file & downloadable resources</span></li>
                                       <li><span className="fname-item"><img src={trophy} alt="trophy"/>Shareable certificate of completion</span></li>
                                       <li><span className="fname-item"><img src={monitor} alt="monitor"/>Access on mobile , tablet and TV</span></li>
                                       <li><span className="fname-item"><img src={notepad} alt="notepad"/>English subtitles</span></li>
                                       <li><span className="fname-item"><img src={Stack} alt="Stack"/>100% online course</span></li>
                                 
                                    </ul>
                              </div>
                              <div className="course_share_inco">
                                 <h4>Share this course:</h4>
                                 <div className="share_list">
                                 <a className="copy-link share-link"><img src={copy} alt="copy"/> Copy link</a>
                                 <a className=" share-link"><img src={facebook} alt="facebook"/></a>
                                 <a className=" share-link"><img src={twitter} alt="twitter"/></a>
                                 <a className=" share-link"><img src={envelope} alt="envelope"/></a>
                                 <a className="share-link"><img src={whatsup} alt="whatsup"/></a>
                                 </div>
                              </div>
                        </div>

                  </div>
             </div> 
            </div>
<div className="related-course-section ">
   <div className="related-course-head row">
   <div className="col-lg-6"><h2>Related Courses</h2></div>
   <div className="col-lg-6 text-right">
   <a className="brows-btn">View All  <img src={arrowright} alt="headphones"/> </a>

   </div>
   </div>
   <div className="best-course-list">
   <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><img src={star} alt="star"/>5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div>
                  <div className="col-s-5">
                     <div className="best-course-card">
                        <div className="course-image"><img src={courseimages} alt="courseimages"/></div>
                        <div className="course-body">
                              <div className="cat-price"><span className="crs_categoury">Design</span> <span className="crs_price">$57</span></div>
                              <h3 className="course_title">Machine Learning A-Z™: Hands-On Python & R In Data...</h3>
                        </div>
                           <div className="course-footer">
                           <span className="corse-rating"><img src={star} alt="star"/> 5.0</span>
                           <span className="corse-student"><span className="cr-value">265.7K</span> students</span>
                           </div>
                     </div> 
                  </div></div>
</div>
         
         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
      </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}
export default SingleCourse;