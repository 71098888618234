import React from "react";
import Card from "react-credit-cards";
import './CreditCardForm.css';
import html2canvas from "html2canvas";

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFrontOfCardVisible: true,
      number: '',
      expiry: '',
      cvc: '',
      name: '',
      focus: '',
    };
  }
  
  downloadImage = async () => {
    try {
      const input = document.getElementById("card-details");
      if (!input) {
          console.error("The element #card-details was not found.");
          return;
      }

      const canvas = await html2canvas(input, { logging: true, letterRendering: 1, useCORS: true });
      const dataUrl = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "card-details.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
        console.error("Failed to capture the card details:", error);
    }
  }

  toggleCardFlip = () => {
    this.setState(prevState => ({
      isFrontOfCardVisible: !prevState.isFrontOfCardVisible
    }));
  }

  render() {
    const { data } = this.props;
    const { isFrontOfCardVisible } = this.state;

    return (
      <div className="mt-3">
        <h4>Submitted:</h4>
        <div>{JSON.stringify(data)}</div>
        <div className="mt-3 cursor-pointer" onClick={this.toggleCardFlip}>
          <div id="card-details">
            <Card
              cvc={data.cvc || ""}
              expiry={data.expiry || ""}
              name={data.name || ""}
              number={data.cardNumber || ""}
              focused={isFrontOfCardVisible ? "number" : "cvc"}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-info mr-10" onClick={this.toggleCardFlip}>
            Toggle Card
          </button>
          <button className="ml-10 btn btn-primary" onClick={this.downloadImage}>
            Download Card
          </button>
        </div>
      </div>
    );
  }
}

export default Results;