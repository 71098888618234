import React from "react";
import fileicon from "../images/file.svg";
import roketicon from "../images/rocket-icon.svg";
import flagicon from "../images/flag-icon.svg";
import chckicon from "../images/check-icon.svg";
import subicon from "../images/file-ck.svg";
import pre from "../images/pre-icon.svg";
import nxt from "../images/nxt-icon.svg";
import $ from "jquery";


class sidebarfilter extends React.Component {
    // Pagination state
  state = {
    currentPage: 1,
    totalPages: 4  // Assuming there are 5 pages total
  };

  // Function to change page
  setPage = (page) => {
    if (page < 1 || page > this.state.totalPages) return;
    this.setState({ currentPage: page });
    // You would also need to call a function here to load the data for the selected page
  };
  componentWillMount(){

    $(document).ready(function(){
        
      $(function(){
         
        $('.showSingle').click(function(){
              $('.targetDiv').slideUp();
              $('#div'+$(this).attr('target')).slideDown();
        });
      });
    });
    
  }
  render() {
    const { currentPage, totalPages } = this.state;
    return (

      <>
       <div className="filter-sidebar">
                  <div className="filter-item">
                     <h2 className="toggle-heading"> Category <span className="ft-toggle"></span></h2>
                     <ul className="toggle-content">
                        <li className="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul className="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                                
                              </ul>
                        </li>
                        <li className="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul className="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                             
                              </ul>
                        </li>
                        <li className="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul class="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                                 
                              </ul>
                        </li>
                        <li className="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul className="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                              
                              </ul>
                        </li>
                        
                     </ul>
                  </div>
                  <div class="filter-item">
                     <h2 class="toggle-heading"> Tools</h2>
                     <ul class="toggle-content">
                        <li class="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul class="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                                
                              </ul>
                        </li>
                        <li class="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul class="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                           
                              </ul>
                        </li>
                        <li class="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul class="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                           
                              </ul>
                        </li>
                    
                     </ul>
                  </div>
                  <div class="filter-item">
                     <h2 class="toggle-heading"> Rating</h2>
                     <ul class="toggle-content">
                        <li class="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul class="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                               
                              </ul>
                        </li>
                        <li class="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul class="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                           
                              </ul>
                        </li>
                        <li class="sd-item">
                              <a href="#">Category <span className="dropdown-icon">&#9662;</span></a>
                              <ul class="item-submenu">
                                 <li><a href="#">Programming</a></li>
                                 <li><a href="#">Design</a></li>
                                 <li><a href="#">Marketing</a></li>
                            
                              </ul>
                        </li>
                        
                     </ul>
                  </div>
            </div>
      </>
    );
  }
}
export default sidebarfilter;