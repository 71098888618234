import React from "react";
import Sidebar from "./Sidebar";
import TopMenu from"./components/TopMenu";
import SettingsIcon from "../src/images/seting-icon.svg";
import tabsicon1 from "../src/images/tabs-icon1.svg";
import tabsicon2 from "../src/images/tabs-icon2.svg";
import tabsicon3 from "../src/images/tabs-icon3.svg";
import tabsicon4 from "../src/images/tabs-icon4.svg";
import tabsicon5 from "../src/images/tabs-icon5.svg";
import tabsicon6 from "../src/images/tabs-icon6.svg";
import tabsicon7 from "../src/images/tabs-icon7.svg";
import editicon from "../src/images/edit-icon.svg";
import AddUserAdd from "./components/AddUserAdd";
import AddBrunchAdd from "./components/AddBrunchAdd";
import { FaRegEye, FaSistrix, FaBars } from "react-icons/fa";
import LabViewUser from "./components/LabViewUser";
import ViewBrunch from "./components/ViewBrunch";
import helpicon from "../src/images/help-icon.svg";
import $ from "jquery";
import MobileMenu from "./components/MobileMenu";
import LabViewBrunch from "./components/LabViewBrunch";
import SetingAddfild from "./components/SetingAddfild";
import SmondayDiv from "./components/SmondayDiv";




class Settings extends React.Component {
    componentWillMount(){

        $(document).ready(function(){
          $(".togle-icon").click(function(e) {
              e.preventDefault();
              $("#navAccordion").toggleClass("active");
              $("main").toggleClass("full-width");
              $("footer").toggleClass("full-width");
        
        });
        });

        $(document).ready(function(){
            $(".toggle-password").click(function() {
     
               $(this).toggleClass("fa-eye fa-eye-slash");
               var input = $($(this).attr("toggle"));
               if (input.attr("type") == "text") {
                 input.attr("type", "password");
               } else {
                 input.attr("type", "text");
               }
             });
         });
        
      }
    render() {
      return(
          <>
           <div className='page-wrapper chiller-theme toggled'>

                <div className="main-sec d-md-flex">
                    <div className="sidebar-menu" id="navAccordion">
                    <Sidebar/>
                    </div>
                </div>
                <main className="body-total content-wrapper float-start w-100">
                    <div className="container-fluid">
                    <div className="d-none d-lg-block">
                        <div className="top-bar-menu shadow float-start">
                            <a className="tog-btn togle-icon">
                            <FaBars/>
                            </a>
                            <TopMenu/>
                        </div>
                    </div>
                    <div className="dasb-div bk-div  float-start w-100">
                            <div className="bk-inside-div">
                                <div className="col-lg-12 ps-lg-0">
                                    <div className="show-date-details d-lg-flex shadow mb-5 mb-lg-0">
                                        
                                        <h6 className="my-4 my-md-0 cm-heading-page"> <span> <img src={SettingsIcon} alt="cl"/> </span> Settings </h6>
                                    
                                        
                                        
                                    
                                    </div>
                                </div>

                                
                            </div> 

                            <div className="totals-tabs-sec mt-4">
                                
                                <div className="d-lg-flex align-items-start w-100 text-center">
                                    
                                    <p>Coming soon</p>
                                </div>

                            </div>
                        
                    
                        </div>
                    </div>
                </main>
            </div> 

            <MobileMenu/>
          </>
      );
    }
  }

export default Settings;