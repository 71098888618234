import React from "react";
import fileicon from "../../images/file.svg";
import roketicon from "../../images/rocket-icon.svg";
import flagicon from "../../images/flag-icon.svg";
import chckicon from "../../images/check-icon.svg";
import subicon from "../../images/file-ck.svg";
import dotthree from "../../images/DotsThree.svg";

import $ from "jquery";


class WithdrawHistoryTable extends React.Component {
  componentWillMount(){

    $(document).ready(function(){
        
      $(function(){
         
        $('.showSingle').click(function(){
              $('.targetDiv').slideUp();
              $('#div'+$(this).attr('target')).slideDown();
        });
      });
    });
    
  }
  render() {
    return (

      <>
      <table className="table  table-hover inside-sp table-responsive">
        <thead>
        <tr className="bg-row">
            <th scope="col"> Date </th>
            <th scope="col">Method</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>           
			      <th scope="col">Action</th>
        </tr>
        </thead>
      <tbody>
      <tr>
         <td>
          <span className="payment_date">21 Sep, 2021 at 2:14 AM</span> </td>
          <td >
          <span className="payment_method">Mastercards</span>
          </td>
          <td className="cm-width">
          <span className="payment_method-card">American Express</span>
          </td>
          <td >
          <span className="payment_status"><span className="text-warning">Pending</span></span>
          </td>
    
          <td>
            <div className="d-flex justify-content-center">
                <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={dotthree} alt="dotthree"/> </a>
                  <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                 <a class="dropdown-item" href="#">Cancel Withdraw</a>
                  </div>
            </div>
          </td>
      </tr>
	  
      <tr>
         <td>
          <span className="payment_date">21 Sep, 2021 at 2:14 AM</span> </td>
          <td >
          <span className="payment_method">Mastercards</span>
          </td>
          <td className="cm-width">
          <span className="payment_method-card">American Express</span>
          </td>
          <td >
          <span className="payment_status"><span className="text-warning">Pending</span></span>
          </td>
    
          <td>
          <div className="d-flex justify-content-center">
                <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={dotthree} alt="dotthree"/> </a>
                  <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                 <a class="dropdown-item" href="#">Cancel Withdraw</a>
                  </div>
            </div>
          </td>
      </tr>
	  
	    <tr>
         <td>
          <span className="payment_date">21 Sep, 2021 at 2:14 AM</span> </td>
          <td >
          <span className="payment_method">Mastercards</span>
          </td>
          <td className="cm-width">
          <span className="payment_method-card">American Express</span>
          </td>
          <td >
          <span className="payment_status"><span className="text-danger">Canceled</span></span>
          </td>
    
          <td>
          <div className="d-flex justify-content-center">
                <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={dotthree} alt="dotthree"/> </a>
                  <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                 <a class="dropdown-item" href="#">Cancel Withdraw</a>
                  </div>
            </div>
          </td>
      </tr>
	  
	  
	  
      <tr>
         <td>
          <span className="payment_date">21 Sep, 2021 at 2:14 AM</span> </td>
          <td >
          <span className="payment_method">Mastercards</span>
          </td>
          <td className="cm-width">
          <span className="payment_method-card">American Express</span>
          </td>
          <td >
          <span className="payment_status"><span className="text-success">Completed</span></span>
          </td>
    
          <td>
          <div className="d-flex justify-content-center">
                <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={dotthree} alt="dotthree"/> </a>
                  <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                 <a class="dropdown-item" href="#">Cancel Withdraw</a>
                  </div>
            </div>
          </td>
      </tr>
	  
      <tr>
         <td>
          <span className="payment_date">21 Sep, 2021 at 2:14 AM</span> </td>
          <td >
          <span className="payment_method">Mastercards</span>
          </td>
          <td className="cm-width">
          <span className="payment_method-card">American Express</span>
          </td>
          <td >
          <span className="payment_status"><span className="text-danger">Pending</span></span>
          </td>
    
          <td>
          <div className="d-flex justify-content-center">
                <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={dotthree} alt="dotthree"/> </a>
                  <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                 <a class="dropdown-item" href="#">Cancel Withdraw</a>
                  </div>
            </div>
          </td>
      </tr>
	  

     
      
      </tbody>
      </table>
      </>
    );
  }
}
export default WithdrawHistoryTable;