import React from "react";
import { Calendar, CalendarComponent } from '@syncfusion/ej2-react-calendars';

function AdminLeftCalender(){
    
    return(
        <>
        <CalendarComponent id="calendar" isMultiSelection={true} />
        </>
    );
}
export default AdminLeftCalender;







