import React from "react";

function PaidOption(){
   
    return(
        <>
        <option value="hide">-- Paid --</option>
        <option value="january">Unpaid</option>
        <option value="january">Paid</option>
 
        </>
    );
}
export default PaidOption;