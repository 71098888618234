import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "./components/BookingCtWeek";
import DashboardCard from "./components/DashboardCard";
import CourseUpdates from "./components/CourseUpdates";
//import CurveGraph from './components/CurveGraph';
import Calendar from './components/Calendar';
import myJavaScriptFile from './myJavaScriptFile.js';
import MultiLineChart from './components/MultiLineChart'
import BookingCtMonth from "./components/BookingCtMonth";
import RevieCtWeek from "./components/RevieCtWeek";
import RevieCtMonth from "./components/RevieCtMonth";
import CustomerCtweek from "./components/CustomerCtweek";
import CustomerCtMonth from "./components/CustomerCtMonth";
import InternationSpacePass from "./components/InternationSpacePass";
import dsicn from "../src/images/ds-icon.png";
import clicn from "../src/images/uil_calender.svg";
import coloricon from "../src/images/cl-icon-color.svg";
import timeicon from "../src/images/time.svg";
import useicon from "../src/images/user-cion.svg";
import downloadicon from "../src/images/download-icon.svg";
import startrating from "../src/images/start-r.svg";
import editicon from "../src/images/edit-icon.svg";
import filtericon from "../src/images/filter-icon.svg";
import fileicon from "../src/images/file.svg";
import roketicon from "../src/images/rocket-icon.svg";
import flagicon from "../src/images/flag-icon.svg";
import chckicon from "../src/images/check-icon.svg";
import subicon from "../src/images/file-ck.svg";
import user1 from '../src/images/user1.png';
import user2 from '../src/images/user2.png';
import user3 from '../src/images/user3.png';
import trailer from '../src/images/Trailer.jpg';
import spinner from '../src/images/Spinner.svg';

import filters from '../src/images/filters-icon.svg';
import search from '../src/images/search-icon.svg';
import addplus from '../src/images/add-plus.svg';
import viewicon from "../src/images/view_icon.svg";
import angleleft from '../src/images/angle-left.svg';
import angleright from "../src/images/angle-right.svg";
import filledcheck from '../src/images/Filled-check.svg';
import passport from '../src/images/passport-image.jpg';
import CoursesTable from "./components/CoursesTable"; 
import BookingModal from "./components/BookingModal";
import roundck from "../src/images/round-check.svg";
import specialicon from "../src/images/sp-icons.svg";
import astronauticon from "../src/images/astronautspaceflight.svg";
import creditcard from "../src/images/credit-card-mockup.png";
import cpu from "../src/images/cpu.svg";
import handshake from "../src/images/Handshake.svg";
import crditCard from "../src/images/CreditCard.svg";
import chartBar from "../src/images/ChartBarHorizontal.svg";
import bugdroid from "../src/images/bugdroid.svg";
import receipt from "../src/images/Receipt.svg";
import megaphone from "../src/images/MegaphoneSimple.svg";
import camerac from "../src/images/Camerac.svg";
import packagec from "../src/images/Package.svg";
import PenNib from "../src/images/PenNib.svg";
import firstaid from "../src/images/FirstAidKit.svg";
import headphones from "../src/images/Headphones.svg";
import arrowright from "../src/images/button-arrowright.svg";
import courseimages from "../src/images/course-images.jpg";
import featureimages from "../src/images/feature-course.jpg";
import usercourse from "../src/images/User-course.svg";
import barchart from "../src/images/bar-chart.svg";
import clock from "../src/images/Clock.svg";
import star from "../src/images/star.svg";
import alarm from "../src/images/Alarm.svg";
import notepad from "../src/images/Notepad.svg";
import notebook from "../src/images/Notebook.svg";
import studentsen from "../src/images/Students-Enrolled.svg";
import Stack from "../src/images/Stack.svg";
import trophy from "../src/images/Trophy.svg";
import currencydollar from "../src/images/CurrencyDollar.svg";
import monitor from "../src/images/Monitor.svg";
import copy from "../src/images/Copy.svg";
import facebook from "../src/images/facebook.svg";
import twitter from "../src/images/twitter.svg";
import envelope from "../src/images/Envelope.svg";
import whatsup from "../src/images/whatsup.svg";
import playcircle from "../src/images/play-circle.svg";
import foldericon from "../src/images/folder-icon.svg";
import playicon from "../src/images/play-icon.svg";
import fileicons from "../src/images/file-icon.svg";
import FileText from "../src/images/FileText.svg";
import ChatsCircle from "../src/images/ChatsCircle.svg";


import clockicon from "../src/images/Clock-icon.svg";
import keven from "../src/images/keven.png";
import instructor from "../src/images/instructor-img.jpg";
import pRight from "../src/images/p-ArrowRight.svg";
import pLeft from "../src/images/p-ArrowLeft.svg";
import netflix from "../src/images/netflix.svg";
import youtube from "../src/images/youtube.svg";
import google from "../src/images/google.svg";
import lenovo from "../src/images/lenovo.svg";
import slack from "../src/images/slack.svg";
import lexmark from "../src/images/lexmark.svg";
import microsoft from "../src/images/microsoft.svg";
import verizon from "../src/images/verizon.svg";
import beinstructor from "../src/images/become-instructor.png";
import NewRevinew from "./components/NewRevinew";
import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "./components/MobileMenu";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import TopMenu from"./components/TopMenu";
import FilerDashboard from "./components/FilerDashboard";
import CalenderMultiple from "./components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "./components/LabDashboradFilter";
import Newgrphadd from "../src/images/customer-graph.svg";
//import PercentageCircle from './PercentageCircle';
import CircularProgress from './CircularProgress';

import $ from "jquery";

class  WatchCourse extends React.Component {
componentWillMount(){
$(document).ready(function(){
$('.accordion .accordion-head').click(function () {
                  if ($(this).next('div').is(':visible')) {
                    $(this).next('div').slideUp('normal');
                    $(this).find('i').removeClass('arrow-open').addClass('arrow-close');
                  } else if ($(this).next('div').is(':hidden')) {
                    $(this).next('div').slideDown('normal');
                    $(this).find('i').removeClass('arrow-close').addClass('arrow-open');
                  } else {
                    $('.accordion-body').slideUp('normal');
                    $(this).next('div').slideToggle('normal');
                    $(this).find('i').removeClass('arrow-open').addClass('arrow-close');
                  }
                }); //this month form
});
}
render() {
return(
<>
<div className='page-wrapper chiller-theme toggled'>
   <div className="main-sec d-lg-flex">
      <div className="sidebar-menu" id="navAccordion">
         <Sidebar/>
      </div>
   </div>
   <main className="body-total content-wrapper float-start w-100">
      <div className="container-fluid w-90">
         <div className="d-none d-lg-block">
            <div className="top-bar-menu shadow float-start">
               <a className="tog-btn me-2 togle-icon">
                  <FaBars/>
               </a>
               <div className="d-flex me-auto  align-items-center">
                  <h5 className="mb-0 crm-newadd m-r-10"> Navdeep Singh </h5>
               </div>
               <TopMenu/>
            </div>
         </div>
         <div className="dasb-div bk-div float-start w-90">
           <div className="watch-course-wrapper">
               <div className="atch-course-row row">
                  <div className="col-lg-7 single-course-left">
                    
                     <div className="single-course-content">
                     <div className="course_video">

                     <iframe width="100%" height="690" src="https://www.youtube.com/embed/EngW7tLk6R8?si=O3McFgsYmz8_3xGs" 
                     title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                     </div>
                                 <h2>2. Sign up in Webflow</h2>
                               
                                    <div className="col-lg-7 course-author-content">
                                       <div className="author-image">
                                       <span className="img1"><img src={user2} alt="user2"/></span>
                                       <span className="user3"><img src={user3} alt="user3"/></span>
                                       </div>
                                       <div className="author-name">
                                       <p className="watch-count">512</p>
                                       <div className="watching_ct-name">Students watching</div>
                                       </div>
                                    </div>
                                    <div className="col-lg-5 course-author-rating">
                                       <div className="courseupdate"> 
                                      
                                          <span className="update_value">Last updated: <span> Oct 26, 2020</span></span>
                                          <span className="comment_view"> Comments:<span> 1220</span></span>
                                       </div>
                                    </div>
                                 </div>

                        
                        <div className="student-profile-menu single-course">
                                 <ul>
                                    <li><a className="active" href="#description">Description</a></li>
                                    <li><a href="#lectures-nots">Lectures Notes</a></li>
                                    <li><a href="#attached-file">Attach File <span className="atach-count">1</span></a></li>
                                    <li><a href="#comments-view">Comments</a></li>
                                 </ul>
                        </div>
               
                           <div id="description" className="overview-section">
                              <div className="overview-description">
                                 <div className="course-single-title">
                                    <h3>Lectures Description</h3>
                                 </div>
                                 <p>We cover everything you need to build your first website. From creating your first page through to uploading your
                                 website to the internet. Weill use the worlds most popular (and free) web design tool called Visual Studio Code. 
                                 There are exercise files you can download and then work along with me. At the end of each video I have a downloadable 
                                 version of where we are in the process so that you can compare your project with mine. This will enable you to see easily
                                 where you might have a problem. We will delve into all the good stuff such as how to create your very own mobile burger
                                 menu from scratch learning some basic JavaScript and jQuery.</p>

                              <p>If that all sounds a little too fancy - do not worry, this course is aimed at people new to web design and who have never coded before. We’ll start
                                 right at the beginning and work our way through step by step. </p>
                     
                              </div>
                                    
                           </div>
                           <div id="lectures-nots" className="lectures-section">
                                 <div className="overview-description">
                                    <div className="course-single-title">
                                       <h3>Lecture Notes</h3><a href="" className="brows-btn download-notes"><img src={downloadicon} alt="downloadicon"/> Download Notes</a>
                                    </div>
                                          <p>In ut aliquet ante. Curabitur mollis tincidunt turpis, sed aliquam mauris finibus vel. Praesent eget mi in mi maximus egestas. Mauris eget ipsum in justo 
                                          bibendum pellentesque. Sed id arcu in arcu ullamcorper eleifend condimentum quis diam. Phasellus tempus, urna ut auctor mattis, nisi nunc tincidunt lorem, eu egestas augue lectus sit amet sapien. Maecenas tristique aliquet massa, 
                                             a venenatis augue tempor in. Aliquam turpis urna, imperdiet in lacus a, posuere suscipit augue.</p>
                                             <p>   Nullam non quam a lectus finibus varius nec a orci. Aliquam efficitur sem cursus elit efficitur lacinia</p>
                                          <ul>
                                          <li>Morbi sit amet pretium tellus. Donec blandit fermentum tincidunt.</li>  
                                          <li>Proin iaculis sem et imperdiet tristique. Nam varius ac nisl id sodales. Donec iaculis interdum mattis.</li> 
                                          <li>  Curabitur posuere ultricies diam in egestas.</li> 
                                          <li>  Donec id diam et lacus pharetra vestibulum a id est. Mauris vestibulum massa quis elit feugiat, dictum maximus ipsum pellentesque.</li> 
                                          <li>  Sed elementum, libero id lacinia aliquet, purus nibh consectetur mauris, eget interdum mi lacus vitae sem.</li> 

                                          </ul>

                                           <p>Donec congue aliquam lorem nec congue. Suspendisse eu risus mattis, interdum ante sed, fringilla urna. Praesent mattis dictum sapien a lacinia. Ut scelerisque magna aliquet, blandit arcu quis, consequat purus. Suspendisse eget scelerisque felis. Integer vulputate urna laoreet purus vehicula condimentum. Donec quis luctus quam. Curabitur quis molestie ante. 
                                          Nam pharetra sagittis varius. Sed ullamcorper facilisis bibendum.</p>
                                    </div>
                              </div>
                              <div id="attached-file" className="attach-section overview-section">
                                 <div className="course-single-title">
                                       <h3>Attach Files <span className="count-file">(01)</span></h3>
                                 </div>
                                    <div className="attach-file-list">
                                       <div className="attach-file-item ">
                                          <div className="file-content-block">
                                             <div className="file-icon"><img src={FileText} alt="FileText"/></div>
                                             <div className="file-content">
                                                <h4>Create account on webflow.pdf</h4>
                                                <span className="file_size">12.6 MB</span>
                                             </div>
                                          </div>
                                          <div className="file-action"><a href="" className="brows-btn download-file">Download File</a></div>
                                       </div>
                                 </div>
                              </div>
                           
                           <div id="comments-view" className="comments-container">
                              <div className="course-single-title">
                                       <h3>Comments <span className="comment_count">(154)</span></h3>
                                 </div>
                              <ul id="comments-list" className="comments-list">
                                       <li>
                                             <div className="comment-main-level">
                                             
                                                <div className="comment-avatar">  <img src={instructor} alt="instructor"/></div>
                                                
                                                <div className="comment-box">
                                                   <div className="comment-head">
                                                         <h6 className="comment-name by-author"><a href="#">Ronald Richards</a></h6>
                                                         <span>1 week ago</span>
                                                      
                                                   </div>
                                                   <div className="comment-content">ChatsCircle
                                                      Maecenas risus tortor, tincidunt nec purus eu, gravida suscipit tortor. 
                                                   </div>
                                                <div className="reply-link"><a href=""><img src={ChatsCircle} alt="ChatsCircle"/> REPLY</a></div>
                                                </div>
                                             </div>
                                       
                                             <ul className="comments-list reply-list">
                                                <li>
                                                   
                                                   <div className="comment-avatar"> <img src={instructor} alt="instructor"/></div>
                                                   
                                                   <div className="comment-box">
                                                         <div className="comment-head">
                                                            <h6 className="comment-name by-admin"><a href="#">Kristin Watson</a></h6><a className="admin_info" href="">Admin</a>
                                                            <span>1 week ago</span>
                                                            
                                                         </div>
                                                         <div className="comment-content">
                                                         Nulla pellentesque leo vitae lorem hendrerit, sit amet elementum ipsum rutrum. Morbi ultricies volutpat orci quis fringilla. Suspendisse faucibus augue quis dictum egestas. 
                                                         </div>
                                                         <div className="reply-link"><a href=""><img src={ChatsCircle} alt="ChatsCircle"/> REPLY</a></div>
                                                   </div>
                                                </li>

                                                <li>
                                                   
                                                   <div className="comment-avatar"> <img src={instructor} alt="instructor"/></div>
                                                   
                                                   <div className="comment-box">
                                                         <div className="comment-head">
                                                            <h6 className="comment-name"><a href="#">Kristin Watson</a></h6><a className="admin_info" href="">Admin</a>
                                                            <span>1 week ago</span>
                                                            
                                                         </div>
                                                         <div className="comment-content">
                                                         Nulla pellentesque leo vitae lorem hendrerit, sit amet elementum ipsum rutrum. Morbi ultricies volutpat orci quis fringilla. Suspendisse faucibus augue quis dictum egestas. 
                                                         </div>
                                                         <div className="reply-link"><a href=""><img src={ChatsCircle} alt="ChatsCircle"/> REPLY</a></div>
                                                   </div>
                                                </li>
                                             </ul>
                                       </li>

                                       <li>
                                             <div className="comment-main-level">
                                          
                                                <div className="comment-avatar"> <img src={instructor} alt="instructor"/></div>
                                             
                                                <div className="comment-box">
                                                   <div className="comment-head">
                                                         <h6 className="comment-name"><a href="#">Ramdas Gupta</a></h6>
                                                         <span>2 weeks ago</span>
                                                      
                                                   </div>
                                                   <div className="comment-content">
                                                      Thank you for your helpful video. May I ask what is the application use to demo the animation at [4:24], is it the runnable mobile application?

                                                   As what I know, Figma Mirror app cannot do that. Please help me

                                                   Great thanks
                                                   
                                                   </div>
                                                   <div className="reply-link"><a href=""><img src={ChatsCircle} alt="ChatsCircle"/> REPLY</a></div>
                                                </div>
                                             </div>
                                       </li>
                                       <li>
                                             <div className="comment-main-level">
                                          
                                                <div className="comment-avatar"> <img src={instructor} alt="instructor"/></div>
                                             
                                                <div className="comment-box">
                                                   <div className="comment-head">
                                                         <h6 className="comment-name"><a href="#">Ramdas Gupta</a></h6>
                                                         <span>2 weeks ago</span>
                                                      
                                                   </div>
                                                   <div className="comment-content">
                                                      Thank you for your helpful video. May I ask what is the application use to demo the animation at [4:24], is it the runnable mobile application?

                                                   As what I know, Figma Mirror app cannot do that. Please help me

                                                   Great thanks
                                                   
                                                   </div>
                                                   <div className="reply-link"><a href=""><img src={ChatsCircle} alt="ChatsCircle"/> REPLY</a></div>
                                                </div>
                                             </div>
                                       </li>
                                       <li>
                                             <div className="comment-main-level">
                                          
                                                <div className="comment-avatar"> <img src={instructor} alt="instructor"/></div>
                                             
                                                <div className="comment-box">
                                                   <div className="comment-head">
                                                         <h6 className="comment-name"><a href="#">Ramdas Gupta</a></h6>
                                                         <span>2 weeks ago</span>
                                                      
                                                   </div>
                                                   <div className="comment-content">
                                                      Thank you for your helpful video. May I ask what is the application use to demo the animation at [4:24], is it the runnable mobile application?

                                                   As what I know, Figma Mirror app cannot do that. Please help me

                                                   Great thanks
                                                   
                                                   </div>
                                                   <div className="reply-link"><a href=""><img src={ChatsCircle} alt="ChatsCircle"/> REPLY</a></div>
                                                   <div className="comment_reply_input">
                                                   <form >
                                                   <div className="d-flex reply_input ">
                                                         <div className="col-auto position-relative">
                                                         <span className="chat-icon-black"><img src={ChatsCircle} alt="ChatsCircle"/></span>
                                                            <input type="text" placeholder="Write your reply" className="form-control" value=""/>
                                                         </div>
                                                      
                                                         
                                                         <div className="col-auto">
                                                            <button type="submit" className="brows-btn ">Post reply</button>
                                                         </div> </div>
                                                         </form>
                                                      
                                                   </div>
                                                </div>
                                             </div>
                                       </li>
                                       <li>
                                             <div className="comment-main-level">
                                          
                                                <div className="comment-avatar"> <img src={instructor} alt="instructor"/></div>
                                             
                                                <div className="comment-box">
                                                   <div className="comment-head">
                                                         <h6 className="comment-name"><a href="#">Ramdas Gupta</a></h6>
                                                         <span>2 weeks ago</span>
                                                      
                                                   </div>
                                                   <div className="comment-content">
                                                      Thank you for your helpful video. May I ask what is the application use to demo the animation at [4:24], is it the runnable mobile application?

                                                   As what I know, Figma Mirror app cannot do that. Please help me

                                                   Great thanks
                                                   
                                                   </div>
                                                   <div className="reply-link"><a href=""><img src={ChatsCircle} alt="ChatsCircle"/> REPLY</a></div>
                                                </div>
                                             </div>
                                       </li>
                              </ul>

                              <a className="button loadmore-btn" >Load more
                              <span><img src={spinner} alt="spinner"/>
                                             
                                             </span>
                           </a>
                           </div>
                        

                     </div>
                  
                  <div className="col-lg-5 single-course-sidebar">
                        <div className="course-watch-sidebar">
                        <div className="course-watch-head">
                              <h3>Course Contents</h3>
                              <div className="course-status">
                              15% Completed
                              </div>
                           </div>
                           <div className="courstProgess">
                           <div class="progress">
                                          <div class="progress-bar bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                          </div>
                              </div>
                        <div id="watchCourseContents" className="course-curriculum-section">
                           
                           <div className="accordion accordion-wrap-faq lecture-wrap" id="watchCourse">
                                 <div className="accordion-item accordion__list2">
                                    <h2 className="accordion-header " id="headingOne">
                                       <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                       <div className="linkwrap"><div className="accordion-header-content">
                                                      
                                                      Getting Started
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                         
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div> </div>
                                       </button>  
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#watchCourse">
                                       <div className="accordion-body accordion-body-text">                                      
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f1"/>
                                                      <label class="form-check-label" for="f1">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                     </div>
                                                   <div className="watchCourse-item">
                                                      
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f2"/>
                                                      <label class="form-check-label" for="f2">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                       
                                                   </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f3"/>
                                                      <label class="form-check-label" for="f3">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                         
                                                   </div>
                                                   <div className="watchCourse-item">
                                                     <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f4"/>
                                                      <label class="form-check-label" for="f4">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     
                                                      </div>
                                                        </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f5"/>
                                                      <label class="form-check-label" for="f5">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                      </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header  " id="headingTwo">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                       <div className="linkwrap"> <div className="accordion-header-content">
                                                      
                                             Secret of Good Design
                                    </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div> </div>
                                       </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#watchCourse">
                                    <div className="accordion-body accordion-body-text">                                      
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f6"/>
                                                      <label class="form-check-label" for="f6">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                     </div>
                                                   <div className="watchCourse-item">
                                                      
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f7"/>
                                                      <label class="form-check-label" for="f7">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                       
                                                   </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f8"/>
                                                      <label class="form-check-label" for="f8">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                         
                                                   </div>
                                                   <div className="watchCourse-item">
                                                     <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f9"/>
                                                      <label class="form-check-label" for="f9">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     
                                                      </div>
                                                        </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f10"/>
                                                      <label class="form-check-label" for="f10">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                      </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingThree">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                       <div className="linkwrap">  <div className="accordion-header-content">
                                                      
                                    Practice Design Like an Artist
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div>  </div>
                                       </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#watchCourse">
                                    <div className="accordion-body accordion-body-text">                                      
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f11"/>
                                                      <label class="form-check-label" for="f11">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                     </div>
                                                   <div className="watchCourse-item">
                                                      
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f12"/>
                                                      <label class="form-check-label" for="f12">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                       
                                                   </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f13"/>
                                                      <label class="form-check-label" for="f13">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                         
                                                   </div>
                                                   <div className="watchCourse-item">
                                                     <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f14"/>
                                                      <label class="form-check-label" for="f14">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     
                                                      </div>
                                                        </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f15"/>
                                                      <label class="form-check-label" for="f15">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                      </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingfour">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                       <div className="linkwrap"> <div className="accordion-header-content">
                                                      
                                                      Web Development (webflow)
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div></div>
                                       </button>
                                    </h2>
                                    <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#watchCourse">
                                    <div className="accordion-body accordion-body-text">                                      
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f17"/>
                                                      <label class="form-check-label" for="f17">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                     </div>
                                                   <div className="watchCourse-item">
                                                      
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f18"/>
                                                      <label class="form-check-label" for="f18">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                       
                                                   </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f19"/>
                                                      <label class="form-check-label" for="f19">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                         
                                                   </div>
                                                   <div className="watchCourse-item">
                                                     <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f20"/>
                                                      <label class="form-check-label" for="f20">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     
                                                      </div>
                                                        </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f21"/>
                                                      <label class="form-check-label" for="f21">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                      </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
                                 <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingfive">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                       <div className="linkwrap">   <div className="accordion-header-content">
                                                      
                                                      Secrets of Making Money Freelancing
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div>   </div>
                                       </button>
                                    </h2>
                                    <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#watchCourse">
                                    <div className="accordion-body accordion-body-text">                                      
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f22"/>
                                                      <label class="form-check-label" for="f22">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                     </div>
                                                   <div className="watchCourse-item">
                                                      
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f23"/>
                                                      <label class="form-check-label" for="f23">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                       
                                                   </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f24"/>
                                                      <label class="form-check-label" for="f24">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                         
                                                   </div>
                                                   <div className="watchCourse-item">
                                                     <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f25"/>
                                                      <label class="form-check-label" for="f25">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     
                                                      </div>
                                                        </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f26"/>
                                                      <label class="form-check-label" for="f26">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                      </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
                              <div className="accordion-item  accordion__list2">
                                    <h2 className="accordion-header" id="headingsix">
                                       <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                       <div className="linkwrap">  <div className="accordion-header-content">
                                                      
                                                      Advanced
                                                   </div>
                                                <div className="sub-heading-meta">
                                                      <ul>
                                                         <li>
                                                            <img src={playcircle} alt="playcircle"/>
                                                         52 lectures
                                                         </li>
                                                         <li>
                                                         <img src={clock} alt="clock"/>
                                                         5m 49m
                                                         </li>
                                                      </ul>
                                                   </div>
                                                   </div>
                                       </button>
                                    </h2>
                                    <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#watchCourse">
                                    <div className="accordion-body accordion-body-text">                                      
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f27"/>
                                                      <label class="form-check-label" for="f27">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                     </div>
                                                   <div className="watchCourse-item">
                                                      
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f28"/>
                                                      <label class="form-check-label" for="f28">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                       
                                                   </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f29"/>
                                                      <label class="form-check-label" for="f29">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     </div>
                                                         
                                                   </div>
                                                   <div className="watchCourse-item">
                                                     <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f30"/>
                                                      <label class="form-check-label" for="f30">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                     
                                                      </div>
                                                        </div>
                                                   <div className="watchCourse-item">
                                                      <div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="f31"/>
                                                      <label class="form-check-label" for="f31">  1. What is Webflow?                                                  
                                                      <span className="course-duration"><img src={playicon} alt="playicon"/>
                                                         07:31</span>
                                                      </label>
                                                      </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                 </div>
                                 
               
                              </div>
                           </div>
                        </div>

                  </div>
             </div> 
            </div>

         
         </div>
         <div className="expandable_right_sidebar">
         <RightSidebar/>
         </div>
         </div>
   </main>
</div>
<div className="dashboard-footer">
<div className="footer-row row">
      <div className="col-lg-6">
         <ul className="left-footer-link">
            <li><a href="">Terms and Conditions</a></li>
            <li><a href="">Privacy Policy</a></li>
         </ul>
      </div>
      <div className="col-lg-6 text-right">
         <p>©  Copyright  |  World Space Academy Pvt. Ltd. </p>
      </div>
</div>
   
</div>
<div className="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog modal-lg">
      <div className="modal-content">
         <div className="modal-header pb-0">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
               <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Student Details</button>
               </li>
               <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
               </li>
            </ul>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <div className="tabs-settings">
               <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                     <div className="patinent-details">
                        <div className="inside-history-div col-lg-12 mx-auto mt-3">
                           <h6 className="mn-head"> 10 Results Found </h6>
                           <div className="result-div mt-3">
                              <div className="cm-history mx-auto row">
                                 <div className="col-lg-10">
                                    <div className="left-history">
                                       <h5 className="d-lg-flex"> Space Program Name  </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                    </div>
                                    <div className="left-history mt-5">
                                       <h5> Space Programe Name </h5>
                                       <div className="same-details">
                                          <span> #SP001545 </span>
                                          <span>
                                             <FaCircle/>
                                             25-02-2022 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #RSLT6848r4 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             #EXMCENTER 
                                          </span>
                                          <span>
                                             <FaCircle/>
                                             1234567890
                                          </span>
                                       </div>
                                       
                                    </div>
                                 </div>
                                 <div className="col-lg-2">
                                    <div className="right-sec d-grid justify-content-lg-end">
                                       <h5> Rs. 1500/- </h5>
                                       <h6 className="ps-st">
                                          <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                       </h6>
                                       <figure className="mb-0">
                                          <img src={startrating} alt="ck"/>
                                       </figure>
                                       <p className="text-center"> Satisfied </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                           <div className="row row-cols-7 justify-content-center">
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={fileicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Enrollment 
                                       <span className="d-block">
                                       (09:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status">
                                    <figure className="iocn-cm">
                                       <img src={roketicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Admission
                                       <span className="d-block">
                                       (10:00 AM)
                                       </span>
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm ">
                                       <img src={flagicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Exam
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={specialicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Score
                                    </h5>
                                 </div>
                              </div>
                              <div className="col">
                                 <div className="comon-status cm-disable">
                                    <figure className="iocn-cm">
                                       <img src={subicon} alt="file"/>
                                    </figure>
                                    <h5>
                                       Result
                                    </h5>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                     <div className="inside-history-div col-lg-11 ms-auto mt-3">
                        <h6 className="mn-head"> 10 Results Found </h6>
                        <div className="result-div mt-3">
                           <div className="cm-history row">
                              <div className="col-lg-11">
                                 <div className="left-history">
                                    <h5> Veena Rao
                                    </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span >
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span >
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          +91 525236521 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          veenarao@gmail.com 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                                 
                              </div>
                              <div className="col-lg-1">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 1500/- </h5>
                                    <h6 className="ps-st">
                                       <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           <div className="cm-history row">
                              <div className="col-lg-9">
                                 <div className="left-history">
                                    <h5> Veena Rao </h5>
                                    <div className="same-details">
                                       <span> 30 Yrs, Female </span>
                                       <span>
                                          <FaCircle/>
                                          25-02-2022 
                                       </span>
                                       <span>
                                          <FaCircle/>
                                          #ORD6848r4 
                                       </span>
                                    </div>
                                    <ul className="list-unstyled d-lg-flex mt-3">
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                       <li>
                                          CVC
                                       </li>
                                       <li>
                                          Dengue
                                       </li>
                                       <li>
                                          Typhoied
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="col-lg-3">
                                 <div className="right-sec d-grid justify-content-lg-end">
                                    <h5> Rs. 500/- </h5>
                                    <h6 className="unps-st">
                                       Unpaid
                                    </h6>
                                 </div>
                              </div>
                           </div>
                           
                           
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<MobileMenu/>
<LabDashboradFilter/>
<div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div className="modal-dialog">
      <div className="modal-content">
         <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
         </div>
         <div className="modal-body">
            <CalenderMultiple/>
         </div>
      </div>
   </div>
</div>
</>
);
}
}
export default WatchCourse;