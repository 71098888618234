import React, {useState} from "react";
import { NavLink } from "react-router-dom";
//import logobodyimg from "../src/images/logo-img.svg";
import logobodyimg from "../src/images/mapmyvisa_home.jpg";
//import logologin from "../src/images/logo-login.png";
import logoLMS from "../src/images/lms_logo_new.png";
import logoMicrosoft from "../src/images/microsoft_logo.png";
import logoGoogle from "../src/images/google_logo.png";
import leftarrow from "../src/images/left-arrow-back.svg";
import rightarrow from "../src/images/arrow-right-submit.svg";

import logologin from "../src/images/mapmyvisa_logo.png";
import iconeye from "../src/images/fm-eye.svg";
import $ from "jquery";

const clientId = "797259639142-el1ccheij4gfv2ssi1cbbivsm4ebg2is.apps.googleusercontent.com";  // Replace with your Google Client ID


class Login extends React.Component {
    
    componentWillMount(){
		
		window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
                client_id: clientId
            }).then((auth2) => {
                this.auth2 = auth2;  // Save the auth2 instance for later use
            });
        });

        $(document).ready(function(){
           $(".toggle-password").click(function() {
    
              $(this).toggleClass("fa-eye fa-eye-slash");
              var input = $($(this).attr("toggle"));
              if (input.attr("type") == "text") {
                input.attr("type", "password");
              } else {
                input.attr("type", "text");
              }
            });
        });
		
      }
	  handleGoogleLogin = () => {
        this.auth2.signIn().then(googleUser => {
            const profile = googleUser.getBasicProfile();
            console.log('Google User Token:', googleUser.getAuthResponse().id_token);
            console.log('Google User ID:', profile.getId());
            console.log('Google User Name:', profile.getName());
            console.log('Google User Image URL:', profile.getImageUrl());
            console.log('Google User Email:', profile.getEmail());
            // Here you would typically dispatch this user data to your store or handle it as your application requires
        }).catch(error => {
            console.error('Login Failed:', error);
        });
    };
    render() {
        
      return (
        <div className="login-page float-start w-100">
        <div className="topLeftLoginBtn">
        <button className="btn btn-default topleftloginbtn">Login Page</button>
        </div>
            <div className="container-fluid">
            <div className="row"> 
              <a class="back-link" href=""><img src={leftarrow} alt="leftarrow"/> Back</a>
              </div>
            <div className="row">
                
          
                <div className="col-lg-6">
                
                    <div className="login-right">
                    <figure className="text-center marginHere">
                            <img src={logoLMS} alt='bn'/>
                        </figure>
                        <div className="form-sec-login">
                            <form action="" method="get">
                            <div className="form-group">
                                <div className="lable-input">
                                <input type="email" className="form-control" placeholder="Enter Email Id or Phone Number" required/>
                                </div> 
                            </div>

                            <div className="form-group">
                                <div className="lable-input">
                                <input name="password" id="password-field" type="Password" className="form-control" placeholder="Enter Password" />
                                <span toggle="#password-field" className="fa fa-fw fa-eye-slash  field-icon toggle-password"> </span>
                                </div> 
                            </div>

                            <div className="from-group loginbottombuttons">
                            <p className="text-left remember-btn"><input type="checkbox" name="remember" value="remember"/> Remember Me? </p>
                            <NavLink to="/forget" className="text-right forget-btn"> Forgot Password? </NavLink>
                            </div>
                            <div className="from-group">
                                <NavLink to="/dashbord" className="sign-btn">
                                <button type="submit"> Log In <span><img src={rightarrow} alt="rightarrow"/></span></button>  
                                </NavLink>
                            </div>
                            
                            </form>
                            <div className="login_footer_section">
							<p className="text-center loginbottomtxt loginbottomsmalltxt">By continuing, you agree to Amazon's Conditions Of Use and Privacy Notice</p>
                            <p className="text-center  login-sign-text loginbottomtxt"><span>Or sign in with</span></p>

                            <div class="image-container">
                    <img src={logoMicrosoft} className="imageLeft" alt='bn' width="60px"/>
                    <img src={logoGoogle} className="imageRight" alt='bn' width="60px"/>
                    </div>
                            </div>
                        </div>
                    </div>

                    
                </div>

                <div className="col-lg-6">
                    <div className="login-left d-flex align-items-center justify-content-start">
                        <figure className="mb-0">
                            <img src={logobodyimg} alt='bn'/>
                        </figure>
                    </div>
                </div>

            </div>
            <div className="row login-footer"><div className="col-lg-12 pt-5  pb-5 text-center"><p>Powered by World Space Council (Dyanmic Footer)</p></div></div>
            </div>
       </div>
      );
    }
}
export default Login;