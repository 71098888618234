import React from "react";
import {NavLink } from "react-router-dom";
import BookingCtWeek from "../components/BookingCtWeek";
import BookingCtMonth from "../components/BookingCtMonth";
import RevieCtWeek from "../components/RevieCtWeek";
import RevieCtMonth from "../components/RevieCtMonth";
import CustomerCtweek from "../components/CustomerCtweek";
import CustomerCtMonth from "../components/CustomerCtMonth";
import dsicn from "../../src/images/ds-icon.png";
import clicn from "../../src/images/uil_calender.svg";
import coloricon from "../../src/images/cl-icon-color.svg";
import timeicon from "../../src/images/time.svg";
import useicon from "../../src/images/user-cion.svg";
import downloadicon from "../../src/images/download-icon.svg";
import startrating from "../../src/images/start-r.svg";
import editicon from "../../src/images/edit-icon.svg";
import filtericon from "../../src/images/filter-icon.svg";
import fileicon from "../../src/images/file.svg";
import roketicon from "../../src/images/rocket-icon.svg";
import flagicon from "../../src/images/flag-icon.svg";
import chckicon from "../../src/images/check-icon.svg";
import subicon from "../../src/images/file-ck.svg";
import BookingTable from "../components/BookingTable";
import BookingModal from "../components/BookingModal";
import roundck from "../../src/images/round-check.svg";
import specialicon from "../../src/images/sp-icons.svg";
import studentuser from "../../src/images/student_img.jpg";
import ins13  from "../../src/images/ins13.jpg";
import arrowright from "../../src/images/button-arrowright.svg";
import trophy from "../../src/images/Trophy.svg";
import playcirclec from "../../src/images/PlayCircle-c.svg";
import checksquare from "../../src/images/CheckSquare.svg";
import arrowdown from "../../src/images/ArrowDown.svg";
import user2 from '../../src/images/user2.png';
import user3 from '../../src/images/user3.png';
import dotthree from "../../src/images/DotsThree.svg";
import studentCourse1 from "../../src/images/studentCourse1.jpg";
import studentCourse2 from "../../src/images/studentCourse2.jpg";
import studentCourse3 from "../../src/images/studentCourse3.jpg";
import studentCourse4 from "../../src/images/studentCourse4.jpg";
import usersgroup from "../../src/images/users-group.svg";
import pRight from "../../src/images/p-ArrowRight.svg";
import pLeft from "../../src/images/p-ArrowLeft.svg";
import NewRevinew from "../components/NewRevinew";
import MultiLineChart from "../components/MultiLineChart";
import ReviewLineChart from "./components/ReviewLineChart";
import ProfileViewBar from "./components/ProfileViewBar";

import { BsEye  } from "react-icons/bs";
import { FaRupeeSign, FaBell, FaCircle, FaMobileAlt, FaEnvelope, FaStar ,FaStarHalfAlt,FaRegStar } from "react-icons/fa";
import MobileMenu from "../components/MobileMenu";
//import Sildebar from "../Sildebar";
import TopMenu from"../components/TopMenu";
import FilerDashboard from "../components/FilerDashboard";
import CalenderMultiple from "../components/CalenderMultiple";
import { FaBars } from "react-icons/fa";
import LabDashboradFilter from "../components/LabDashboradFilter";
import Newgrphadd from "../../src/images/customer-graph.svg";
import InstructorSidebar from "./components/InstructorSidebar";
import $ from "jquery";

class InstructorCourseDetail extends React.Component {
   componentWillMount(){

      $(document).ready(function(){
        $(".togle-icon").click(function(e) {
            e.preventDefault();
            $("#navAccordion").toggleClass("active");
            $("main").toggleClass("full-width");
            $("footer").toggleClass("full-width");
      
      });
      });
      
    }
   render() {
     return(
        <>
        <div className='page-wrapper chiller-theme toggled instructor-main'>
        <div className="main-sec d-lg-flex instructor-main">
          <div className="sidebar-menu" id="navAccordion">
             <InstructorSidebar/>
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
          

            <div className="d-none d-lg-block">
                  <div className="top-bar-menu shadow float-start">
                     <a className="tog-btn me-2 togle-icon">
                     <FaBars/>
                     </a>
                     <div className="d-flex me-auto  align-items-center">
                        <h5 className="mb-0 crm-newadd"> Student ID: <span>  NS3608320 </span>  </h5>
                        <h5 className="mb-0 ms-3 crm-newadd"> Student Name:<span> Navdeep Singh </span>  </h5>
                     </div>
                     <TopMenu/>
                  </div>
            </div>
             
            <div className="dasb-div bk-div float-start w-100">
         <div className="instructor_wrapper ">
            <div className="row text-left">
               <div className="breadcrumb-menu">
            
                  <nav aria-label="breadcrumb" className="justify-content-start">
                        <ol className="breadcrumb">
                           <li className="breadcrumb-item">Course</li>
                           <li className="breadcrumb-item">My Courses</li>
                           <li className="breadcrumb-item">Development</li>    
                           <li className="breadcrumb-item">Web Development</li>    
                           <li className="breadcrumb-item active" aria-current="page">2021 Complete Python Bootcamp From Zero to Hero in Python</li>
                        </ol>
                  </nav>
               </div>
            </div>
            <div class="row g-lg-4">
               <div className="col-lg-12 instructor-couse-detail">
                  <div class="row g-lg-4 instructor-row ">
                     <div className="col-lg-3 col-md-4 col-sm-6 crs-image">
                     <div className="ins-course-image"><img src={ins13} alt="ins13"/></div>
                     </div>
                     <div className="col-lg-9 col-md-8 col-sm-6 ">
                     <div className="inc-crs-content">
                     <div class="crc-top-head"><span class="crc-info">Uploaded:<span> Jan 21, 2020</span></span> <span class="crc-info">Last Updated:<span>  Sep 11, 2021</span></span></div>
                     <h3><a href="">2021 Complete Python Bootcamp From Zero to Hero in Python</a></h3>
                     <p>3 in 1 Course: Learn to design websites with Figma, build with Webflow, and make a living freelancing.</p>
                        <div className="row course-author">
                           <div className="col-lg-7 col-md-6 col-sm-6 col-12 course-author-content">
                              <div className="author-image"><span className="img1"><img src={user2} alt="user2"/></span><span className="user3"><img src={user3} alt="user3"/></span></div>
                              <div className="author-name">
                              <p>Created by:</p>
                              <div className="author_ct-name"><a>Dianne Russell</a> • <a>Kristin Watson</a></div>
                              </div>
                           </div>
                           <div className="col-lg-5 col-md-6 col-sm-6 col-12 course-author-rating">
                              <div className="rating-content"> 
                              <span className="star-icons">
                                 <FaStar />
                                 <FaStar />
                                 <FaStar />
                                 <FaStar />
                                 <FaStar />
                              </span>
                                 <span className="rating_value">4.8</span>
                                 <span className="rating_view"> (451,444 Rating)</span>
                              </div>
                           </div>
                        </div>
                        <div className="row course-revenue align-items-center">
                        <div className="col-lg-7 col-md-6 col-sm-6">
                        <div className="d-flex insprice-block">
                        <div className="course_prices">$13.99<span className="crc-label">Course prices</span></div>
                        <div className="course_earning">$131,800,455.82<span className="crc-label">USD dollar revenue</span></div>
                        </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6">
                        <div className="withdrewr_price d-flex align-items-center justify-content-end">
                        <a  className="brows-btn" href="" >withdrew money</a>
                                          <div className="edit_option">
                                             <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                                             data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={dotthree} alt="dotthree"/> </a>
                                                <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                                <a class="dropdown-item" href="#">View Details</a>
                                                   <a class="dropdown-item" href="#">Edit Course</a>
                                                   <a class="dropdown-item" href="#">Delete Course</a> 
                                                </div>
                                       </div>
                                    </div>
                        </div>
                        </div>
                  </div>
               </div>
               </div> 
               </div>
            </div>

            <div class="row g-lg-4  ">
               <div className="col-lg-6 col-md-6 col-12">
                  <div class="row topcategory ">
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={playcirclec} alt="playcirclec"/></div>
                           <div className="cat-content ">
                           <h5>957</h5>
                           <p>Enrolled Courses</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={checksquare} alt="checksquare"/></div>
                           <div className="cat-content ">
                           <h5>6</h5>
                           <p>Active Courses</p>
                           </div>
                        </div>
                     </div>
                     
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={trophy} alt="trophy"/></div>
                           <div className="cat-content ">
                           <h5>951</h5>
                           <p>Completed Courses</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={usersgroup} alt="usersgroup"/></div>
                           <div className="cat-content ">
                           <h5>241</h5>
                           <p>Course Instructors</p>
                           </div>
                        </div>
                     </div>  
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={playcirclec} alt="playcirclec"/></div>
                           <div className="cat-content ">
                           <h5>957</h5>
                           <p>Enrolled Courses</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={checksquare} alt="checksquare"/></div>
                           <div className="cat-content ">
                           <h5>6</h5>
                           <p>Active Courses</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={trophy} alt="trophy"/></div>
                           <div className="cat-content ">
                           <h5>951</h5>
                           <p>Completed Courses</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-xs-12 col-sm-6 col-md-6 ">
                        <div className="inline-box cat1">
                        <div className="cat-image"><img src={usersgroup} alt="usersgroup"/></div>
                           <div className="cat-content ">
                           <h5>241</h5>
                           <p>Course Instructors</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6 col-md-6 col-12 ">
                  <div className="comon-chart-globe activity-box border-0">
                     <div className="card-header d-flex justify-content-between align-items-center">
                        <h2 class="card-title">Overall Course Rating </h2>
                        <div class="dropdown">
                           <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                           data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">This week</a>
                              <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                 <a class="dropdown-item" href="#">Action</a>
                                 <a class="dropdown-item" href="#">Another action</a>
                                 <a class="dropdown-item" href="#">Something else here</a>                              
                                 <a class="dropdown-item" href="#">Separated link</a>
                              </div>
                        </div>
                     </div>

                     <div className="cardbody">
                     <div className="instructoru_reviews row">
                        <div className="col-md-5">
                        <div className="rating-box reviewsinf0">
                                       <h4>4.8</h4>
                                       <ul className="total-star list-inline">
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                       <span className="star-icon">  <FaStar /></span>
                                       </li>
                                       <li className="list-inline-item">
                                          <span className="star-icon"> <FaStarHalfAlt /> </span>

                                       </li>
                                       </ul>
                                       <p>Course Rating</p>
                                    </div>
                        </div>
                        <div className="col-md-7">
                        <ReviewLineChart />
                        </div>
                        </div>
                        <div className="instructoru_reviews-all row">
                        <div className="col-md-12">
                        <div className="rating-percentage">
                                       <div className="percentage-line">
                                       <ul className="total-star total-star-sm list-inline">
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                       </ul>
                                       <div className="rating">
                                          5 Star 
                                       </div>
                                       <div className="rating-area-percentage">
                                          <div className="main-percentage-bg"></div>
                                          <div className="main-percentage four"></div>
                                       </div>
                                       <div className="percentage-text">
                                          <span>75%</span>
                                       </div>
                                       </div>
                                       <div className="percentage-line">
                                       <ul className="total-star total-star-sm list-inline">
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                             <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                       </ul>
                                       <div className="rating">
                                          4 Star 
                                       </div>
                                       <div className="rating-area-percentage">
                                          <div className="main-percentage-bg"></div>
                                          <div className="main-percentage four"></div>
                                       </div>
                                       <div className="percentage-text">
                                          <span>21%</span>
                                       </div>
                                       </div>
                                       <div className="percentage-line">
                                       <ul className="total-star total-star-sm list-inline">
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon">  <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                       </ul>
                                       <div className="rating">
                                          3 Star 
                                       </div>
                                       <div className="rating-area-percentage">
                                          <div className="main-percentage-bg"></div>
                                          <div className="main-percentage three"></div>
                                       </div>
                                       <div className="percentage-text">
                                          <span>3%</span>
                                       </div>
                                       </div>
                                       <div className="percentage-line">
                                       <ul className="total-star total-star-sm list-inline">
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                       </ul>
                                       <div className="rating">
                                          2 Star 
                                       </div>
                                       <div className="rating-area-percentage">
                                          <div className="main-percentage-bg"></div>
                                          <div className="main-percentage four"></div>
                                       </div>
                                       <div className="percentage-text">
                                          <span>2%</span>
                                       </div>
                                       </div>
                                       <div className="percentage-line">
                                       <ul className="total-star total-star-sm list-inline">
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                          <li className="list-inline-item">
                                          <span className="star-icon"> <FaRegStar /></span>
                                          </li>
                                       </ul>
                                       <div className="rating">
                                          1 Star 
                                       </div>
                                       <div className="rating-area-percentage">
                                          <div className="main-percentage-bg"></div>
                                          <div className="main-percentage one"></div>
                                       </div>
                                       <div className="percentage-text">
                                          <span>2%</span>
                                       </div>
                                       </div>
                                    </div>
                        </div>
                     </div>                  
                     </div>
                  </div>
                  
               </div>
               <div className=" col-sm-6 col-md-5 col-12 ">
                  <div className="revenu-div activity-box ">
                     <div className="card-header d-flex justify-content-between align-items-center">
                     <h2 class="card-title"> Total Revenue </h2>
                     <div class="dropdown ms-3">
                                 <button class="cs-dropdown btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                 Today
                                 </button>
                                 <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                 <li><a class="dropdown-item" href="#">Action</a></li>
                                 <li><a class="dropdown-item" href="#">Another action</a></li>
                                 <li><a class="dropdown-item" href="#">Something else here</a></li>
                                 </ul>
                              </div>
                     </div>
                     <div className="cardbody">  <RevieCtWeek/></div>
                  

                  </div>
               </div>
               <div className="col-sm-6 col-md-7 col-12">
                  <div className="comon-chart-globe border-0 activity-box ">
                                 
                  <div className="card-header d-flex justify-content-between align-items-center">
                     <h2 class="card-title"> Course Overview </h2>
                     <div class="dropdown">
                           <a class=" cs-dropdown btn btn-link dropdown-toggle" id="dropdownMenuLink" href="#" role="button"
                           data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">This week</a>
                              <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuLink">
                                 <a class="dropdown-item" href="#">Action</a>
                                 <a class="dropdown-item" href="#">Another action</a>
                                 <a class="dropdown-item" href="#">Something else here</a>                              
                                 <a class="dropdown-item" href="#">Separated link</a>
                              </div>
                        </div>
                     </div>
                  <div className="cardbody"><MultiLineChart /></div>
               </div>
               </div>
            

            </div>
            
            
   </div>
               


        
   
  
</div>
          </div>
        </main>
       </div>

       
        <div class="modal fade bk-tabs-div" id="settingsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <div class="modal-header pb-0">
                  
               <ul className="nav nav-pills" id="pills-tab" role="tablist">
                     <li className="nav-item" role="presentation">
                     <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Paitent Details</button>
                     </li>
                     <li className="nav-item" role="presentation">
                     <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">History</button>
                     </li>
                     
               </ul>
               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">

                  <div className="tabs-settings">
                     
                     <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              
                              <div className="patinent-details">
                                 <div className="inside-history-div col-lg-12 mx-auto mt-3">
                                    <h6 className="mn-head"> 10 Results Found </h6> 
                                    <div className="result-div mt-3">
                                       <div className="cm-history mx-auto row">
                                          <div className="col-lg-10">
                                             <div className="left-history">
                                                <h5 className="d-lg-flex"> Veena Rao  </h5>
                                                <div className="same-details">
                                                   <span> 30 Yrs, Female </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                   <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                             <div className="left-history mt-5">
                                                <h6 className="nw-add"> Beneficiary </h6>
                                                <h5> Jaswant Thakur </h5>
                                                <div className="same-details">
                                                   <span> 45 Yrs, Male </span>
                                                   <span> <FaCircle/> 25-02-2022 </span>
                                                   <span> <FaCircle/>  #ORD6848r4 </span>
                                                </div>
                                                <ul className="list-unstyled d-lg-flex mt-3">
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                   <li>
                                                      CVC
                                                   </li>
                                                   <li>
                                                      Dengue
                                                   </li>
                                                   <li>
                                                      Typhoied
                                                   </li>
                                                </ul>
                                             </div>

                                          </div>
                                          <div className="col-lg-2">
                                             <div className="right-sec d-grid justify-content-lg-end">
                                                <h5> Rs. 1500/- </h5>
                                                <h6 className="ps-st">
                                                   <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                                </h6>
                                                <figure className="mb-0">
                                                   <img src={startrating} alt="ck"/>
                                                </figure>
                                                <p className="text-center"> Satisfied </p>
                                             </div>
                                             
                                          </div>
                                       </div>



                                    </div>
                           
                                 </div>

                                 <div className="ps-status-div speacial-accept col-lg-12 mx-auto my-4">
                                       <div className="row row-cols-7 justify-content-center">
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={fileicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Accepted 
                                                   <span className="d-block">
                                                   (09:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                                       <div className="col">
                                             <div className="comon-status">
                                             <figure className="iocn-cm">
                                                   <img src={roketicon} alt="file"/>
                                             </figure>
                                             <h5>
                                                   Started
                                                   <span className="d-block">
                                                   (10:00 AM)
                                                   </span>
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm ">
                                                   <img src={flagicon} alt="file"/>
                                             </figure>
                                             <h5>
                                              Reached
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={specialicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Collected
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       <div className="col">
                                             <div className="comon-status cm-disable">
                                             <figure className="iocn-cm">
                                                   <img src={subicon} alt="file"/>
                                             </figure>
                                             <h5>
                                             Submitted
                                                   
                                             </h5>
                                             </div>
                                       </div>
                           
                           
                                       </div>
                                 </div>
                              </div>

                              
                        
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        
                              <div className="inside-history-div col-lg-11 ms-auto mt-3">
                                 <h6 className="mn-head"> 10 Results Found </h6> 
                                 <div className="result-div mt-3">
                                    <div className="cm-history row">
                                       <div className="col-lg-11">
                                          <div className="left-history">
                                             <h5> Veena Rao
                                             </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span > <FaCircle/> 25-02-2022 </span>
                                                <span > <FaCircle/>  #ORD6848r4 </span>
                                                <span> <FaCircle/>  +91 525236521 </span>
                                                   <span> <FaCircle/>  veenarao@gmail.com </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          <div className="left-history mt-5">
                                             <h6 className="nw-add"> Beneficiary </h6>
                                             <h5> Jaswant Thakur </h5>
                                             <div className="same-details">
                                                <span> 45 Yrs, Male </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                       </div>
                                       <div className="col-lg-1">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1500/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 500/- </h5>
                                             <h6 className="unps-st">
                                                Unpaid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 2000/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>

                                    <div className="cm-history row">
                                       <div className="col-lg-9">
                                          <div className="left-history">
                                             <h5> Veena Rao </h5>
                                             <div className="same-details">
                                                <span> 30 Yrs, Female </span>
                                                <span> <FaCircle/> 25-02-2022 </span>
                                                <span> <FaCircle/>  #ORD6848r4 </span>
                                             </div>
                                             <ul className="list-unstyled d-lg-flex mt-3">
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                                <li>
                                                   CVC
                                                </li>
                                                <li>
                                                   Dengue
                                                </li>
                                                <li>
                                                   Typhoied
                                                </li>
                                             </ul>
                                          </div>

                                          

                                       </div>
                                       <div className="col-lg-3">
                                          <div className="right-sec d-grid justify-content-lg-end">
                                             <h5> Rs. 1200/- </h5>
                                             <h6 className="ps-st">
                                                <span>  <img src={roundck} alt="ck"/> </span>  Paid
                                             </h6>
                                          </div>
                                          
                                       </div>
                                    </div>


                                 </div>
                                 
                              </div>

                        </div>
                        
                     </div>
                  </div>






                  



               </div>
               
            </div>
         </div>
        </div>
       
        <MobileMenu/>


       <LabDashboradFilter/>

        <div className="modal fade calender-modal" id="caldenderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
               <div className="modal-content">
                  <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Calendar</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <CalenderMultiple/>
                  </div>
                  
               </div>
            </div>
        </div>
        </>
     );
   }
 }

export default InstructorCourseDetail;