import React, { Component } from 'react';
import { FaRegStar, FaVideo } from 'react-icons/fa'; // Import the icons you want to use
import "../Card.css"; // Ensure this path is correct

class DashboardCard extends Component {
    render() {
        const { title, bodyText } = this.props; // Destructuring props for easier access

        return (
            <>
                <div className="card">
                    <div className="card-icon">
                        <FaVideo /> {/* Video camera icon */}
                    </div>
                    <div className="card-content">
                        <h2 className="heading">{title}</h2>
						<div className="time">10:00 12:30</div>
                        <p>{bodyText}</p>
                    </div>
                    <div className="card-footer">
                        <button className="view-button">Join Meeting</button>
                    </div>
                </div>
            </>
        );
    }
}

export default DashboardCard;
