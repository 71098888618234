import React, { useState } from 'react';
import volet from "../src/images/icon-volet.svg";
import usericons from "../src/images/course-icon.svg";
import calender from "../src/images/icon-rg-calender.svg";
import message from "../src/images/message-icon.svg";
import emailc from "../src/images/icon-email.svg";
import plus from "../src/images/icon-plus-rg.svg";
import alarm from "../src/images/icon-alarm.svg";
import lamp from "../src/images/icon-lamp.svg";
import landline from "../src/images/icon_landline.svg";
import setting from "../src/images/setting-icon.svg";
import ellipsea from "../src/images/ellipse1.svg";
import ellipseb from "../src/images/ellipse2.svg";
//import { FaBars, FaTimes, FaHome, FaUser, FaCog, FaBell } from 'react-icons/fa'; // Importing necessary icons
import { FaBars, FaTimes, FaWallet, FaUser, FaCalendarAlt, FaCommentDots, FaEnvelope, FaClock, FaLightbulb, FaCalculator, FaCog } from 'react-icons/fa';
//import "./Sidebar.css"; // Import CSS for styling

const RightSidebar = () => {
	
	const [collapsed, setCollapsed] = useState(true); // State to manage collapse

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };
	
    return (
        <div className={`rsidebar ${collapsed ? 'collapsed' : 'expanded'}`}><span className='shape-top'><img src={ellipsea} alt="ellipse1"/></span>
            {/* Toggle icon changes depending on the state */}
            <div className="toggle-button" onClick={toggleSidebar}>
                {collapsed ? <FaBars className="icon" /> : <FaTimes className="ricon" />}
            </div>
            <div className="ricon-strip">
            <ul className='rglist-link'>
            <li> <img src={volet}  alt="volet"/> </li>
            <li> <img src={usericons}  alt="usericons"/> </li>
            <li> <img src={calender}  alt="icon-rg-calender"/> </li>
            <li> <img src={message}  alt="message"/> </li>
            <li> <img src={emailc}  alt="email"/> </li>
            <li> <img src={plus}  alt="plus"/> </li>
            <li> <img src={alarm}  alt="alarm"/> </li>
            <li> <img src={lamp}  alt="lamp"/> </li>
            <li> <img src={landline}  alt="landline"/> </li>
            <li> <img src={setting}  alt="setting"/> </li>
            </ul>
            </div><span className='shape-bottom'><img src={ellipseb} alt="ellipse2"/></span>
        </div>
    );
};

export default RightSidebar;

